import { ContentSectionsCuratedByComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { CuratedBy } from "./CuratedBy";
import { CuratedByProps } from "./CuratedBy.types";

const map = ({
  author,
}: ContentSectionsCuratedByComponent): CuratedByProps => ({
  author,
});

export const renderCuratedBy = ({ contentProps }: RendererArg) => (
  <CuratedBy {...map(contentProps as ContentSectionsCuratedByComponent)} />
);
