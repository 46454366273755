import { type PaginationProps } from "@molecules/pagination/Pagination.types";
import { ArrowButton } from "@molecules/pagination/components/ArrowButton";
import { tw } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";

export const Pagination: FC<PaginationProps> = ({
  prefix,
  totalPages,
  currentPage,
}) => {
  const prevPageUrl =
    currentPage === 2 ? `/${prefix}` : `/${prefix}/page/${currentPage - 1}`;
  const nextDisabled = currentPage >= totalPages;
  const prevDisabled = currentPage <= 1;
  const nextPageUrl = `/${prefix}/page/${currentPage + 1}/`;

  const rootStyles = tw`t-body-b2 mb-24
    mt-11 flex items-center
    justify-center gap-5 text-base leading-7
    sm:mb-32 sm:mt-16
    md:mb-24 md:mt-12
    2xl:mb-52 2xl:mt-24`;

  return (
    <div className={rootStyles}>
      {prevDisabled ? (
        <ArrowButton rotated disabled={prevDisabled} />
      ) : (
        <Link href={prevPageUrl} prefetch={false} title="previous page">
          <ArrowButton rotated disabled={prevDisabled} />
        </Link>
      )}
      Page {currentPage} of {totalPages}
      {nextDisabled ? (
        <ArrowButton disabled={nextDisabled} />
      ) : (
        <Link href={nextPageUrl} prefetch={false} title="next page">
          <ArrowButton disabled={nextDisabled} />
        </Link>
      )}
    </div>
  );
};
