import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsCompanyRecordsComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { CompanyRecordsSection } from "./CompanyRecordsSection";
import { CompanyRecordsSectionProps } from "./CompanyRecordsSection.types";

const map = ({
  header_left,
  header_right,
  gdpr_email,
  info_columns,
  block_settings,
}: ServiceSectionsCompanyRecordsComponent): CompanyRecordsSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  gdprEmail: gdpr_email,
  headerLeft: header_left,
  headerRight: header_right,
  infoColumns: info_columns?.map((it) => ({
    id: it.id,
    title: it.title,
    rawHtmlContent: it.description,
  })),
});

export const companyRecordsAdapter = (
  data?: ServiceSectionsCompanyRecordsComponent | undefined
) => (data ? map(data) : undefined);

export const renderCompanyRecords = ({ contentProps }: RendererArg) => (
  <CompanyRecordsSection
    {...map(contentProps as ServiceSectionsCompanyRecordsComponent)}
  />
);
