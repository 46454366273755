import Icon from "@atoms/icon/Icon";
import {
  BlogsListCategory,
  BlogsListingProps,
} from "@page-components/blogs-list/BlogsList.types";
import { cn, tw } from "@utils/tailwind";
import { ArticleLink } from "components/lib/molecules/article-link/ArticleLink";
import { Pagination } from "components/lib/molecules/pagination/Pagination";
import { sortBy } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
import { Search } from "./searchbar/Search";

const DEVELOPER_INSIGHTS_CATEGORY: BlogsListCategory = {
  label: "Developer insights",
  url: "https://dev.virtuslab.com/",
  isExternal: true,
};

const BLANK_CATEGORY: BlogsListCategory = {
  label: "All",
  url: "/blog",
  slug: undefined,
};

export const BlogsList: FC<BlogsListingProps> = ({
  title,
  list,
  categories,
  currentPage,
  pagesTotal,
  paginationPrefix,
  blogLinkPrefix,
}) => {
  const router = useRouter();
  const { category } = router.query;
  const { tagName } = router.query;
  const allCategories: BlogsListCategory[] = sortBy(
    [BLANK_CATEGORY, ...(categories ?? []), DEVELOPER_INSIGHTS_CATEGORY],
    [(it) => it.label]
  );

  const selectedCategory = categories?.find((item) => item.slug === category);
  const headerText = tagName
    ? `Tag: ${tagName && tagName[0].toUpperCase() + tagName.slice(1)}`
    : selectedCategory?.label
      ? `Category: ${selectedCategory.label}`
      : title;

  const linkStyles = tw`group/category-link
    t-body-b2
    flex
    cursor-pointer
    items-center
    gap-2
    border-b
    border-solid
    border-b-transparent
    text-grey-scale-off-black
    no-underline
    outline-none
    hover:border-b-green-accent-one
    focus:border-b-green-accent-one
    focus:text-green-accent-one`;

  const selectedLinkStyles = tw`border-b-content-primary-on-light
    text-grey-scale-off-black
    hover:border-b-content-primary-on-light
    hover:text-grey-scale-off-black
    active:border-b-content-primary-on-light
    active:text-grey-scale-off-black`;

  const iconStyles = tw`transition-all
    ease-in-out
    group-hover/category-link:-translate-y-0.5
    group-hover/category-link:translate-x-0.5
    group-hover/category-link:fill-green-accent-one
    group-focus/category-link:fill-green-accent-one
    group-active/category-link:fill-green-accent-one`;

  return (
    <div className="container-flexible">
      <h1 className="t-subpage-h1 mb-11 mt-24 flex h-fit items-center justify-between gap-4 sm:mb-12 md:mt-28 2xl:mt-32">
        {headerText}
        <Link
          href="/feeds/blog/rss.xml"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col no-underline"
        >
          <Icon width={24} height={24} fill="#ff9a00" name="RssIcon" />
          <span className="font-sans text-base font-medium leading-6 text-[#ff9a00]">
            RSS
          </span>
        </Link>
      </h1>
      <div className="mb-16 grid gap-8 sm:mb-20 lg:grid-cols-2 2xl:mb-28">
        {!tagName && (
          <div className="flex flex-wrap gap-x-8">
            <span className="t-body-b2 text-grey-faded">Categories</span>
            {allCategories?.map((item, idx) => (
              <Link
                href={item.url ?? ""}
                key={idx}
                target={item.isExternal ? "_blank" : undefined}
                className={cn(
                  linkStyles,
                  !item.isExternal &&
                    category === item.slug &&
                    selectedLinkStyles
                )}
                rel="noreferrer"
              >
                {item.label}
                {item.isExternal && (
                  <Icon
                    width={8}
                    height={8}
                    name="ArrowTopRight"
                    className={iconStyles}
                  />
                )}
              </Link>
            ))}
          </div>
        )}
        <Search className="max-w-lg lg:justify-self-end" />
      </div>
      {list && (
        <>
          <div className="grid gap-y-5 md:grid-cols-2 lg:grid-cols-3">
            {list.map((item, idx) => (
              <ArticleLink
                image={item.image}
                caption={item.category?.title}
                summary={item.title}
                index={1}
                url={
                  category
                    ? `${blogLinkPrefix}/${item.slug}`
                    : `${blogLinkPrefix}/${item.category?.slug}/${item.slug}`
                }
                key={idx}
                isArrowVisible={false}
                body={item.body}
              />
            ))}
          </div>
          <Pagination
            prefix={paginationPrefix}
            totalPages={pagesTotal}
            currentPage={currentPage}
          />
        </>
      )}
    </div>
  );
};
