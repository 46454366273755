import { breakpointValues } from "@utils/theming/breakpoints";
import { CardsGroup, Slide } from "./TimelineSection.types";

/**
 * It takes an array and returns a 2D array where each element represents a column of elements.
 * For the screen >= xl it returns three columns: [[el1, el4], [el2, el5], [el3, el6]].
 * For the screen >= sm it returns two columns: [[el1, el3], [el2, el4]].
 * Else it returns one column containing all the given elements: [[el1, el2, el3, ...]]
 */
export function splitIntoColumns<T>(elements: T[], screenWidth?: number) {
  function buildColumns(columnsAmount: number) {
    const columns: T[][] = [];
    for (let i = 0; i < elements.length; i++) {
      const ind = i % columnsAmount;
      if (!columns[ind]) columns[ind] = [];
      columns[i % columnsAmount].push(elements[i]);
    }
    return columns;
  }

  if (!screenWidth) return [];
  if (screenWidth >= breakpointValues.xl) return buildColumns(3);
  else if (screenWidth >= breakpointValues.sm) return buildColumns(2);
  return buildColumns(1);
}

/**
 * It splits elements into groups because we cannot fit all elements into one screen.
 * @param elements
 * @param screenWidth
 */
export function splitElementsToFitScreen<T>(
  elements: T[],
  screenWidth?: number
) {
  const buildGroups = (maxElementsAmount: number) => {
    const groups: T[][] = new Array(
      Math.ceil(elements.length / maxElementsAmount)
    );
    for (let i = 0; i < elements.length; i++) {
      const ind = Math.floor(i / maxElementsAmount);
      if (!groups[ind]) groups[ind] = [];
      groups[ind].push(elements[i]);
    }
    return groups;
  };

  if (!screenWidth) return [];
  if (screenWidth >= breakpointValues.xl) return buildGroups(9);
  else if (screenWidth >= breakpointValues.sm) return buildGroups(4);
  else return buildGroups(2);
}

export function extractSortedYears(groups: CardsGroup[]) {
  return groups ? groups.map((it) => it.year).sort((a, b) => a - b) : [];
}

/**
 * It returns a sorted array (in ascending order) of slides which are used by Swiper.
 * Each slide contains cards that are grouped into columns. Each slide is described by a year and a slideNumber.
 */
export function buildSlides(
  years: CardsGroup[],
  sortedYears: number[],
  screenWidth?: number
): Slide[] {
  let slidesCounter = -1;
  return sortedYears.flatMap((year) => {
    const chunks = splitElementsToFitScreen(
      years.find((it) => it.year === year)?.cards ?? [],
      screenWidth
    );

    return chunks.map((chunk) => {
      slidesCounter = slidesCounter + 1;
      return {
        slideNumber: slidesCounter,
        year,
        columns: splitIntoColumns(chunk, screenWidth).filter(
          (it) => !!it.length
        ),
      };
    });
  });
}
