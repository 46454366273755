import Icon from "@atoms/icon/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "@atoms/popover";
import Typography from "@atoms/typography/Typography";

const content = `We will process your personal data to respond to your application and for future communications related to your inquiry. VirtusLab Sp. z o.o is the data controller. More information you will find in our <a href="/privacy-policy/" target="_blank">Privacy Policy</a>.`;

export const PersonalDataPopover = () => (
  <Popover>
    <PopoverTrigger type="button" className="text-grey-faded">
      <Icon name="Info" className="size-4">
        Icon
      </Icon>
    </PopoverTrigger>
    <PopoverContent side="top" align="start" className="bg-[#fcfcfc] md:w-96">
      <Typography
        wysiwygStyling
        tag="p"
        variant="xs"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </PopoverContent>
  </Popover>
);
