import { blockSettingsAdapter } from "@adapters/block-settings";
import { HomeSectionsMountainsComponent } from "@api/contract";
import { MountainsSection } from "@sections/mountains/MountainsSection";
import { MountainsSectionProps } from "@sections/mountains/MountainsSection.types";
import { RendererArg } from "@/types/renderer";

const map = ({
  block_settings,
  mnt_title,
  mnt_image,
  mnt_subtitle,
  mnt_base_title,
  mnt_base_cards,
}: HomeSectionsMountainsComponent): MountainsSectionProps => ({
  ...blockSettingsAdapter({ blockSettings: block_settings }),
  title: mnt_title,
  image: mnt_image?.data,
  subtitle: mnt_subtitle,
  base: {
    title: mnt_base_title,
    cards: mnt_base_cards?.map((it) => ({
      title: it.title || "",
      description: it.description || "",
      href: it.href,
    })),
  },
});

export const renderMountains = ({ contentProps }: RendererArg) => (
  <MountainsSection {...map(contentProps as HomeSectionsMountainsComponent)} />
);
