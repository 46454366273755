import { ServiceSectionsImageSpacerComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { RendererArg } from "@/types/renderer";
import { ImageSpacerSection } from "./ImageSpacerSection";
import { ImageSpacerSectionProps } from "./ImageSpacerSection.types";

const map = ({
  image,
}: ServiceSectionsImageSpacerComponent): ImageSpacerSectionProps => ({
  image: image?.data as ApiImage,
});

export const renderImageSpacerSection = ({ contentProps }: RendererArg) => (
  <ImageSpacerSection
    {...map(contentProps as ServiceSectionsImageSpacerComponent)}
  />
);
