import { cn } from "@utils/tailwind";
import { PropsWithChildren } from "react";

/**
 * @deprecated use "container-flexible" class
 * */
const ContainerFlexible = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={cn("container-flexible", className)}>{children}</div>
);

export default ContainerFlexible;
