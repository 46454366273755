import Icon from "@atoms/icon/Icon";
import { Logo } from "@sections/open-source/open-source-components/Logo";
import { OpenSourceCardProps } from "@sections/open-source/open-source-components/types";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";

export const OpenSourceCard: FC<OpenSourceCardProps> = ({
  title,
  projects,
  linkButton,
}) => {
  const descriptionStyles = tw`t-body-b3 whitespace-nowrap
    text-grey-scale-off-black text-opacity-75 md:t-body-b2`;

  const viewProjectsPillStyles = cn(
    descriptionStyles,
    `group/view-pill flex h-10 items-center justify-center gap-1.5
    rounded-full border border-solid border-grey-scale-off-black
    px-4 py-0 no-underline shadow-sm
    active:bg-card
    md:h-12 md:gap-2 md:px-6 md:py-0
    md:text-sm md:font-medium md:leading-5
    2xl:h-14 2xl:gap-2.5 2xl:px-8 2xl:py-0`
  );

  const pillStyles = tw`flex h-9 items-center gap-1
    rounded-full bg-white
    py-0.5 pl-0.5 pr-3.5
    no-underline shadow-sm
    hover:bg-content-primary-on-dark
    sm:h-9 sm:gap-1 sm:py-0.5 sm:pl-0.5 sm:pr-3.5
    md:h-12 md:gap-2 md:py-1 md:pl-1 md:pr-6
    2xl:h-12 2xl:gap-4 2xl:py-1 2xl:pl-1 2xl:pr-8`;

  return (
    <div>
      <h4 className="t-headline-h4 mb-5 text-grey-scale-off-black md:mb-8 2xl:mb-12">
        {title}
      </h4>
      <div className="flex flex-wrap items-center gap-x-2 gap-y-1.5 md:gap-3 2xl:gap-4">
        {projects.map(({ id, logo, title, url }) => (
          <a
            key={id}
            href={url}
            target="_blank"
            rel="noreferrer"
            className={pillStyles}
          >
            <Logo {...logo} />
            <span className={descriptionStyles}>{title}</span>
          </a>
        ))}
        <Link
          href={linkButton.href}
          target={linkButton.openInNewWindow ? "_blank" : undefined}
          rel={linkButton.openInNewWindow ? "noreferrer" : undefined}
          className={viewProjectsPillStyles}
        >
          <span>View all projects</span>
          <Icon
            name="ArrowTopRight"
            className="h-full w-2 fill-grey-scale-off-black transition-transform duration-200 ease-out group-hover/view-pill:-translate-y-0.5 group-hover/view-pill:translate-x-0.5 md:w-2.5"
          />
        </Link>
      </div>
    </div>
  );
};
