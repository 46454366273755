import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { OurStoryInNumbersSectionProps } from "@sections/story-in-numbers/OurStoryInNumbersSection.types";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import { OurStoryInNumbersCard } from "./cards/OurStoryInNumbersCard";

export const OurStoryInNumbersSection: FC<OurStoryInNumbersSectionProps> = ({
  title,
  cards,
  anchorId,
  themeName,
  isTransparentBackground,
}) => {
  const shadowStyles = tw`before:absolute
    before:left-0 before:top-0
    before:size-full before:translate-x-2.5
    before:translate-y-5 before:rounded-3xl
    before:bg-grey-scale-light-gray
    before:blur-xl
    before:content-[""]
    darkGreen:before:bg-transparent
    lightGreen:before:bg-green-tertiary/40
    lightBlue:before:bg-transparent
    beige:before:bg-black/20`;

  return (
    <ContainerSpacing
      isTransparentBackground={isTransparentBackground}
      id={anchorId}
      withBackground
      data-strapi="story-in-numbers"
      data-theme={themeName}
    >
      <div className="container-flexible flex flex-col gap-7 sm:gap-12">
        <Typography tag="h2" variant="h2" className="text-section-title">
          {title}
        </Typography>
        <div className={cn(shadowStyles, "relative grid md:grid-cols-3")}>
          {cards.map((data) => (
            <OurStoryInNumbersCard key={data.id} {...data} />
          ))}
        </div>
      </div>
    </ContainerSpacing>
  );
};
