import { DynamicIconComponent } from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { type StepProps } from "@sections/column-steps/components/Step.types";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";

export const Step: FC<StepProps> = ({ counter, title, text, icon }) => {
  const showCounter = counter !== undefined;
  const showIcon = icon && !showCounter;

  const rootStyles = tw`relative
  z-10
  grid
  bg-transparent
  p-8
  darkGreen:border-blue-light/20
  darkGreen:bg-transparent
  lg:row-span-2
  lg:grid-rows-subgrid
  lg:rounded-4xl
  lg:p-5
  xl:p-10`;

  const rootStylesBefore = tw`before:absolute before:left-0 
  before:top-0 before:block before:size-[calc(100%+1px)] before:rounded-3xl before:border 
  before:border-green-accent-two before:content-[''] before:lg:rounded-4xl`;

  const iconWrapperStyles = tw`relative block size-7
    flex-none rounded-full   xl:size-12`;

  const counterStyles = tw`absolute left-2/4
    top-2/4 -translate-x-2/4
    -translate-y-2/4 text-base
    text-green-accent-two
    xl:text-lg xl:leading-7`;

  const alignCenter = showCounter || showIcon;

  const headerStyles = cn(
    "mb-5 flex w-full justify-between gap-3 text-card-title darkGreen:text-primary xl:mb-8 2xl:mb-10",
    alignCenter ? "items-center" : "items-start"
  );

  return (
    <div className={[rootStyles, rootStylesBefore].join(" ")}>
      <div className={headerStyles}>
        <Typography tag="h4" variant="h4">
          {title}
        </Typography>

        {(showCounter || showIcon) && (
          <div
            className={cn(
              iconWrapperStyles,
              !showIcon && `border border-green-accent-two`
            )}
          >
            {showCounter && <span className={counterStyles}>{counter}</span>}
            {showIcon && (
              <DynamicIconComponent
                className="!object-contain"
                backgroundImage
                {...icon}
              />
            )}
          </div>
        )}
      </div>
      {text && (
        <Typography
          tag="div"
          variant="base"
          className="text-content-primary-on-light opacity-80 darkGreen:text-primary"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  );
};
