import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import type { ArticleLinkItem } from "./ArticleLink.types";

export const ArticleLink: FC<ArticleLinkItem> = ({
  image,
  caption,
  summary,
  header,
  url,
  body,
  index = 1,
  isArrowVisible = true,
  className,
}) => {
  const articleStyles = tw`group flex w-min min-w-full cursor-pointer flex-col
    xl:transition-transform xl:duration-300 xl:will-change-transform
    xl:hover:scale-[1.06] xl:hover:animate-[scale_1s_forwards]`;

  const arrowIconStyles = tw`translate-x-px
    translate-y-px scale-[1.2] fill-green-accent-one
    transition-opacity duration-300 ease-linear
    sm:translate-x-px sm:translate-y-px
    sm:scale-150 xl:invisible xl:opacity-0
    xl:group-hover:visible xl:group-hover:opacity-100`;

  const contentContainerStyles = tw`flex flex-col gap-2.5 px-0 pb-2.5 pt-4
    sm:gap-3 sm:px-0 sm:pb-2.5 sm:pt-5
    md:gap-2 md:p-4
    xl:gap-2.5 xl:px-5 xl:pb-2.5 xl:pt-5
    2xl:pb-5 2xl:pt-8`;

  const isOdd = Math.abs(index % 2) == 1;
  const headerWrapperStyles = cn(
    `t-article relative
    bottom-4
    text-content-primary-on-light sm:absolute sm:top-[20%] sm:pb-0`,
    isOdd && "sm:right-[-5%] sm:translate-x-2/4",
    !isOdd && "-translate-x-2/4"
  );

  return (
    <article className={cn(articleStyles, className)}>
      <Link
        className="relative no-underline"
        href={url ? `/${url}` : ""}
        prefetch={false}
      >
        {header && <span className={headerWrapperStyles}>{header}</span>}
        {image && (
          <figure className="relative aspect-[300/240] md:mx-2.5 md:mb-0 md:mt-2.5 md:aspect-square 2xl:mx-5 2xl:mb-0 2xl:mt-5">
            <Image
              imageData={image}
              data-component="image"
              backgroundImage
              className="rounded-2xl"
            />
          </figure>
        )}
        <div className={contentContainerStyles}>
          <span className="t-body-b1 m-0 text-grey-scale-mid 2xl:text-xl">
            {caption}
          </span>
          <h4 className="t-headline-h4 m-0 text-content-primary-on-light 2xl:text-[1.6875rem]">
            {summary}
          </h4>
          <p className="t-body-b2 text-grey-scale-mid">{body}</p>
          {isArrowVisible && (
            <Icon className={arrowIconStyles} name="ArrowIcon" />
          )}
        </div>
      </Link>
    </article>
  );
};
