import { Image } from "@atoms/image/Image";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { ImageSpacerSectionProps } from "./ImageSpacerSection.types";

export const ImageSpacerSection = ({ image }: ImageSpacerSectionProps) => (
  <ContainerSpacing
    className="relative h-[300px] overflow-hidden !p-0 md:h-[340px] xl:h-[411px] 2xl:h-[480px]"
    withBackground
    data-strapi="image-spacer"
  >
    <Image className="object-cover" backgroundImage imageData={image} />
  </ContainerSpacing>
);
