import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import helpersStyles from "./../helpers.module.css";
import { extractTableContent } from "./TableBlock.helpers";
import styles from "./TableBlock.module.css";
import { TableBlockProps } from "./TableBlock.types";

export const TableBlock: FC<TableBlockProps> = ({ table }) => {
  const extractedTable = table ? extractTableContent(table) : null;
  return (
    extractedTable && (
      <div
        className={cn(
          helpersStyles.tableStyles,
          styles.tableBlock,
          marginBottomWrapperStyles,
          "links-container w-full overflow-scroll"
        )}
        dangerouslySetInnerHTML={{ __html: extractedTable }}
      />
    )
  );
};
