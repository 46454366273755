import { Image } from "@atoms/image/Image";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { ChessCardWithImageProps } from "../ChessSection.types";
import { cardStyles } from "./Card.styles";
import { Texts } from "./Texts";

export const ChessCardWithImage: FC<ChessCardWithImageProps> = (props) => {
  const { image, direction, desktopAspectRatio } = props;
  const isPanoramic = desktopAspectRatio === "panoramic";

  const imageWrapperStyles = cn(
    `relative mb-10 aspect-[3/2] h-auto w-full
    sm:aspect-[216/100]
    md:max-h-[300px]
    lg:col-span-6 lg:row-span-1 lg:mb-0 lg:h-auto lg:max-h-full
    2xl:col-span-5 2xl:row-span-1`,
    isPanoramic ? "lg:aspect-[157/100]" : "lg:aspect-square",
    direction === "LR" &&
      "lg:col-start-7 lg:pl-2.5 lg:pr-0 2xl:col-start-7 2xl:pl-5 2xl:pr-0",
    direction === "RL" &&
      "lg:col-start-1 lg:pl-0 lg:pr-2.5 2xl:col-start-2 2xl:pl-0 2xl:pr-5"
  );

  return (
    <div className={cardStyles}>
      {image && (
        <div className={imageWrapperStyles}>
          <Image
            className="size-full rounded-3xl object-cover"
            backgroundImage
            maxKnownWidth={800}
            imageData={image}
          />
        </div>
      )}
      <Texts {...props} />
    </div>
  );
};
