import { RefObject, useEffect, useState } from "react";
import { HorizontalStepTypes } from "./HorizontalStepsSection.constants";

export const useSelectActiveStep = (
  steps: HorizontalStepTypes[],
  windowWidth: number | undefined,
  wrapperRef: RefObject<HTMLDivElement>
) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const sectionTop = wrapperRef?.current?.getBoundingClientRect().top;
        if (sectionTop) {
          if (sectionTop <= -1200) {
            setActiveStep(4);
          } else if (sectionTop <= -900) {
            setActiveStep(3);
          } else if (sectionTop <= -600) {
            setActiveStep(2);
          } else if (sectionTop <= -300) {
            setActiveStep(1);
          } else {
            setActiveStep(0);
          }
        }
      }
    };
    if (windowWidth && windowWidth >= 1270) {
      window.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [setActiveStep, steps.length, windowWidth, wrapperRef]);

  return {
    activeStep,
    setActiveStep,
  };
};

export const mobileScroll = (event: React.MouseEvent<HTMLDivElement>) => {
  const target = event.currentTarget;
  target.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
  });
};

export const desktopScroll = (
  index: number,
  wrapperRef: RefObject<HTMLDivElement>
) => {
  const sectionTop = wrapperRef?.current?.getBoundingClientRect().top;
  if (sectionTop || sectionTop === 0) {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const positionOfASection = sectionTop + scrollTop;
    window.scrollTo({
      top: index === 0 ? positionOfASection : positionOfASection + index * 300,
      behavior: "smooth",
    });
  }
};
