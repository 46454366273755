import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { FC } from "react";
import { SquareData } from "../ColumnSquares.types";

export const Square: FC<SquareData> = ({ title, points }) => (
  <div className="content-just-start grid translate-y-px border-b border-green-accent-two px-9 py-10 md:p-10 lg:translate-x-px lg:border-r lg:py-12 xl:py-14">
    <Typography
      tag="h3"
      variant="h4"
      weight="medium"
      className="mb-4 text-content-primary-on-light md:mb-5"
    >
      {title}
    </Typography>

    <ul className="flex list-none flex-col ps-0 lg:gap-1 2xl:gap-1.5">
      {points &&
        points.map((point, index) => (
          <li
            key={index}
            className="flex items-baseline gap-2.5 py-2 lg:py-2.5"
          >
            <Icon
              name="Success"
              className="h-3 w-4 flex-none fill-green-accent-two"
            />
            {point && (
              <Typography
                tag="span"
                variant="base"
                weight="normal"
                wysiwygStyling
                dangerouslySetInnerHTML={{ __html: point }}
                className="text-content-primary-on-light"
              />
            )}
          </li>
        ))}
    </ul>
  </div>
);
