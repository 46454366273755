import { blockSettingsAdapter } from "@adapters/block-settings";
import {
  HeadersHHeaderSubpageComponent,
  HeadersHeaderHomepageComponent,
} from "@api/contract";
import { ButtonProps } from "@atoms/button/Button.types";
import { Logo } from "@molecules/logo-looper/LogoLooper.types";
import { UnderHeaderProps } from "@sections/header-subpage/HeaderSubpageSection.types";
import { isEmpty } from "lodash";
import { RendererArg } from "@/types/renderer";
import { HeaderHomepageSection } from "./HeaderHomepageSection";
import { HeaderHomepageProps } from "./HeaderHomepageSection.types";

const headerHomepageAdapter = ({
  headline,
  subheadline,
  cta,
  tisax_logo,
  tuv_logo,
  background_vimeo_id,
  background_image,
  block_settings,
  bg_img_mobile,
}: HeadersHeaderHomepageComponent): HeaderHomepageProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  subheadline,
  cta: {
    href: cta?.button_url || "/contact",
    buttonLabel: cta?.button_text || "Contact us",
    target: cta?.button_new_window,
  } as ButtonProps,
  headline: headline ?? "",
  vimeoUrl: background_vimeo_id,
  imageTisax: tisax_logo ? tisax_logo.data : undefined,
  imageTuv: tuv_logo ? tuv_logo.data : undefined,
  backgroundInit: background_image ? background_image.data : undefined,
  backgroundMobile: bg_img_mobile ? bg_img_mobile.data : undefined,
});

export const underHeaderAdapter = ({
  under_header_title,
  under_header_img,
}: HeadersHHeaderSubpageComponent): UnderHeaderProps => ({
  title: under_header_title || undefined,
  logos:
    under_header_img
      ?.map(
        ({ link_image, link_url }): Logo => ({
          imageOrJSX: link_image?.data ?? {},
          href: link_url ?? "",
        })
      )
      .filter((it) => !isEmpty(it.imageOrJSX)) ?? [],
});

export const renderHeaderHomepageSection = ({ contentProps }: RendererArg) => (
  <>
    <HeaderHomepageSection
      {...headerHomepageAdapter(contentProps as HeadersHeaderHomepageComponent)}
      underHeader={underHeaderAdapter(
        contentProps as HeadersHHeaderSubpageComponent
      )}
    />
  </>
);
