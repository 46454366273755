import { renderVideoSection } from "@content-sections/VideoBlock/VideoBlock.adapter";
import { renderAccordionSection } from "@sections/accordion/AccordionSection.adapter";
import { renderBigNumbers } from "@sections/big-numbers/BigNumbers.adapter";
import { renderBurgerSection } from "@sections/burger/BurgerSection.adapter";
import { renderCarouselSection } from "@sections/carousel/CarouselSection.adapter";
import { renderChessSection } from "@sections/chess/ChessSection.adapter";
import { renderColumnSquares } from "@sections/column-squares/ColumnSquares.adapter";
import { renderColumnSteps } from "@sections/column-steps/ColumnSteps.adapter";
import { renderCommuningSection } from "@sections/communing/CommuningSection.adapter";
import { renderCompanyRecords } from "@sections/company-records/CompanyRecordsSection.adapter";
import { renderDefaultFullPageText } from "@sections/default-full-page-text/DefaultFullPageText.adapter";
import { renderEventsListSection } from "@sections/events-list/EventsListSection.adapter";
import { renderExpertsSection } from "@sections/experts/ExpertsSection.adapter";
import { renderFingerPulse } from "@sections/finger-pulse/FingerPulse.adapter";
import { renderHeaderHomepageSection } from "@sections/header-homepage/HeaderHomepage.adapter";
import { renderHeaderSubpageSection } from "@sections/header-subpage/HeaderSubpage.adapter";
import { renderHorizontalAccordionSection } from "@sections/horizontal-accordion/HorizontalAccordionSection.adapter";
import { renderHorizontalStepsSection } from "@sections/horizontal-steps/HorizontalStepsSection.adapter";
import { renderIconListSection } from "@sections/icon-list/IconList.adapter";
import { renderIconsInCategoriesSection } from "@sections/icons-in-categories/IconsInCategories.adapter";
import { renderImageSpacerSection } from "@sections/image-spacer/ImageSpacer.adapter";
import { renderMountains } from "@sections/mountains/MountainsSection.adapter";
import { renderOpenSourceSection } from "@sections/open-source/OpenSourceSection.adapter";
import { renderPartnerFlexibilitySection } from "@sections/partner-flexibly/PartnerFlexiblySection.adapter";
import { renderSphereEvents } from "@sections/sphere-events/SphereEvents.adapter";
import { renderSponsorshipSection } from "@sections/sponsorship/SponsorshipSection.adapter";
import { renderOurStoryInNumbersSection } from "@sections/story-in-numbers/OurStoryInNumbersSection.adapter";
import { renderSuccessStoriesSection } from "@sections/success-stories/SuccessStoriesSection.adapter";
import { renderTestimonialSection } from "@sections/testimonials/TestimonialsSection.adapter";
import { renderTimelineSection } from "@sections/timeline/TimelineSection.adapter";
import { renderTrifoldSection } from "@sections/trifold-section/TrifoldSection.adapter";
import { renderVlGroupSection } from "@sections/vl-group/VlGroupSection.adapter";
import { renderYourNeedsSection } from "@sections/your-needs/YourNeedsSection.adapter";
import { JSX, ReactNode } from "react";
import { RendererArg } from "@/types/renderer";
import {
  GlobalContentSectionComponent,
  HomeSectionComponent,
  SectionComponent,
} from "@/types/sections";

type HomeSections =
  | HomeSectionComponent
  | GlobalContentSectionComponent
  | SectionComponent;

const contentVariants: Record<
  HomeSections,
  (data: RendererArg) => JSX.Element
> = {
  "testimonials-placeholder": renderTestimonialSection,
  "flexible-partners-placeholder": renderPartnerFlexibilitySection,
  "accordion-cards-3col": renderTrifoldSection,
  "accordion-tabs": renderAccordionSection,
  "gallery-swipe": renderCarouselSection,
  "column-squares": renderColumnSquares,
  "column-steps": renderColumnSteps,
  "story-in-numbers": renderOurStoryInNumbersSection,
  "company-records": renderCompanyRecords,
  "icon-list": renderIconListSection,
  "icons-in-categories": renderIconsInCategoriesSection,
  "people-experts": renderExpertsSection,
  "vl-group": renderVlGroupSection,
  "image-spacer": renderImageSpacerSection,
  "columned-know-how": renderYourNeedsSection,
  video: renderVideoSection,
  "shortcuts-list": renderFingerPulse,
  "default-full-page-text": renderDefaultFullPageText,
  "sphere-events": renderSphereEvents,
  timeline: renderTimelineSection,
  "success-stories": renderSuccessStoriesSection,
  "open-source-projects": renderOpenSourceSection,
  "sponsorship-logos": renderSponsorshipSection,
  "chess-image-text": renderChessSection,
  "h-header-subpage": renderHeaderSubpageSection,
  communing: renderCommuningSection,
  "events-list": renderEventsListSection,
  "horizontal-steps": renderHorizontalStepsSection,
  burger: renderBurgerSection,
  "big-numbers": renderBigNumbers,
  "horizontal-accordion": renderHorizontalAccordionSection,
  "header-homepage": renderHeaderHomepageSection,
  mountains: renderMountains,
};

type Variant = keyof typeof contentVariants;

export const contentRenderer = (data: RendererArg): ReactNode => {
  const { componentName } = data;

  const variant = contentVariants[componentName as Variant];

  if (!variant) {
    // eslint-disable-next-line no-console
    console.error(
      `Component ${componentName} has no implementation in contentRenderer`
    );
    return null;
  }

  return variant(data);
};
