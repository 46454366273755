import {
  ContentSectionsWarningComponent,
  ContentSectionsWarningComponentIconEnum,
} from "@api/contract";
import { iconAdapter } from "@atoms/icon/Icon.adapter";
import { RendererArg } from "@/types/renderer";
import { WarningBlock } from "./WarningBlock";
import { AllowedIcon, WarningBlockProps } from "./WarningBlock.types";

const icons: Record<ContentSectionsWarningComponentIconEnum, AllowedIcon> = {
  questionmark: "Info",
  warning: "warning",
};

const map = (data: ContentSectionsWarningComponent): WarningBlockProps => ({
  description: data.description ?? "",
  icon: data.icon_file?.data
    ? iconAdapter(data.icon_file?.data?.attributes)
    : icons[data.icon ?? "warning"],
  title: data.heading,
});

export const renderWarningBlock = ({ contentProps }: RendererArg) => (
  <WarningBlock {...map(contentProps as ContentSectionsWarningComponent)} />
);
