import { SettingsSectionsSectionPartnersComponent } from "@api/contract";
import { iconAdapter } from "@atoms/icon/Icon.adapter";
import { GreenRoundedTileProps } from "@molecules/green-rounded-tile/GreenRoundedTile.types";
import { SectionComponent } from "@/types/component";
import { RendererArg } from "@/types/renderer";
import { PartnerFlexiblySection } from "./PartnerFlexiblySection";
import { PartnerFlexiblySectionProps } from "./PartnerFlexiblySection.types";

type StrapiFlexiblySectionAdapter = {
  globalPartners?: SettingsSectionsSectionPartnersComponent;
};

type Tag = {
  title?: string;
  slug?: string;
};

export const partnerFlexiblySectionAdapter = ({
  globalPartners,
}: StrapiFlexiblySectionAdapter): PartnerFlexiblySectionProps &
  SectionComponent => {
  const greenTiles: GreenRoundedTileProps[] | undefined =
    globalPartners?.service_tiles &&
    globalPartners.service_tiles.map((service) => ({
      icon: iconAdapter(service?.icon_file?.data?.attributes),
      title: service.title ?? "",
      text: service.description,
      urlAddress: service.service?.data?.attributes?.slug ?? "",
    }));

  const businessTags =
    globalPartners?.business_tile?.business_sectors?.data &&
    globalPartners?.business_tile?.business_sectors?.data.map((business) => ({
      children: (business.attributes as Tag).title,
      href: `/sectors/${(business.attributes as Tag).slug}`,
    }));

  const capabilitiesTags =
    globalPartners?.capabilities_tile?.capabilities?.data &&
    globalPartners?.capabilities_tile?.capabilities?.data.map((capability) => ({
      children: (capability.attributes as Tag).title,
      href: `/capabilities/${(capability.attributes as Tag).slug}`,
    }));

  const blueTiles = [
    { header: globalPartners?.business_tile?.title, tags: businessTags },
    {
      header: globalPartners?.capabilities_tile?.title,
      tags: capabilitiesTags,
    },
  ];
  return {
    header: globalPartners?.title ?? "",
    subheader: globalPartners?.description ?? "",
    greenTiles,
    blueTiles,
  };
};

export const renderPartnerFlexibilitySection = ({
  contentProps,
}: RendererArg) => {
  const props = partnerFlexiblySectionAdapter({
    globalPartners: contentProps as SettingsSectionsSectionPartnersComponent,
  });

  return <PartnerFlexiblySection {...props} />;
};
