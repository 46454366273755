import { ContentSectionsTablesComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { TableBlock } from "./TableBlock";
import { TableBlockProps } from "./TableBlock.types";

const map = ({ table }: ContentSectionsTablesComponent): TableBlockProps => ({
  table,
});

export const renderTableBlock = ({ contentProps }: RendererArg) => (
  <TableBlock {...map(contentProps as ContentSectionsTablesComponent)} />
);
