import { postContactForm } from "@utils/api/handlers/contact-form";
import { z } from "zod";
import { ContactFormSchema } from "./ContactSection.types";

const arrayItem = z.object({ name: z.string(), isIncluded: z.boolean() });

export const contactFormSchema = z.object({
  topics: z.array(arrayItem).refine((arr) => arr.some((it) => it.isIncluded), {
    message: "Please choose at least one interest",
  }),
  firstName: z.string().min(1, "Required").max(80, "First name is too long"),
  lastName: z.string().min(1, "Required").max(80, "Last name is too long"),
  email: z.string().email(),
  projectDescription: z
    .string()
    .min(1, "Required")
    .max(2000, "Project description should take no more than 2000 characters"),
  cameFrom: z
    .array(arrayItem)
    .refine((arr) => arr.some((it) => it.isIncluded), {
      message: "Please choose at least one origin",
    }),
  captchaToken: z.string().min(1),
  shouldProcessPersonalData: z.literal(true),
  shouldReceiveNewsletter: z.boolean(),
});

export const toArrayItem = (name: string): z.infer<typeof arrayItem> => ({
  name,
  isIncluded: false,
});

export const postForm = async (values: ContactFormSchema) =>
  postContactForm({
    recaptchaResponse: values.captchaToken,
    name: values.firstName,
    surname: values.lastName,
    message: values.projectDescription,
    companyMail: values.email,
    url: window.location.href,
    checkboxValue: values.shouldReceiveNewsletter,
    topics: values.topics
      .filter((it) => it.isIncluded)
      .map((it) => it.name)
      .join(", "),
    cameFrom: values.cameFrom
      .filter((it) => it.isIncluded)
      .map((it) => it.name)
      .join(", "),
  });
