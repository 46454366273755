import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { type IconListSectionProps } from "@sections/icon-list/IconListSection.types";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { IconListCard } from "./cards/IconListCard";

export const IconListSection: FC<IconListSectionProps> = ({
  title,
  description,
  cards,
  showNumbers,
  themeName,
  anchorId,
}) => (
  <>
    <ContainerSpacing
      withBackground
      isTransparentBackground
      id={anchorId}
      data-strapi="icon-list"
      data-theme={themeName}
    >
      <div className="container-flexible">
        <SectionHeader
          withMarginBottom
          className="text-content-primary-on-light md:col-span-12"
          title={title}
          subtitle={description}
        />
        {cards && (
          <div
            className={cn(
              "grid gap-3.5 md:gap-6 lg:gap-x-9 lg:gap-y-5 xl:gap-5 2xl:gap-10",
              cards.length >= 2 && "lg:grid-cols-2",
              cards.length === 3 && "xl:grid-cols-3",
              cards.length >= 4 && "xl:grid-cols-4"
            )}
          >
            {cards.map((card, ind) => (
              <IconListCard
                key={card.id}
                counter={showNumbers ? ind + 1 : undefined}
                {...card}
              />
            ))}
          </div>
        )}
      </div>
    </ContainerSpacing>
  </>
);
