import { ArticleLink } from "@molecules/article-link/ArticleLink";
import { Pagination } from "components/lib/molecules/pagination/Pagination";
import { useRouter } from "next/router";
import { FC } from "react";
import { CaseStudiesListProps } from "./CaseStudiesList.types";

export const CaseStudiesList: FC<CaseStudiesListProps> = ({
  paginationPrefix,
  currentPage,
  pagesTotal,
  list,
  title,
  linkPrefix,
}) => {
  const router = useRouter();
  const { industryName } = router.query;

  const headerText = industryName
    ? `Industries: ${
        industryName && industryName[0].toUpperCase() + industryName.slice(1)
      }`
    : title;

  return (
    <div className="container-flexible">
      <h1 className="t-subpage-h1 mb-11 mt-24 sm:mb-12 md:mt-28">
        {headerText}
      </h1>
      <div className="flex flex-row flex-wrap">
        {list &&
          list.map((item, idx) => (
            <ArticleLink
              image={item.image}
              caption={item.caption}
              summary={item.title}
              index={1}
              key={idx}
              isArrowVisible={false}
              body={item.body}
              url={`${linkPrefix}/${item.slug}`}
              className="mb-5 w-full md:w-[48%] md:min-w-[48%] lg:w-[32%] lg:min-w-[32%]"
            />
          ))}
      </div>
      <Pagination
        prefix={paginationPrefix}
        totalPages={pagesTotal}
        currentPage={currentPage}
      />
    </div>
  );
};
