import { SimpleNode } from "@utils/dom/parsed-html";
import { findLast } from "lodash";
import { useEffect, useState } from "react";

type Heading = SimpleNode & { element?: Element };

const findHighlightedNode = (items: Heading[]) =>
  findLast(items, ({ element }) => {
    const littleOffset = 5;
    // TODO - navbarHeight should be a global var
    const navbarHeight = 85;
    return (
      element &&
      element.getBoundingClientRect().top <= navbarHeight + littleOffset
    );
  }) as Heading | undefined;

const findHeading = (it: SimpleNode): Heading => ({
  ...it,
  element: document.getElementById(it.id) ?? undefined,
});

export const useHighlightedNode = (
  headingNodes: SimpleNode[]
): string | undefined => {
  const [nodeId, setNodeId] = useState<string>();

  useEffect(() => {
    if (!headingNodes.length) return;
    const headings = headingNodes.map(findHeading);
    const handleScroll = () => setNodeId(findHighlightedNode(headings)?.id);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headingNodes]);

  return nodeId;
};
