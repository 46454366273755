import { HeaderTypes } from "@atoms/headline";
import Link from "next/link";
import { FC, ReactNode } from "react";

interface StringWithLinksProps {
  children?: string;
  className?: string;
  tag?: "div" | "span" | "p" | HeaderTypes;
  linksIdPrefix?: string;
}

export const StringWithLinks: FC<StringWithLinksProps> = ({
  children,
  className,
  tag,
  linksIdPrefix,
}) => {
  const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1.*?>(.*?)<\/a>/g;
  const parts = children?.split(regex) ?? [];
  const elements: ReactNode[] = [];
  const Tag = tag ? tag : "span";
  parts.forEach((part, index) => {
    if (index % 4 === 0) {
      elements.push(part);
    } else if (index % 4 === 1) {
      const href = parts[index + 1];
      const text = parts[index + 2];
      elements.push(
        <Link
          className="link"
          key={index}
          href={href}
          id={`${linksIdPrefix}-${index}`}
        >
          {text}
        </Link>
      );
    }
  });
  return <Tag className={className}>{elements}</Tag>;
};
