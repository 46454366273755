import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { QuoteBlockProps } from "./QuoteBlock.types";

export const QuoteBlock: FC<QuoteBlockProps> = ({
  quote,
  author,
  authorDescription,
}) => (
  <div
    className={cn(
      marginBottomWrapperStyles,
      "relative w-full border-l-2 border-green-accent-two pl-9 text-content-primary-on-light",
      "sm:pl-11"
    )}
  >
    <p className="t-body-quote mb-3.5 md:mb-6 2xl:mb-8">“{quote}”</p>
    <p className="t-body-b2">
      {author}
      <span className="block text-green-accent-two">{authorDescription}</span>
    </p>
  </div>
);
