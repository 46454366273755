import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsShortcutsListComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { RendererArg } from "@/types/renderer";
import { FingerPulseSectionProps } from "./FingerPulse.types";
import { FingerPulseSection } from "./FingerPulseSection";

const map = ({
  block_settings,
  header,
  description,
  shortcuts,
}: ServiceSectionsShortcutsListComponent): FingerPulseSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title: header ?? "",
  subtitle: description ?? "",
  connections:
    shortcuts?.map(({ id, image, title, description, url, url_text }) => ({
      id: id ?? 0,
      logo: image?.data as ApiImage,
      title: title ?? "",
      description: description ?? "",
      link: {
        href: url ?? "",
        label: url_text ?? "",
      },
    })) ?? [],
});

export const renderFingerPulse = ({ contentProps }: RendererArg) => (
  <FingerPulseSection
    {...map(contentProps as ServiceSectionsShortcutsListComponent)}
  />
);
