import { DynamicIconComponent } from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { type IconListCardProps } from "@sections/icon-list/IconListSection.types";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export const IconListCard: FC<IconListCardProps> = ({
  description,
  icon,
  counter,
}) => {
  const showCounter = counter !== undefined;
  return (
    <div className="flex gap-1 lg:gap-0 xl:flex-col 2xl:flex-row">
      {icon && (
        <div
          className={cn(
            "relative size-[4.5rem] flex-none md:size-[5.625rem] xl:mx-2.5 xl:mb-4 2xl:mx-0 2xl:mb-0",
            showCounter && "rounded-full bg-icon white:bg-green-saturated"
          )}
        >
          {showCounter && (
            <Typography
              tag="span"
              variant="h2"
              weight="medium"
              className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-icon-fg white:text-greendom-off-white"
            >
              {counter}
            </Typography>
          )}
          {!showCounter && (
            <DynamicIconComponent
              maxKnownWidth={150}
              backgroundImage
              {...icon}
            />
          )}
        </div>
      )}
      {description && (
        <Typography
          tag="p"
          variant="base"
          weight="normal"
          wysiwygStyling
          dangerouslySetInnerHTML={{ __html: description }}
          className="px-3 py-2.5 text-content-primary-on-light md:px-5 xl:px-2.5 2xl:px-5"
        />
      )}
    </div>
  );
};
