import { FC } from "react";
import { CuratedByProps } from "./CuratedBy.types";

const piwikAuthorId = "curator";

export const CuratedBy: FC<CuratedByProps> = ({ author }) =>
  author && (
    <div className="grid w-full justify-end gap-1">
      <p className="t-body-micro w-max text-secondary">Curated by</p>
      <p className="t-body-b2 w-max text-primary" id={piwikAuthorId}>
        {author}
      </p>
    </div>
  );
