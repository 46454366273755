import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { tw } from "@utils/tailwind";
import { FC, PropsWithChildren } from "react";
import { ContentListProps } from "./ContentList.types";

export const ContentList: FC<PropsWithChildren<ContentListProps>> = ({
  variant,
  children,
  isTransparentBackground,
}) => {
  const sectorStyles = tw`flex flex-col gap-5 2xl:[&_div:odd]:flex-row-reverse`;
  const tilesStyles = tw`grid gap-5 md:min-w-0 md:grid-cols-2 2xl:grid-cols-3`;
  return (
    <ContainerSpacing
      withBackground
      data-strapi="content-list"
      className={isTransparentBackground ? "bg-transparent" : "bg-blue-mid"}
    >
      <div className="container-flexible">
        <div className={variant === "sectors" ? sectorStyles : tilesStyles}>
          {children}
        </div>
      </div>
    </ContainerSpacing>
  );
};
