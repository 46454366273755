import { blockSettingsAdapter } from "@adapters/block-settings";
import { HomeSectionsAccordionTabsComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { AccordionSection, AccordionSectionProps } from "./AccordionSection";

const map = ({
  main_title,
  accordion_tabs,
  block_settings,
}: HomeSectionsAccordionTabsComponent): AccordionSectionProps => ({
  ...blockSettingsAdapter({ blockSettings: block_settings }),
  mainTitle: main_title,
  accordionTabs: accordion_tabs,
});

export const renderAccordionSection = ({ contentProps }: RendererArg) => (
  <AccordionSection
    {...map(contentProps as HomeSectionsAccordionTabsComponent)}
  />
);
