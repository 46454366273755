import { ArticleTile } from "@atoms/articleTile/ArticleTile";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { ArticlesListProps } from "./ArticlesList.types";

export const ArticlesList: FC<ArticlesListProps> = ({
  title,
  className,
  articles,
  size,
  headlineClassName,
}) =>
  articles &&
  articles.length > 0 && (
    <div className={className}>
      <h6 className={cn("t-headline-h6 mb-5", headlineClassName)}>{title}</h6>
      <ul className="p-0">
        {articles.map((article, index) => (
          <ArticleTile
            size={size}
            {...article}
            key={`${article.title}-${index}`}
          />
        ))}
      </ul>
    </div>
  );
