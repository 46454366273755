import { PillButton } from "@molecules/pill-button/PillButton";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { TrifoldSectionProps } from "@sections/trifold-section/TrifoldSection.types";
import { ExpandableCards } from "@sections/trifold-section/expandable-cards/ExpandableCards";
import { cn } from "@utils/tailwind";
import { FC, useRef } from "react";
import styles from "./TrifoldSection.module.css";

export const TrifoldSection: FC<TrifoldSectionProps> = ({
  title,
  description,
  cards,
}) => {
  const cardsRef = useRef<null | HTMLDivElement>(null);
  const onButtonClick = () => {
    cardsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section
      data-strapi="trifold-section"
      className="bg-[#012116] py-24 sm:py-32 md:py-24 lg:py-32 xl:py-36 2xl:py-40"
    >
      <div className="container-flexible mb-9 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16">
        <SectionHeader
          title={title}
          subtitle={description}
          className="border-t border-content-primary-on-dark pb-11 pt-9 text-blue-light md:pt-11 lg:pt-10 xl:pt-14 2xl:pt-20"
        />
        <PillButton
          background="dark"
          onClick={onButtonClick}
          aria-label="go to next section"
          className="h-fit"
          iconClassName="rotate-90"
        />
      </div>
      {cards && (
        <div
          className={cn(
            "container-flexible w-full",
            styles.largeContainerFlexible
          )}
        >
          <ExpandableCards cards={cards} ref={cardsRef} />
        </div>
      )}
    </section>
  );
};
