import { SmallComponentsResponsiveImageComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";

export type ResponsiveImages = {
  mobile?: ApiImage;
  tablet?: ApiImage;
  desktop?: ApiImage;
};

export const getResponsiveImages = (
  image?: SmallComponentsResponsiveImageComponent
): ResponsiveImages | undefined =>
  image?.mobile?.data || image?.tablet?.data || image?.desktop?.data
    ? {
        mobile: image?.mobile?.data,
        tablet: image?.tablet?.data,
        desktop: image?.desktop?.data,
      }
    : undefined;
