import { Blog } from "./blog/Blog";
import { BlogsList } from "./blogs-list/BlogsList";
import { CaseStudiesList } from "./case-studies/CaseStudiesList";
import { CaseStudy } from "./case-study/CaseStudy";
import { Contact } from "./contact/Contact";
import { DynamicList } from "./dynamic-list/DynamicList";
import { DynamicPage } from "./dynamic-page/DynamicPage";
import { Home } from "./home/Home";
import { NotFound } from "./not-found/NotFound";
import { ThankYou } from "./thank-you/ThankYou";

export const Pages = {
  Home,
  Blog,
  BlogsList,
  CaseStudiesList,
  CaseStudy,
  Contact,
  DynamicPage,
  ThankYou,
  NotFound,
  DynamicList,
};
