import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { cn, tw } from "@utils/tailwind";
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react";

export type CheckboxFieldProps = ComponentPropsWithoutRef<"input"> & {
  id: string;
  label?: string;
  error?: string;
  afterLabel?: ReactNode;
};

const checkboxStyles = tw`peer
  size-5
  flex-none
  cursor-pointer
  appearance-none
  rounded-sm
  border
  border-green-black-equivalent
  outline-none
  focus:ring-1
  focus:ring-blue-500
  active:ring-1
  active:ring-blue-500`;

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ label, afterLabel, error, className, ...inputProps }, ref) => (
    <Typography
      tag="div"
      variant="sm"
      weight="medium"
      className={cn(
        "relative flex items-center gap-2.5",
        error && "text-error",
        className
      )}
    >
      <input
        type="checkbox"
        className={cn(
          checkboxStyles,
          error && "border-error focus:border-error"
        )}
        ref={ref}
        {...inputProps}
      />
      <Icon
        name="Thick"
        className="pointer-events-none absolute left-0.5 hidden size-4 peer-checked:block"
      />
      <label htmlFor={inputProps.id}>{label}</label>
      {afterLabel}
    </Typography>
  )
);

CheckboxField.displayName = "CheckboxField";
