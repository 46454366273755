import { Image } from "@atoms/image/Image";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { AuthorTileProps } from "./AuthorTile.types";

const piwikAuthorId = "author";

export const AuthorTile: FC<AuthorTileProps> = ({
  author,
  className,
  background = "Dark",
}) => {
  const typographyColor = cn(
    background == "Dark" ? "text-blue-light" : "text-grey-scale-text"
  );

  return (
    <div className={cn("flex", className)}>
      {author.image && (
        <div className="relative mr-4 flex size-8 overflow-hidden rounded-full bg-neutral-200 md:size-11">
          <Image
            backgroundImage
            alt={`Picture of ${author.name}, ${author.position}`}
            maxKnownWidth={44}
            imageData={author.image}
          />
        </div>
      )}
      <div className={cn("flex flex-col", typographyColor)}>
        <p id={piwikAuthorId} className="text-sm opacity-90 md:text-base">
          {author.name}
        </p>
        <span className="text-xs opacity-90 md:text-sm">{author.position}</span>
      </div>
    </div>
  );
};
