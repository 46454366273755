import { CommandItem } from "@molecules/command/Command";
import { HitProps } from "@page-components/blogs-list/searchbar/hit/Hit.types";
import { endpoints } from "@utils/preview/endpoints";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";

export const Hit: FC<HitProps> = ({ title, categorySlug, slug, isStale }) => {
  const router = useRouter();
  const href = categorySlug ? endpoints.blog(categorySlug, slug) : "";

  if (!href) return null;
  return (
    <CommandItem asChild value={title} onSelect={() => router.push(href)}>
      <Link
        className={cn("cursor-pointer", isStale && "opacity-50")}
        href={href}
      >
        {title}
      </Link>
    </CommandItem>
  );
};
