import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsSponsorshipLogosComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { SponsorshipSectionProps } from "@sections/sponsorship/SponsorshipSection.types";
import { RendererArg } from "@/types/renderer";
import { SponsorshipSection } from "./SponsorshipSection";

const map = ({
  header,
  description,
  images,
  block_settings,
}: ServiceSectionsSponsorshipLogosComponent): SponsorshipSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "darkGreen",
  }),
  title: header ?? "",
  rawHtmlDescription: description ?? "",
  logos: images?.map((it) => it.image_file?.data as ApiImage) ?? [],
});

export const renderSponsorshipSection = ({ contentProps }: RendererArg) => (
  <SponsorshipSection
    {...map(contentProps as ServiceSectionsSponsorshipLogosComponent)}
  />
);
