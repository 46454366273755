import { FC } from "react";
import { contentRenderer } from "utils/content-renderer/contentRenderer";
import { extractNameAndRender } from "utils/content-renderer/extract-and-render/extractAndRender";
import { WithComponent } from "@/types/component";

export type DynamicPageProps = {
  content: WithComponent[];
};

export const DynamicPage: FC<DynamicPageProps> = ({ content }) => (
  <>
    {content.map((page_section, idx) =>
      extractNameAndRender(page_section, contentRenderer, idx)
    )}
  </>
);
