import { Button } from "@atoms/button/Button";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { Carousel } from "@sections/carousel/components/Carousel";
import { FC } from "react";
import { CarouselSectionProps } from "./CarouselSection.types";

export const CarouselSection: FC<CarouselSectionProps> = ({
  carouselImages,
  button,
  anchorId,
}) => (
  <section id={anchorId} data-strapi="carousel" className="grid text-clip">
    <ContainerSpacing
      asDiv
      isTransparentBackground
      withBackground
      className="col-start-1 col-end-2 row-start-1 row-end-2 h-full overflow-hidden"
    >
      {carouselImages && <Carousel images={carouselImages} />}

      {button?.buttonUrl && (
        <div className="container-flexible flex content-center pt-8 sm:pt-10 md:pt-12 lg:pt-16 xl:pt-[5.375rem] 2xl:pb-2 2xl:pt-9">
          <Button
            intent="secondary"
            icon="ArrowTopRight"
            href={button.buttonUrl}
            asLink
            className="w-full sm:w-auto"
          >
            {button.buttonText}
          </Button>
        </div>
      )}
    </ContainerSpacing>
  </section>
);
