import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsChessImageTextComponent } from "@api/contract";
import { ImagesApi } from "@api/types";
import { RendererArg } from "@/types/renderer";
import { ChessSection } from "./ChessSection";
import {
  ChessCard,
  ChessCardBase,
  ChessSectionProps,
} from "./ChessSection.types";

const mapCardImages = (images?: ImagesApi) =>
  images?.data?.map((dd) => ({
    data: {
      ...dd,
    },
  })) ?? [];

const map = ({
  title,
  description,
  show_top_border,
  cards_2: cards,
  block_settings,
}: ServiceSectionsChessImageTextComponent): ChessSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "beige",
  }),
  title,
  rawHtmlSubtitle: description,
  showDecorativeLine: show_top_border,
  cards:
    cards?.map(
      ({
        id,
        description,
        image_position,
        image_variant,
        images,
        title: cardTitle,
      }) => {
        const cardImages = mapCardImages(images);
        const base: ChessCardBase = {
          id: id ?? 0,
          title: cardTitle || "",
          rawHtmlDescription: description || "",
          direction: image_position === "right" ? "LR" : "RL",
        };
        const card: ChessCard =
          image_variant === "multiple-centered"
            ? {
                ...base,
                variant: "with-logos",
                logos: cardImages.map((image) => ({ src: image.data })),
              }
            : {
                ...base,
                variant: "with-image",
                desktopAspectRatio:
                  image_variant === "default" ? "default" : "panoramic",
                image: cardImages[0].data,
              };
        return card;
      }
    ) ?? [],
});

export const renderChessSection = ({ contentProps }: RendererArg) => (
  <ChessSection
    {...map(contentProps as ServiceSectionsChessImageTextComponent)}
  />
);
