import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { CollapsableList } from "@molecules/collapsable-list/CollapsableList";
import { FC } from "react";

export type AccordionTab = {
  description?: string;
  icon?: string;
  title?: string;
};

export type AccordionSectionProps = {
  mainTitle?: string;
  accordionTabs?: AccordionTab[];
};

export const AccordionSection: FC<AccordionSectionProps> = ({
  mainTitle,
  accordionTabs,
}) => {
  const title = mainTitle ?? null;

  const items = accordionTabs
    ? accordionTabs.map((tab) => ({
        title: tab.title,
        description: tab.description,
      }))
    : null;

  return (
    <ContainerSpacing isTransparentBackground data-strapi="accordion">
      <div className="container-flexible">
        <div className="flex flex-col lg:flex-row lg:gap-x-10">
          {title && (
            <Typography
              tag="h2"
              variant="h2"
              className="mb-10 text-grey-scale-off-black md:mb-12 lg:w-[35%]"
            >
              {title}
            </Typography>
          )}
          {items && <CollapsableList className="lg:w-2/3" items={items} />}
        </div>
      </div>
    </ContainerSpacing>
  );
};
