import { ContentSectionsImageComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { RendererArg } from "@/types/renderer";
import { ImageBlock } from "./ImageBlock";
import { ImageBlockProps } from "./ImageBlock.types";

const map = (data: ContentSectionsImageComponent): ImageBlockProps => ({
  imageObject: data.image?.data as ApiImage,
  caption: data.caption,
});

export const renderImageBlock = ({ contentProps }: RendererArg) => (
  <ImageBlock {...map(contentProps as ContentSectionsImageComponent)} />
);
