import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { ColumnSquaresProps } from "@sections/column-squares/ColumnSquares.types";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import LocalSvg from "./components/LocalSvg";
import { Square } from "./square/Square";

export const ColumnSquares: FC<ColumnSquaresProps> = ({
  themeName,
  title,
  description,
  tiles,
  anchorId,
  sideImage = "no-image",
}) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground
    id={anchorId}
    data-strapi="column-squares"
    data-theme={themeName}
  >
    <div className="relative overflow-hidden">
      {sideImage !== "no-image" && (
        <LocalSvg
          name={sideImage}
          className="absolute right-[-27.75rem] top-14 hidden lg:block xl:-right-64 xl:top-[3.75rem] 2xl:-right-5 2xl:top-16"
        />
      )}
      <div className="container-flexible relative grid gap-9 md:gap-10 lg:grid-cols-12 lg:gap-12 xl:gap-14 2xl:gap-16">
        <div className="grid gap-4 text-content-primary-on-light md:gap-5 lg:col-span-10 xl:col-span-8 xl:gap-6 2xl:col-span-7 2xl:gap-7">
          <Typography tag="h2" variant="h2" weight="medium">
            {title}
          </Typography>
          {description && (
            <Typography
              tag="span"
              variant="base"
              weight="normal"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        {tiles && (
          <div
            className={cn(
              "grid overflow-hidden rounded-4xl border border-green-accent-two bg-content-primary-on-dark lg:col-span-12 lg:grid-cols-3",
              tiles.length === 1 && "lg:grid-cols-1",
              tiles.length % 2 === 0 && "lg:grid-cols-2",
              tiles.length % 3 === 0 && "lg:grid-cols-3"
            )}
          >
            {tiles.map((tile, index) => (
              <Square {...tile} key={`${tile.title}-${index}`} />
            ))}
          </div>
        )}
      </div>
    </div>
  </ContainerSpacing>
);
