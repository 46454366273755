import { blockSettingsAdapter } from "@adapters/block-settings";
import { HeadersHHeaderSubpageComponent } from "@api/contract";
import { ButtonProps } from "@atoms/button/Button.types";
import { Logo } from "@molecules/logo-looper/LogoLooper.types";
import { isEmpty } from "lodash";
import { RendererArg } from "@/types/renderer";
import { HeaderSubpageSection } from "./HeaderSubpageSection";
import {
  HeaderSubpageProps,
  UnderHeaderProps,
} from "./HeaderSubpageSection.types";

const headerSubpageAdapter = ({
  header,
  subheader,
  cta,
  show_border,
  disable_cta,
  block_settings,
}: HeadersHHeaderSubpageComponent): HeaderSubpageProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  subheader: subheader,
  cta: {
    href: cta?.button_url || "/contact",
    buttonLabel: cta?.button_text || "Contact us",
    target: cta?.button_new_window,
  } as ButtonProps,
  header: header ?? "",
  showBorder: show_border,
  disableCta: disable_cta,
});

export const underHeaderAdapter = ({
  under_header_title,
  under_header_img,
}: HeadersHHeaderSubpageComponent): UnderHeaderProps => ({
  title: under_header_title || undefined,
  logos:
    under_header_img
      ?.map(
        ({ link_image, link_url }): Logo => ({
          imageOrJSX: link_image?.data ?? {},
          href: link_url ?? "",
        })
      )
      .filter((it) => !isEmpty(it.imageOrJSX)) ?? [],
});

export const renderHeaderSubpageSection = ({ contentProps }: RendererArg) => (
  <>
    <HeaderSubpageSection
      {...headerSubpageAdapter(contentProps as HeadersHHeaderSubpageComponent)}
      underHeader={underHeaderAdapter(
        contentProps as HeadersHHeaderSubpageComponent
      )}
    />
  </>
);
