import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { ArticleTileProps, ArticleTileSize } from "./ArticleTile.types";

//TODO: TYPOGRAPHY

export const ArticleTile: FC<ArticleTileProps> = ({
  category,
  title,
  size = ArticleTileSize.DEFAULT,
  src,
}) => {
  const linkSize = cn(size === ArticleTileSize.SMALL ? "text-base" : "text-lg");
  return (
    <li className="mb-5 list-none p-0">
      <div>
        <Link
          className="mb-4 text-grey-faded no-underline transition-colors duration-200 ease-in hover:text-grey-scale-text"
          href={`/blog/${category}`}
          passHref
        >
          {category}
        </Link>
      </div>
      <Link className={cn("link", linkSize)} href={src ?? ""}>
        {title}
      </Link>
    </li>
  );
};
