import { TextAreaField } from "@atoms/text-area-field/TextAreaField";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ContactFormSchema } from "../ContactSection.types";

export const ProjectDescriptionField: FC<{ className?: string }> = ({
  className,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ContactFormSchema>();

  return (
    <TextAreaField
      required
      id="contact-project-description"
      label="What would you like to discuss?"
      error={errors.projectDescription?.message}
      className={className}
      {...register("projectDescription")}
    />
  );
};
