import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsPeopleExpertsComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { RendererArg } from "@/types/renderer";
import { ExpertsSection } from "./ExpertsSection";
import { ExpertsSectionProps } from "./ExpertsSection.types";

const map = ({
  title,
  experts,
  block_settings,
}: ServiceSectionsPeopleExpertsComponent): ExpertsSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "beige",
  }),
  header: title ?? "",
  experts:
    experts?.map(({ id, title, name, linkedin_url, quote, image }) => ({
      id: id ?? 0,
      title: title ?? "",
      name: name ?? "",
      quote: quote ?? "",
      href: linkedin_url || undefined,
      img: image?.data as ApiImage,
    })) ?? [],
});

export const renderExpertsSection = ({ contentProps }: RendererArg) => (
  <ExpertsSection
    {...map(contentProps as ServiceSectionsPeopleExpertsComponent)}
  />
);
