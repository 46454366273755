import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsHorizontalStepsComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { HorizontalStepsSection } from "./HorizontalStepsSection";
import { HorizontalStepsSectionTypes } from "./HorizontalStepsSection.constants";

const map = ({
  title,
  steps,
  block_settings,
}: ServiceSectionsHorizontalStepsComponent): HorizontalStepsSectionTypes => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "beige",
  }),
  title: title ?? "",
  steps:
    steps?.map(
      ({
        title,
        period,
        description,
        outcome,
        outcome_label,
        icon_first,
        icon_second,
      }) => ({
        title: title ?? "",
        period: period ?? "",
        description: description ?? "",
        outcome: outcome ?? "",
        outcome_label: outcome_label ?? "",
        icon_first: icon_first ?? undefined,
        icon_second: icon_second ?? undefined,
      })
    ) ?? [],
});

export const renderHorizontalStepsSection = ({ contentProps }: RendererArg) => (
  <HorizontalStepsSection
    {...map(contentProps as ServiceSectionsHorizontalStepsComponent)}
  />
);
