import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsColumnedKnowHowComponent } from "@api/contract";
import { YourNeedsSectionProps } from "@sections/your-needs/YourNeedsSection.types";
import { RendererArg } from "@/types/renderer";
import { YourNeedsSection } from "./YourNeedsSection";

const map = ({
  block_settings,
  headline,
  columns_kh,
  variant_5: variant,
  color,
}: ServiceSectionsColumnedKnowHowComponent): YourNeedsSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title: headline ?? "",
  variant: variant ?? "threeColumns",
  color: color ?? "light",
  cards:
    columns_kh?.map(({ title, description, href, id }) => ({
      id: id ?? 0,
      title: title ?? "",
      href: href ?? undefined,
      description: description ?? "",
    })) ?? [],
});

export const renderYourNeedsSection = ({ contentProps }: RendererArg) => (
  <YourNeedsSection
    {...map(contentProps as ServiceSectionsColumnedKnowHowComponent)}
  />
);
