import { blockSettingsAdapter } from "@adapters/block-settings";
import { callToActionButtonAdapter } from "@adapters/buttons";
import { HomeSectionsGallerySwipeComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { RendererArg } from "@/types/renderer";
import { CarouselSection } from "./CarouselSection";
import exampleImage from "/public/example_image.png";
import { CarouselSectionProps } from "./CarouselSection.types";

const map = ({
  gallery_images,
  button_c2a,
  bg_title,
  block_settings,
}: HomeSectionsGallerySwipeComponent): CarouselSectionProps => ({
  ...blockSettingsAdapter({ blockSettings: block_settings }),
  title: bg_title,
  button: callToActionButtonAdapter(button_c2a),
  carouselImages: gallery_images?.map(
    ({ gallery_img_size, gallery_image }) => ({
      variant: gallery_img_size ?? "horizontal",
      image: (gallery_image?.data as ApiImage) ?? exampleImage.src,
    })
  ),
});

export const renderCarouselSection = ({ contentProps }: RendererArg) => (
  <CarouselSection
    {...map(contentProps as HomeSectionsGallerySwipeComponent)}
  />
);
