import { FC } from "react";
import { SchemaProps } from "./Schema.types";

export const Schema: FC<SchemaProps> = ({ schema }) =>
  schema && (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
