import { postData } from "@utils/fetch/calls";

export type ContactFormBody = {
  message: string;
  companyMail: string;
  url: string;
  name?: string;
  surname?: string;
  checkboxValue?: boolean;
  recaptchaResponse?: string | null;
  topics?: string;
  cameFrom?: string;
};

export const postContactForm = async (body: ContactFormBody) => {
  try {
    return postData("/api/contact-form/", {
      body,
      signal: AbortSignal.timeout(7000),
    });
  } catch (error) {
    let errorMessage = "unknown error";
    if (error instanceof Error) {
      errorMessage =
        error.name === "TimeoutError"
          ? "request takes too long, try again"
          : error.message;
    }
    return { success: false, errorMessage };
  }
};
