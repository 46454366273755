import { ContentSectionsBlockParagraphComponent } from "@api/contract";
import { ContentType } from "@content-sections/helpers";
import { RendererArg } from "@/types/renderer";
import { FormattedText } from "./FormattedText";

const map = (data: ContentSectionsBlockParagraphComponent): ContentType => ({
  content: data.content ?? "",
});

export const renderFormatedTextSection = ({ contentProps }: RendererArg) => (
  <FormattedText
    {...map(contentProps as ContentSectionsBlockParagraphComponent)}
  />
);
