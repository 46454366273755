import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { cn } from "@utils/tailwind";
import { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { VideoBlockProps } from "./VideoBlock.types";

export const VideoBlock: FC<VideoBlockProps> = ({ videoURL, caption }) => {
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  return (
    hasWindow &&
    videoURL && (
      <div className={cn(marginBottomWrapperStyles, "relative w-full")}>
        <div
          style={{ boxShadow: "0px 33px 66px 0px rgba(11, 10, 10, 0.1)" }}
          className="relative w-full pt-[56.25%]"
        >
          <ReactPlayer
            width="100%"
            height="100%"
            playing={false}
            style={{ position: "absolute", top: "0", left: 0 }}
            url={videoURL}
          />
        </div>
        {caption && (
          <p className="t-body-caption mt-3.5 text-grey-scale-mid">{caption}</p>
        )}
      </div>
    )
  );
};
