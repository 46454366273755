import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { cn } from "@utils/tailwind";
import { useResolution } from "@utils/use-resolution/useResolution";
import { FC, useEffect, useRef, useState } from "react";
import { HorizontalStepsSectionTypes } from "./HorizontalStepsSection.constants";
import {
  desktopScroll,
  mobileScroll,
  useSelectActiveStep,
} from "./HorizontalStepsSection.helpers";

export const HorizontalStepsSection: FC<HorizontalStepsSectionTypes> = ({
  title,
  steps,
  themeName,
  anchorId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const stepsContainer = useRef<HTMLDivElement>(null);
  const resolution = useResolution();
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const [wrapperHeight, setWrapperHeight] = useState<number | undefined>(
    undefined
  );
  const { activeStep, setActiveStep } = useSelectActiveStep(
    steps,
    windowWidth,
    wrapperRef
  );

  useEffect(() => {
    if (containerRef.current && resolution.width) {
      const windowWidth = resolution.width - 10;
      setWidth((windowWidth - containerRef.current.clientWidth) / 2);
      setWindowWidth(windowWidth);
    }
  }, [containerRef, resolution]);

  useEffect(() => {
    if (wrapperRef.current) {
      const defaultWrapperHeight = wrapperRef.current.offsetHeight;
      setWrapperHeight(defaultWrapperHeight + steps.length * 300);
    }
  }, [wrapperRef, steps]);

  const activeStepTranslate = "translate-x-0";
  const activeStepColor = "text-primary";
  const noneFirtIndexPadding = "px-6";
  const stepsContainerWidth =
    windowWidth && windowWidth >= 1270
      ? "100%"
      : width
        ? `calc(100% + ${width}px)`
        : "100vw";
  return (
    steps &&
    steps.length > 0 && (
      <ContainerSpacing
        id={anchorId}
        ref={wrapperRef}
        withBackground
        data-theme={themeName}
        style={
          windowWidth && windowWidth >= 1270 && wrapperHeight
            ? { height: `${wrapperHeight}px` }
            : {}
        }
        className={cn(
          "relative max-w-full !p-0",
          !themeName && "bg-organic-lighter"
        )}
      >
        <div className="container-flexible py-[90px] sm:py-[100px] md:py-[120px] xl:sticky xl:top-0 xl:py-[160px] 2xl:py-[200px]">
          {title && (
            <Typography
              tag="h2"
              variant="h2"
              className="mb-6 text-primary md:mb-16 xl:mb-32"
            >
              {title}
            </Typography>
          )}
          <div
            style={{
              width: stepsContainerWidth,
            }}
            ref={stepsContainer}
            className="no-scrollbar relative mb-12 flex overflow-scroll xl:mb-24"
          >
            {steps.map((step, index) => (
              <div
                onClick={(event) => {
                  setActiveStep(index);
                  windowWidth && windowWidth < 1270
                    ? mobileScroll(event)
                    : desktopScroll(index, wrapperRef);
                }}
                key={`${step.title}-${index}`}
                className="min-w-52 cursor-pointer overflow-hidden pb-3 xl:min-w-0 xl:flex-1"
              >
                <p
                  className={cn(
                    "mb-4 text-2xl font-medium text-secondary transition-colors duration-500 xl:mb-5 xl:text-5xl",
                    activeStep === index && activeStepColor,
                    index !== 0 && noneFirtIndexPadding
                  )}
                >
                  {index <= 9 && 0}
                  {index + 1}
                </p>
                <span
                  className={cn(
                    "block h-0.5 translate-x-[-101%] bg-content-primary-on-light transition-transform duration-500 xl:h-1",
                    activeStep === index && activeStepTranslate
                  )}
                />
                <span className="mb-4 block h-px bg-grey-dim xl:mb-5" />
                <p
                  className={cn(
                    "m-0 text-base font-medium text-secondary transition-colors duration-500 xl:text-xl 2xl:text-2xl",
                    activeStep === index && activeStepColor,
                    index !== 0 && noneFirtIndexPadding
                  )}
                >
                  {step.title}
                </p>
              </div>
            ))}
          </div>
          <div className="flex overflow-hidden" ref={containerRef}>
            {steps.map((step, index) => (
              <div
                style={{
                  transform:
                    activeStep === 0
                      ? "translateX(0)"
                      : `translateX(-${activeStep * 100}%)`,
                }}
                className="min-w-full transition-transform duration-500 xl:flex xl:gap-8"
                key={`stepContent-${index}}`}
              >
                {(step.period || step.description) && (
                  <div className="mb-6 flex flex-wrap md:mb-7 md:flex-1 md:flex-nowrap md:gap-6">
                    {step.icon_first && step.icon_first !== "no-icon" && (
                      <Icon
                        className="mr-5 w-5 min-w-5 stroke-green-accent-two md:mr-0"
                        name={
                          step.icon_first === "clock"
                            ? "TimeOutline"
                            : "StarOutline"
                        }
                      />
                    )}
                    <div
                      dangerouslySetInnerHTML={{ __html: step.period }}
                      className="links-container mb-4 block text-nowrap font-bold text-primary md:min-w-28 md:text-wrap xl:min-w-32 xl:text-xl"
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: step.description }}
                      className="links-container w-full text-primary xl:text-xl"
                    />
                  </div>
                )}
                {(step.outcome || step.outcome_label) && (
                  <div className="mb-6 flex flex-wrap md:mb-7 md:flex-1 md:flex-nowrap md:gap-6">
                    {step.icon_second && step.icon_second !== "no-icon" && (
                      <Icon
                        className="mr-5 w-5 min-w-5 fill-green-accent-two md:mr-0"
                        name={
                          step.icon_second === "clock"
                            ? "TimeOutline"
                            : "StarOutline"
                        }
                      />
                    )}
                    <p className="mb-4 block text-nowrap font-bold text-primary md:min-w-28 md:text-wrap xl:min-w-32 xl:text-xl">
                      {step.outcome_label}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: step.outcome }}
                      className="links-container w-full text-primary xl:text-xl"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </ContainerSpacing>
    )
  );
};
