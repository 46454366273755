import { SystemSettingsBlockSettingsComponent } from "@api/contract";
import { ThemeName, themeNames } from "@utils/theming/color-scheme";
import { BlockSettings } from "@/types/component";

type BlockSettingInput = {
  blockSettings?: SystemSettingsBlockSettingsComponent;
  fallbackTheme?: ThemeName;
};

export const blockSettingsAdapter = ({
  blockSettings,
  fallbackTheme,
}: BlockSettingInput): BlockSettings => ({
  themeName: blockSettings
    ? themeNames.find((x) => x === blockSettings.theme)
    : fallbackTheme,
  anchorId: blockSettings?.anchor_id ?? undefined,
});
