import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsIconsInCategoriesComponent as ApiIconsInCategories } from "@api/contract";
import { ImagesApi as ApiImages } from "@api/types";
import { RendererArg } from "@/types/renderer";
import {
  CardProps,
  IconsInCategoriesSectionProps,
} from "./IconsInCategories.types";
import { IconsInCategoriesSection } from "./IconsInCategoriesSection";

type ApiCard = {
  category?: string;
  images?: ApiImages;
};

const buildCard = ({ category, images }: ApiCard): CardProps => ({
  title: category || "",
  images: images?.data ?? [],
});

const map = ({
  title,
  description,
  block_settings,
  tech_we_use,
  categories,
}: ApiIconsInCategories): IconsInCategoriesSectionProps => ({
  ...blockSettingsAdapter({ blockSettings: block_settings }),
  title: title ?? "",
  description: description ?? "",
  subtitle: tech_we_use ?? "",
  cards: categories?.map(buildCard) ?? [],
});

export const renderIconsInCategoriesSection = ({
  contentProps,
}: RendererArg) => (
  <IconsInCategoriesSection {...map(contentProps as ApiIconsInCategories)} />
);
