import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsSphereEventsComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { SphereEventsSectionProps } from "@sections/sphere-events/SphereEventsSection.types";
import { RendererArg } from "@/types/renderer";
import { SphereEventsSection } from "./SphereEventsSection";

const map = ({
  header,
  description,
  sphere_events,
  block_settings,
}: ServiceSectionsSphereEventsComponent): SphereEventsSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title: header ?? "",
  subtitle: description ?? "",
  cards:
    sphere_events?.map(({ id, title, description, url, image }) => ({
      id: id ?? 0,
      title: title ?? "",
      description: description ?? "",
      href: url ?? "url",
      image: image?.data as ApiImage,
    })) ?? [],
});

export const renderSphereEvents = ({ contentProps }: RendererArg) => (
  <SphereEventsSection
    {...map(contentProps as ServiceSectionsSphereEventsComponent)}
  />
);
