import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsVlGroupComponent } from "@api/contract";
import { getResponsiveImages } from "@utils/images/responsive-images";
import { RendererArg } from "@/types/renderer";
import { VlGroupSection } from "./VlGroupSection";
import { VlGroupSectionProps } from "./VlGroupSection.types";

const map = ({
  block_settings,
  title,
  description,
  resp_image,
}: ServiceSectionsVlGroupComponent): VlGroupSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "darkGreen",
  }),
  title: title ?? "",
  rawHtmlDescription: description ?? "",
  responsiveImages: getResponsiveImages(resp_image),
});

export const renderVlGroupSection = ({ contentProps }: RendererArg) => (
  <VlGroupSection {...map(contentProps as ServiceSectionsVlGroupComponent)} />
);
