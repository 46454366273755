import Headline from "@atoms/headline";
import ContainerFlexible from "@layout/container-flexible/ContainerFlexible";
import { ArticlesList } from "@molecules/ArticlesList/ArticlesList";
import { ShareButton } from "@molecules/share-button/ShareButton";
import { Tags } from "@molecules/tags/Tags";
import { FC } from "react";
import { ArticleTag } from "@/types/article";
import { ArticleProps } from "../BlogLayout.types";

type BlogBottomProps = {
  articleTags?: ArticleTag[];
  interestingArticles?: ArticleProps[];
};

export const BlogBottom: FC<BlogBottomProps> = ({
  articleTags,
  interestingArticles,
}) => (
  <ContainerFlexible className="2xl:mx-auto 2xl:max-w-7xl">
    <div className="mx-auto mb-32 mt-24 max-w-full flex-1 border-t border-solid border-black pt-16 md:mb-40 xl:my-52 2xl:w-1/2 2xl:pt-28">
      <Headline className="text-center" tag="h3">
        Liked the article?
      </Headline>
      <p className="t-body-b2 my-5 text-center text-grey-scale-mid">
        Share it with others!
      </p>
      <div className="mb-16 flex justify-center gap-5 2xl:mb-28">
        <ShareButton socialMedia="x" />
        <ShareButton socialMedia="facebook" />
        <ShareButton socialMedia="linkedin" />
      </div>
      {articleTags && articleTags.length > 0 && (
        <Tags
          className="mb-10"
          headlineClassName="text-content-primary-on-light uppercase"
          title="explore more on"
          tags={articleTags}
        />
      )}
      {interestingArticles && interestingArticles.length > 0 && (
        <ArticlesList
          title="Other articles you might find interesting"
          headlineClassName="text-content-primary-on-light uppercase"
          articles={interestingArticles}
        />
      )}
    </div>
  </ContainerFlexible>
);
