import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { ChessCardBase } from "../ChessSection.types";
import styles from "./Texts.module.css";

type TextsProps = ChessCardBase;

export const Texts: FC<TextsProps> = ({
  title,
  rawHtmlDescription,
  direction,
}) => (
  <div
    className={cn(
      "lg:col-span-5 lg:row-start-1 2xl:col-span-4",
      direction === "LR" && "lg:col-start-1 2xl:col-start-2",
      direction === "RL" && "lg:col-start-8 2xl:col-start-8"
    )}
  >
    <Typography
      tag="h3"
      variant="h3"
      className="mb-3.5 text-primary sm:mb-6 2xl:mb-8"
    >
      {title}
    </Typography>
    <span
      className={cn(
        "t-body-b2 links-container text-primary/75",
        styles.bodyText
      )}
      dangerouslySetInnerHTML={{ __html: rawHtmlDescription }}
    />
  </div>
);
