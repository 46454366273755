import { useQuery } from "@tanstack/react-query";
import { meiliClient as client } from "@utils/search/meilisearch";

export type BlogHit = {
  id: number;
  title: string;
  slug: string;
  categorySlug?: string;
};

export const useBlogs = (searchValue: string, limit = 5) =>
  useQuery({
    queryKey: ["blogs", searchValue],
    queryFn: async ({ signal }) => {
      if (!searchValue) return [];
      const res = await client
        .index("blog")
        .search<BlogHit>(searchValue, { limit }, { signal });
      return res.hits;
    },
    retry: false,
    staleTime: Infinity,
    placeholderData: (previousData) => previousData,
  });
