import { AuthorTile } from "@atoms/authorTile/AuthorTile";
import Breadcrumbs from "@atoms/breadcrumbs/Breadcrumbs";
import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { HeaderBlogSectionProps } from "./HeaderBlogSection.types";

export const HeaderBlogSection: FC<HeaderBlogSectionProps> = ({
  title,
  authors,
  minutesToRead,
  backgroundUrl,
}) => {
  const isLight = !backgroundUrl;
  const colorScheme = isLight ? "Light" : "Dark";

  const gradient = (url?: string) =>
    url
      ? `linear-gradient(0deg, rgba(31, 38, 33, 0.80) 0%, rgba(31, 38, 33, 0.80) 100%), url(${url})`
      : undefined;

  return (
    <section
      style={
        backgroundUrl
          ? {
              background: gradient(backgroundUrl),
              backgroundPosition: "center",
              backgroundSize: "cover",
            }
          : {}
      }
      className={cn(
        "mb-12 px-0 pb-8 pt-40",
        !backgroundUrl && "bg-section",
        backgroundUrl ? "text-blue-light" : "text-grey-scale-off-black",
        "sm:pt-44",
        "2xl:mb-16 2xl:pt-48"
      )}
      data-theme={isLight ? "white" : "darkGreen"}
    >
      <div className="container-flexible 2xl:max-w-[1400px]">
        <Typography tag="h1" variant="h2" className="mb-5">
          {title}
        </Typography>
        {authors && authors.length > 0 && (
          <div className="mb-16 flex flex-wrap gap-4 2xl:mb-28">
            {authors.map(
              (author, index) =>
                author.name && (
                  <AuthorTile
                    key={`${author.name}-${index}`}
                    author={author}
                    background={colorScheme}
                  />
                )
            )}
          </div>
        )}
        <div className="pb-7 md:flex md:items-center md:justify-between md:gap-2.5">
          <Breadcrumbs theme={colorScheme} className="max-w-full" />
          {minutesToRead && (
            <p
              className={cn(
                "t-body-caption mb-3.5 md:mb-0 md:min-w-28",
                colorScheme === "Light"
                  ? "text-grey-scale-mid"
                  : "text-blue-light"
              )}
            >
              {minutesToRead} minutes read
            </p>
          )}
        </div>
      </div>
    </section>
  );
};
