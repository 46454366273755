import { ContentSectionsQuoteComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { QuoteBlock } from "./QuoteBlock";
import { QuoteBlockProps } from "./QuoteBlock.types";

const map = (data: ContentSectionsQuoteComponent): QuoteBlockProps => ({
  quote: data.quote ?? "",
  author: data.author ?? "",
  authorDescription: data.authorDescription ?? "",
});

export const renderQuoteBlock = ({ contentProps }: RendererArg) => (
  <QuoteBlock {...map(contentProps as ContentSectionsQuoteComponent)} />
);
