import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsTimelineComponent, OpenSource } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { TimelineSectionProps } from "@sections/timeline/TimelineSection.types";
import { RendererArg } from "@/types/renderer";
import { TimelineSection } from "./TimelineSection";

type OpenSourceAttr = {
  attributes?: OpenSource;
};

const map = ({
  block_settings,
  title,
  description,
  years,
}: ServiceSectionsTimelineComponent): TimelineSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title,
  description,
  groups:
    years?.map((container) => ({
      year: Number(container.year),
      cards:
        container.open_sources?.data?.map(
          ({ attributes: it }: OpenSourceAttr) => ({
            title: it?.title ?? "",
            bodyText: it?.description ?? "",
            stats: {
              forks: it?.forks ?? 0,
              stars: it?.stars ?? 0,
            },
            icon: {
              src: it?.image?.data as ApiImage,
              bgColor: it?.background ?? "beige",
            },
            url: it?.url ?? undefined,
          })
        ) ?? [],
    })) ?? [],
});

export const renderTimelineSection = ({ contentProps }: RendererArg) => (
  <TimelineSection {...map(contentProps as ServiceSectionsTimelineComponent)} />
);
