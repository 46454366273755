import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { useToast } from "@atoms/toast/use-toast";
import Typography from "@atoms/typography/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { tw } from "@utils/tailwind";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  contactFormSchema,
  postForm,
  toArrayItem,
} from "./ContactSection.helpers";
import { ContactFormSchema, ContactSectionProps } from "./ContactSection.types";
import { FormBottomPart } from "./bottom-part/FormBottomPart";
import {
  CameFromField,
  EmailField,
  FirstNameField,
  LastNameField,
  ProjectDescriptionField,
  TopicsField,
} from "./fields/";

const imageStyles = tw`left-1/2
  h-[520px]
  w-1/2
  object-left-top
  md:left-1/3
  md:h-[674px]
  md:w-2/3
  xl:left-1/2
  xl:h-[821px]
  xl:w-1/2
  3xl:left-2/3
  3xl:w-1/3`;

export const ContactSection: FC<ContactSectionProps> = ({
  title,
  topics,
  origins,
  bgImage,
}) => {
  const methods = useForm<ContactFormSchema>({
    defaultValues: {
      topics: topics.map(toArrayItem),
      cameFrom: origins.map(toArrayItem),
    },
    resolver: zodResolver(contactFormSchema),
  });

  const {
    handleSubmit,
    setFocus,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const { push } = useRouter();
  const { toast } = useToast();

  const submit: SubmitHandler<ContactFormSchema> = async (values) => {
    if (isSubmitSuccessful) return;
    const res = await postForm(values);

    if (!res.success) {
      toast({
        title: "Uh oh! Something went wrong",
        description: res.errorMessage,
        variant: "destructive",
      });
      setError("root.serverError", {
        type: "server",
        message: res.errorMessage,
      });
    }
  };

  const handleErrors = (errors: object) => {
    if ("topics" in errors) {
      setFocus("topics.0.isIncluded");
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) void push("/thank-you/?source=contact");
  }, [isSubmitSuccessful, push]);

  return (
    <section
      data-strapi="contact-form"
      className="relative overflow-hidden text-content-primary-on-light"
    >
      <Image backgroundImage imageData={bgImage} className={imageStyles} />
      <div className="container-flexible mb-44 pt-10 md:mb-[16.75rem]">
        <Link href="/">
          <Icon
            name="VirtusLogoWithText"
            className="h-14 w-36 fill-green-accent-two"
          />
        </Link>
      </div>
      <div className="container-flexible grid text-balance text-content-primary-on-light lg:grid-cols-12">
        <Typography
          tag="h1"
          variant="h1"
          weight="medium"
          className="lg:col-span-10 xl:col-span-8 2xl:col-span-7"
        >
          {title}
        </Typography>
      </div>
      <ContainerSpacing asDiv withBackground className="container-flexible">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit, handleErrors)} noValidate>
            {/* Note: relative class name makes this field render
            on top of the background image */}
            <TopicsField className="relative" />
            <div className="mb-12 grid gap-x-10 gap-y-[4.5rem] pt-7 md:mb-10 lg:mb-12 lg:w-11/12 lg:grid-cols-2 xl:mb-14 xl:w-2/3 2xl:mb-16 2xl:w-1/2">
              <ProjectDescriptionField className="lg:col-span-2" />
              <FirstNameField />
              <LastNameField />
              <EmailField />
            </div>
            <CameFromField />
            <FormBottomPart />
          </form>
        </FormProvider>
      </ContainerSpacing>
    </section>
  );
};
