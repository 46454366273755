import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { cn } from "@utils/tailwind";
import { isEmpty } from "lodash";
import { FC } from "react";
import styles from "./ColumnText.module.css";

type ColumnItem = {
  item?: string;
};

export type ColumnTextProps = {
  title?: string;
  column?: ColumnItem[];
};

export const ColumnText: FC<ColumnTextProps> = ({ title, column }) => {
  const items = column?.map(({ item }: ColumnItem) =>
    item?.replaceAll("<p>", "").replaceAll("</p>", "")
  );

  const orderedListStyles = cn(
    styles.counterParent,
    `grid list-outside list-none
    gap-7 ps-0 text-grey-scale-mid
    md:gap-10
    lg:grid-cols-[auto_auto]
    2xl:gap-x-16 2xl:gap-y-20`
  );

  const listItemStyles = cn(
    styles.counterChild,
    "t-body-b2 flex before:t-breadcrumbs",
    `before:t-breadcrumbs
    before:flex before:size-6
    before:items-center before:justify-center
    before:rounded-full
    before:border before:border-solid
    before:border-grey-scale-text
    before:p-2.5
    before:text-center before:uppercase`
  );

  return (
    <ContainerSpacing
      withBackground
      data-strapi="column-text"
      className="bg-blue-light"
    >
      <div className="container-flexible">
        <h2 className="t-subpage-h2 mb-6 font-sans md:mb-12 xl:mb-14 2xl:mb-20">
          {title}
        </h2>
        <ol className={orderedListStyles}>
          {!isEmpty(items) &&
            items?.map(
              (item, index) =>
                item && (
                  <li key={`column-text-${index}`} className={listItemStyles}>
                    <span className="ml-2.5 md:ml-4">{item}</span>
                  </li>
                )
            )}
        </ol>
      </div>
    </ContainerSpacing>
  );
};
