import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { FC } from "react";
import { HorizontalAccordionSectionProps } from "./HorizontalAccordionSection.types";
import { HorizontalAccordionCard } from "./card/HorizontalAccordionCard";

export const HorizontalAccordionSection: FC<
  HorizontalAccordionSectionProps
> = ({ title, subtitle, cards, anchorId }) => (
  <ContainerSpacing
    withBackground
    id={anchorId}
    data-strapi="horizontal-accordion"
    className="text-content-primary-on-light"
  >
    <SectionHeader
      title={title}
      subtitle={subtitle}
      className="container-flexible mb-10 md:mb-11 lg:mb-12 xl:mb-16 2xl:mb-16"
    />
    {cards.map((it) => (
      <HorizontalAccordionCard key={it.title} {...it} />
    ))}
  </ContainerSpacing>
);
