/* eslint-disable react/no-danger-with-children */
import { cn } from "@utils/tailwind";
import { ComponentProps, FC, PropsWithChildren } from "react";

export type HeaderTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export type AvailableTags = HeaderTypes | "div";

export type HeadlineProps = PropsWithChildren<{
  tag: AvailableTags;
  styledAs?: HeaderTypes;
  className?: string;
  onClick?: () => void;
  dangerouslySetInnerHTML?: ComponentProps<"h1">["dangerouslySetInnerHTML"];
  noDefaultStyle?: boolean;
}>;

/** @deprecated Just use HTML tags with tailwind typography */
const Headline: FC<HeadlineProps> = ({
  tag,
  styledAs,
  className,
  children,
  onClick,
  dangerouslySetInnerHTML,
  noDefaultStyle = false,
}) => {
  const props = {
    onClick,
    dangerouslySetInnerHTML,
    children,
    className: cn(
      !noDefaultStyle && {
        "t-headline-h1": styledAs === "h1" || (!styledAs && tag === "h1"),
        "t-headline-h2": styledAs === "h2" || (!styledAs && tag === "h2"),
        "t-headline-h3": styledAs === "h3" || (!styledAs && tag === "h3"),
        "t-headline-h4": styledAs === "h4" || (!styledAs && tag === "h4"),
        "t-headline-h5": styledAs === "h5" || (!styledAs && tag === "h5"),
        "t-headline-h6": styledAs === "h6" || (!styledAs && tag === "h6"),
      },
      className
    ),
  };

  switch (tag) {
    case "h1":
      return <h1 {...props} />;
    case "h2":
      return <h2 {...props} />;
    case "h3":
      return <h3 {...props} />;
    case "h4":
      return <h4 {...props} />;
    case "h5":
      return <h5 {...props} />;
    case "h6":
      return <h6 {...props} />;
    case "div":
      return <div {...props} />;
    default:
      return null;
  }
};

export default Headline;
