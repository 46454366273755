import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import styles from "./DefaultFullPageText.module.css";

export type DefaultFullPageTextProps = {
  rawHtmlContent: string;
};

export const DefaultFullPageText: FC<DefaultFullPageTextProps> = ({
  rawHtmlContent,
}) => {
  const defaultFullPageTextStyles = cn(
    "links-container m-auto w-full max-w-[850px] px-7 text-sm text-primary md:text-base xl:text-lg",
    "sm:px-12",
    "md:px-14",
    "[&_h1]:my-[0.67em] [&_h1]:text-3xl md:[&_h1]:text-4xl xl:[&_h1]:text-5xl",
    "[&_h2]:my-[0.83em] [&_h2]:text-2xl md:[&_h2]:text-3xl xl:[&_h2]:text-4xl",
    "[&_h3]:my-[1em] [&_h3]:text-xl md:[&_h3]:text-2xl xl:[&_h3]:text-3xl",
    "[&_h4]:my-[1.33em] [&_h4]:text-lg md:[&_h4]:text-xl xl:[&_h4]:text-2xl",
    "[&_h5]:my-[1.67em] [&_h5]:text-base md:[&_h5]:text-lg xl:[&_h5]:text-xl",
    "[&_h6]:my-[2.33em] [&_h6]:text-xs md:[&_h6]:text-base xl:[&_h6]:text-lg",
    "[&_p]:my-4",
    "[&_ol]:my-4 [&_ol]:mr-0 [&_ol]:list-none",
    "[&_ul]:my-4 [&_ul]:list-disc",
    "[&_li]:relative [&_li]:mb-4 [&_li]:ml-7 [&_li]:marker:text-green-accent-two [&_li]:last:mb-0 [&_li]:sm:ml-10 [&_li]:xl:pl-2",
    "[&_strong]:font-bold",
    "[&_figcaption]:t-body-caption [&_figcaption]:pt-4 [&_figcaption]:text-grey-scale-mid",
    "[&_img]:rounded-lg",
    "[&_blockquote]:my-7 [&_blockquote]:w-full [&_blockquote]:rounded-xl [&_blockquote]:bg-organic-lighter [&_blockquote]:px-12 [&_blockquote]:py-8 [&_blockquote]:sm:p-12 [&_blockquote]:md:my-10 [&_blockquote]:xl:my-12",
    "[&>*:first-child]:mt-0 [&>*:last-child]:mb-0",
    "[&_ol>li:before]:t-breadcrumbs [&_ol>li:before]:absolute [&_ol>li:before]:left-[-24px] [&_ol>li:before]:top-[2px] [&_ol>li:before]:inline-flex [&_ol>li:before]:size-[1.5em] [&_ol>li:before]:items-center [&_ol>li:before]:justify-center [&_ol>li:before]:rounded-full [&_ol>li:before]:border [&_ol>li:before]:border-grey-scale-text",
    "[&_ol>li:before]:md:top-1",
    "[&_ol>li:before]:xl:-left-5 [&_ol>li:before]:xl:top-1.5",
    styles.defaultFullPageTextModule
  );
  return (
    <ContainerSpacing asDiv withBackground data-strapi="default-full-page-text">
      <div
        className={defaultFullPageTextStyles}
        dangerouslySetInnerHTML={{ __html: rawHtmlContent }}
      />
    </ContainerSpacing>
  );
};
