import Typography from "@atoms/typography/Typography";
import { cn, tw } from "@utils/tailwind";
import { ComponentPropsWithoutRef, forwardRef } from "react";

export type PillCheckboxProps = ComponentPropsWithoutRef<"input"> & {
  id: string;
  label?: string;
  labelClassName?: string;
};

const labelStyles = tw`cursor-pointer
  rounded-[1.125rem]
  border
  border-content-primary-on-light
  bg-content-primary-on-dark
  px-6
  py-3.5
  text-content-primary-on-light
  transition-[color,background-color]
  duration-300
  ease-out
  hover:border-green-accent-two
  active:border-content-primary-on-light
  active:bg-content-primary-on-light
  active:text-content-primary-on-dark
  peer-checked:border-content-primary-on-light
  peer-checked:bg-content-primary-on-light
  peer-checked:text-content-primary-on-dark
  peer-focus:border-green-accent-two`;

export const PillCheckbox = forwardRef<HTMLInputElement, PillCheckboxProps>(
  ({ label, labelClassName, className, ...inputProps }, ref) => (
    <Typography tag="div" variant="sm" weight="medium" className="flex">
      <input
        type="checkbox"
        ref={ref}
        className={cn("peer absolute appearance-none outline-none", className)}
        {...inputProps}
      />
      <label
        htmlFor={inputProps.id}
        className={cn(labelStyles, labelClassName)}
      >
        {label}
      </label>
    </Typography>
  )
);

PillCheckbox.displayName = "PillCheckbox";
