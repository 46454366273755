import { SettingsSectionsTestimonialsComponent } from "@api/contract";
import { TestimonialsSectionProps } from "@sections/testimonials/TestimonialsSection.types";
import { SectionComponent } from "@/types/component";
import { RendererArg } from "@/types/renderer";
import { TestimonialsSection } from "./TestimonialsSection";

type StrapiTestimonialSectionAdapter = {
  globalTestimonials?: SettingsSectionsTestimonialsComponent;
};

export const testimonialSectionAdapter = ({
  globalTestimonials,
}: StrapiTestimonialSectionAdapter): TestimonialsSectionProps &
  SectionComponent => ({
  mainTitle: globalTestimonials?.main_title ?? "",
  testimonials:
    globalTestimonials?.testimonials?.data &&
    globalTestimonials?.testimonials.data.map((testimonial) => ({
      text: testimonial?.attributes?.content ?? "",
      authorName: testimonial?.attributes?.author ?? "",
      authorRole: testimonial?.attributes?.author_position ?? "",
    })),
});

export const renderTestimonialSection = ({ contentProps }: RendererArg) => {
  const props = testimonialSectionAdapter({
    globalTestimonials: contentProps as SettingsSectionsTestimonialsComponent,
  });

  return <TestimonialsSection {...props} />;
};
