import { blockSettingsAdapter } from "@adapters/block-settings";
import {
  ServiceSectionsSuccessStoriesComponent,
  SmallComponentsSuccessStoryCardComponent,
} from "@api/contract";
import {
  SuccessStoriesCardProps,
  SuccessStoriesSectionProps,
} from "@sections/success-stories/SuccessStoriesSection.types";
import { RendererArg } from "@/types/renderer";
import { SuccessStoriesSection } from "./SuccessStoriesSection";

const mapCard = (
  cards?: SmallComponentsSuccessStoryCardComponent[]
): SuccessStoriesCardProps[] =>
  cards?.map(({ id, title, label, url, image, list_items }) => ({
    id: id ?? 0,
    title: title ?? "",
    label: label ?? "",
    url,
    image: image?.image_file?.data,
    statements: list_items?.map((x) => x.description ?? "") ?? [],
  })) ?? [];

const map = ({
  block_settings,
  cards,
  title,
}: ServiceSectionsSuccessStoriesComponent): SuccessStoriesSectionProps => ({
  ...blockSettingsAdapter({ blockSettings: block_settings }),
  title: title ?? "",
  cards: mapCard(cards),
});

export const renderSuccessStoriesSection = ({ contentProps }: RendererArg) => (
  <SuccessStoriesSection
    {...map(contentProps as ServiceSectionsSuccessStoriesComponent)}
  />
);
