import { RefObject, useEffect, useState } from "react";

export const useImageLightbox = (containerRef: RefObject<HTMLDivElement>) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  useEffect(() => {
    const selectBiggestSrc = (picture: HTMLPictureElement) => {
      const source = picture.querySelector("source");
      const img = picture.querySelector("img");
      if (source) {
        const srcset = source.getAttribute("srcset");
        if (srcset) {
          const src = srcset.split(" ")[0];
          handleImageClick(src);
        }
      } else if (img) {
        const src = img.getAttribute("src");
        if (src) {
          handleImageClick(src);
        }
      }
    };
    if (!containerRef || !containerRef.current) return;

    const pictureElements = containerRef.current.querySelectorAll(
      "picture[data-lightbox]"
    ) as NodeListOf<HTMLPictureElement>;
    pictureElements.forEach((picture) => {
      picture.addEventListener("click", () => selectBiggestSrc(picture));
    });

    return () => {
      pictureElements.forEach((picture) => {
        picture.removeEventListener("click", () => selectBiggestSrc(picture));
      });
    };
  }, [containerRef]);

  return { selectedImage, isLightboxOpen, closeLightbox };
};
