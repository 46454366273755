import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import {
  StatementsCardProps,
  SuccessStoriesCardProps,
} from "@sections/success-stories/SuccessStoriesSection.types";
import { DesktopOverlay } from "@sections/success-stories/overlays/DesktopOverlay";
import { MobileOverlay } from "@sections/success-stories/overlays/MobileOverlay";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { FC, useState } from "react";
import { DesktopInvisibleStatements } from "../invisible-statements/DesktopInvisibleStatements";
import { MobileInvisibleStatements } from "../invisible-statements/MobileInvisibleStatements";

const rootStyles = tw`grid
  max-xl:[grid-template-areas:"mobile-overlay"]
  xl:row-span-2
  xl:grid-rows-subgrid`;

const baseCardStyles = tw`group/card
  relative
  grid
  min-h-[21.875rem]
  w-full
  cursor-pointer
  content-between
  overflow-hidden
  rounded-[1.25rem]
  bg-content-primary-on-dark
  max-xl:[grid-area:mobile-overlay]
  md:min-h-[20.75rem]
  md:cursor-default
  md:grid-cols-2
  md:content-stretch
  lg:min-h-[18.75rem]
  xl:row-span-2
  xl:mb-8
  xl:grid-cols-1
  xl:grid-rows-subgrid
  xl:content-between`;

const imageWrapperStyles = tw`relative
  h-40
  w-full
  overflow-hidden
  md:h-full
  xl:min-h-[19.375rem]
  2xl:min-h-[16.875rem]`;

export const SuccessStoriesCard: FC<SuccessStoriesCardProps> = ({
  image,
  label,
  title,
  statements,
  url,
}) => {
  const [showMobileOverlay, setShowMobileOverlay] = useState(false);
  const statementsProps: StatementsCardProps = { statements, url };

  const groupHoverTitle =
    "link-animated-base group-hover/link:link-animated-base-hover";
  const Comp = url ? Link : "div";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const linkProps = url ? { href: url } : ({} as any);

  return (
    <div className={rootStyles}>
      <div
        className={baseCardStyles}
        onClick={() => setShowMobileOverlay((prev) => !prev)}
      >
        <MobileOverlay {...statementsProps} show={showMobileOverlay} />
        <Comp
          {...linkProps}
          className={cn(
            "pointer-events-none min-h-48 p-6 text-content-primary-on-light md:pointer-events-auto md:p-8 2xl:p-10",
            url ? "group/link" : ""
          )}
        >
          <Typography
            tag="p"
            variant="sm"
            weight="medium"
            className="mb-2 py-1 uppercase opacity-50 empty:hidden md:mb-7 xl:mb-3 2xl:mb-6"
          >
            {label}
          </Typography>
          <Typography
            tag="h3"
            variant="h4"
            className="transition-transform duration-300"
          >
            <span className={groupHoverTitle}>{title}</span>
          </Typography>
        </Comp>
        <div className={imageWrapperStyles}>
          <DesktopOverlay {...statementsProps} />
          <DesktopInvisibleStatements {...statementsProps} />
          <Image
            backgroundImage
            maxKnownWidth={500}
            imageData={image}
            className={cn(
              "border-content-primary-on-dark transition-transform duration-500 md:max-xl:border-l",
              showMobileOverlay && "max-md:scale-110"
            )}
          />
          {!image && (
            <div className="absolute left-0 top-0 size-full bg-blue-light/50" />
          )}
        </div>
      </div>
      <MobileInvisibleStatements {...statementsProps} />
    </div>
  );
};
