import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsBurgerComponent } from "@api/contract";
import layer0 from "public/images/burger/layer_0.png";
import layer1 from "public/images/burger/layer_1.png";
import layer2 from "public/images/burger/layer_2.png";
import { RendererArg } from "@/types/renderer";
import { BurgerSection } from "./BurgerSection";
import { BurgerSectionTypes } from "./BurgerSection.constants";

const map = ({
  title,
  block_settings,
  step1_title,
  step_list,
  step2_title,
  step2_list,
  step3_list,
  step3_title,
  step1_image,
  step2_image,
  step3_image,
}: ServiceSectionsBurgerComponent): BurgerSectionTypes => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "beige",
  }),
  title: title ?? "",
  layers: [
    {
      title: step1_title ?? "",
      description: step_list
        ? step_list.map((element) => element.item ?? "")
        : [],
      image: step1_image?.data ?? layer0.src,
    },
    {
      title: step2_title ?? "",
      description: step2_list
        ? step2_list.map((element) => element.item ?? "")
        : [],
      image: step2_image?.data ?? layer1.src,
    },
    {
      title: step3_title ?? " ",
      description: step3_list
        ? step3_list.map((element) => element.item ?? "")
        : [],
      image: step3_image?.data ?? layer2.src,
    },
  ],
});

export const renderBurgerSection = ({ contentProps }: RendererArg) => (
  <BurgerSection {...map(contentProps as ServiceSectionsBurgerComponent)} />
);
