import Breadcrumbs from "@atoms/breadcrumbs/Breadcrumbs";
import { Image } from "@atoms/image/Image";
import { RichTextFormatter } from "@content-sections/RichTextFormatter/RichTextFormatter";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { StringWithLinks } from "@utils/string-with-links/StringWithLinks";
import { FC } from "react";
import { CaseStudyHeaderProps } from "./CaseStudyHeader.types";
import { DownloadStory } from "./download-story/DownloadStory";

export const CaseStudyHeader: FC<CaseStudyHeaderProps> = ({
  title,
  timeToRead,
  image,
  alt,
  bulletPoints,
  firstParagraph,
  download,
}) => (
  <ContainerSpacing withBackground className="bg-content-primary-on-dark">
    <div className="container-flexible flex flex-col gap-7 md:gap-10 lg:gap-16 xl:gap-20 2xl:gap-24">
      {title && (
        <div className="flex items-center justify-center py-5 text-center">
          <h1 className="t-subpage-h1 text-grey-scale-off-black sm:w-4/5 2xl:w-3/5">
            {title}
          </h1>
        </div>
      )}
      <div>
        <div className="pb-7 md:flex md:items-center md:justify-between md:gap-2.5">
          <Breadcrumbs theme="Light" className="max-w-full" />
          {timeToRead && (
            <p className="t-body-caption mb-3.5 text-grey-scale-mid md:mb-0 md:min-w-28">
              {timeToRead} minutes read
            </p>
          )}
        </div>
        <div className="py-2">
          <Image
            alt={alt ?? "case-study"}
            imageData={image}
            className="aspect-[2/1] h-auto max-h-[36.25rem] w-full rounded-lg object-cover md:aspect-[2.52] lg:aspect-[2.46] 2xl:aspect-[2.42]"
          />
        </div>
        <div className="grid grid-cols-3 gap-x-12 py-2.5">
          {bulletPoints
            .filter((it) => it.content)
            .map((point, index) => (
              <div
                key={`${point.header}-${index}`}
                className="t-subpage-body flex flex-col gap-2.5 text-grey-scale-off-black"
              >
                <span className="first:text-grey-faded">{point.header}</span>
                <StringWithLinks linksIdPrefix="industry">
                  {point.content}
                </StringWithLinks>
              </div>
            ))}
        </div>
      </div>
      {firstParagraph && (
        <div className="grid gap-20 xl:grid-cols-2 xl:gap-5 2xl:gap-8">
          <RichTextFormatter content={firstParagraph} />
          {download && <DownloadStory {...download} />}
        </div>
      )}
    </div>
  </ContainerSpacing>
);
