import { Button } from "@atoms/button/Button";
import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { cn, tw } from "@utils/tailwind";
import { useInView } from "framer-motion";
import { FC, useRef, useState } from "react";
import { BigNumbersSectionProps } from "./BigNumbers.types";
import Topography from "./assets/Topography";
import TopographyMobile from "./assets/TopographyMobile";
import { Card } from "./cards/Card";

const gradientStyles = tw`opacity-60
  [background:radial-gradient(circle,rgba(103,225,135,1)_0%,rgba(0,0,0,1)_75%,rgba(0,0,0,1)_100%)]`;

export const BigNumbers: FC<BigNumbersSectionProps> = ({
  title,
  cards,
  claim,
  cta,
  themeName,
  anchorId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ xPercent: -100, yPercent: -100 });
  const isInView = useInView(containerRef);

  const handleMouseMove = (e: { clientX: number; clientY: number }) => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const xPercent = (x / rect.width) * 100;
      const yPercent = (y / rect.height) * 100;

      setPosition({ xPercent, yPercent });
    }
  };

  const handleMouseOut = () => {
    setPosition({ xPercent: -100, yPercent: -100 });
  };

  const gradientContainerStyles = cn(
    "absolute top-0 hidden size-full overflow-hidden rounded-t-4xl bg-black lg:col-span-3 lg:block",
    // isInView fixes the performance on Safari https://app.clickup.com/t/8695f9h37
    isInView && "[clipPath:url(#theSVGPathTopography)]"
  );

  return (
    <ContainerSpacing
      withBackground
      isTransparentBackground
      id={anchorId}
      data-strapi="your-needs"
      data-theme={themeName}
      className="bg-content-primary-on-light text-content-primary-on-dark"
    >
      <div className="container-flexible">
        {title && (
          <Typography
            tag="h2"
            variant="h2"
            className="mb-9 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16"
          >
            {title}
          </Typography>
        )}

        {cards && (
          <div className="relative">
            <Topography />

            <div
              className="relative grid overflow-hidden rounded-t-4xl border border-grey-scale-text lg:grid-cols-3"
              ref={containerRef}
              onMouseMove={handleMouseMove}
              onMouseOut={handleMouseOut}
            >
              <div className={gradientContainerStyles}>
                {position && (
                  <div
                    className={cn(
                      gradientStyles,
                      "pointer-events-none absolute block size-64 -translate-x-1/2 -translate-y-1/2 rounded-full"
                    )}
                    style={{
                      top: `${position.yPercent}%`,
                      left: `${position.xPercent}%`,
                    }}
                  />
                )}
              </div>

              <TopographyMobile className="absolute top-0 block opacity-50 md:scale-100 lg:hidden" />

              {cards.map((card, idx) => (
                <Card key={idx} {...card} id={idx} />
              ))}
            </div>

            {(claim || cta) && (
              <div className="flex flex-wrap items-center justify-between rounded-b-4xl border border-t-0 border-grey-scale-text px-6 py-8 md:p-9 lg:p-10 2xl:flex-nowrap ">
                {claim && (
                  <Typography
                    tag="div"
                    variant="lg"
                    className="mb-10 shrink text-center font-termina lg:text-left 2xl:mb-0 2xl:pr-10"
                  >
                    {claim}
                  </Typography>
                )}
                {cta?.href && cta.buttonLabel && (
                  <div className="block w-full text-center lg:text-left 2xl:inline-block 2xl:w-fit 2xl:text-right">
                    <Button
                      id="numbers-cta"
                      href={cta.href}
                      asLink
                      icon="ArrowRight"
                      intent="secondaryV2"
                      background="dark"
                      size="small"
                      target={cta.target}
                      mobileFullWidth
                      buttonLabel={cta.buttonLabel}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </ContainerSpacing>
  );
};
