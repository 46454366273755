import { ContentSectionsSchemaScriptComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { Schema } from "./Schema";
import { SchemaProps } from "./Schema.types";

const map = ({
  schema,
}: ContentSectionsSchemaScriptComponent): SchemaProps => ({
  schema,
});

export const renderSchema = ({ contentProps }: RendererArg) => (
  <Schema {...map(contentProps as ContentSectionsSchemaScriptComponent)} />
);
