import { LightBox } from "@atoms/light-box/LightBox";
import { CaseStudyHeader } from "@sections/case-study-header/CaseStudyHeader";
import { extractNameAndRender } from "@utils/content-renderer/extract-and-render/extractAndRender";
import { useImageLightbox } from "@utils/use-image-lightbox/useImageLightbox";
import { FC, useRef } from "react";
import { caseStudyContentRenderer } from "./CaseStudy.render";
import { CaseStudyPageProps } from "./CaseStudy.types";

export const CaseStudy: FC<CaseStudyPageProps> = ({ header, content }) => {
  const caseStudyRef = useRef<HTMLDivElement>(null);
  const { selectedImage, isLightboxOpen, closeLightbox } =
    useImageLightbox(caseStudyRef);
  return (
    <>
      <CaseStudyHeader {...header} />
      <main ref={caseStudyRef}>
        <LightBox
          url={selectedImage}
          onClick={closeLightbox}
          active={isLightboxOpen}
        />
        {content.map((page_section, idx) =>
          extractNameAndRender(page_section, caseStudyContentRenderer, idx)
        )}
      </main>
    </>
  );
};
