import { ContentSectionsCodeComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { CodeBlock } from "./CodeBlock";
import { CodeBlockProps } from "./CodeBlock.types";

const map = (data: ContentSectionsCodeComponent): CodeBlockProps => ({
  code: data.code || "",
  language: data.language || "",
});

export const renderCodeBlockSection = ({ contentProps }: RendererArg) => (
  <CodeBlock {...map(contentProps as ContentSectionsCodeComponent)} />
);
