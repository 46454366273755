import { Image } from "@atoms/image/Image";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { CommuningSectionProps } from "@sections/communing/CommuningSection.types";
import { CommuningCard } from "@sections/communing/cards/CommuningCard";
import { tw } from "@utils/tailwind";
import { FC } from "react";

export const CommuningSection: FC<CommuningSectionProps> = ({
  title,
  description,
  cards,
  anchorId,
  isTransparentBackground,
  themeName,
  decorativeImages,
}) => {
  const contentStyles = tw`container-flexible grid gap-12
    md:grid-cols-3 md:grid-rows-[auto_1fr]
    md:gap-x-5 md:gap-y-0
    xl:grid-cols-[20rem_repeat(3,1fr)]
    xl:grid-rows-[repeat(3,auto)]
    xl:gap-x-10 xl:gap-y-0
    2xl:gap-x-14 2xl:gap-y-0`;

  return (
    <ContainerSpacing
      withBackground
      isTransparentBackground={isTransparentBackground}
      id={anchorId}
      data-strapi="communing"
      data-theme={themeName}
    >
      {(title || description) && (
        <SectionHeader
          withMarginBottom
          title={title}
          subtitle={description}
          className="container-flexible"
          subtitleClassName="opacity-75"
        />
      )}

      <div className={contentStyles}>
        {decorativeImages && (
          <div className="relative h-36 w-full md:col-span-3 md:row-span-3 md:h-52 xl:col-span-1 xl:h-full">
            <Image
              backgroundImage
              imageData={decorativeImages}
              className="object-center xl:overflow-visible xl:object-right"
            />
          </div>
        )}
        {cards &&
          cards.map((card) => <CommuningCard key={card.id} {...card} />)}
      </div>
    </ContainerSpacing>
  );
};
