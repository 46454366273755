import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { HorizontalAccordionCardProps } from "../HorizontalAccordionSection.types";
import styles from "./HorizontalAccordionCard.module.css";

const gridStyles = tw`grid
  grid-cols-[repeat(2,auto)]
  items-center
  justify-between
  gap-x-8
  gap-y-4
  md:gap-y-2
  lg:grid-cols-[auto_245px_auto]
  lg:justify-end
  lg:gap-x-14
  xl:grid-cols-[auto_350px_auto]
  xl:gap-x-[3.75rem]
  2xl:grid-cols-[auto_445px_auto]
  2xl:gap-x-24`;

const bigTitleStyles = cn(
  styles.stroke,
  `col-span-2
  cursor-default
  font-termina
  font-semibold
  uppercase
  tracking-[-0.01em]
  text-transparent
  transition-colors
  duration-500
  ease-in-out
  group-hover/card:text-current
  sm:col-span-1
  md:text-nowrap
  lg:duration-700`
);

const iconWrapperStyles = tw`row-start-2
  grid
  size-[45px]
  place-items-center
  rounded-full
  border
  border-current
  transition-[background-color,margin-left]
  duration-500
  ease-out
  group-hover/card:bg-content-primary-on-light
  sm:row-span-2
  sm:row-start-auto
  lg:row-span-1
  lg:duration-700
  lg:group-hover/card:ml-[--animation-offset]`;

export const HorizontalAccordionCard: FC<HorizontalAccordionCardProps> = ({
  title,
  rawContent,
  href,
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const containerFlexibleRef = useRef<HTMLDivElement>(null);
  const [animationOffset, setAnimationOffset] = useState(0);

  useEffect(() => {
    const update = () => {
      const containerWidth = containerFlexibleRef.current?.clientWidth || 0;
      const gridWidth = gridRef.current?.clientWidth || 0;
      setAnimationOffset(Math.max(containerWidth - gridWidth, 0));
    };

    window.addEventListener("resize", update);
    update();
    // Trigger update after one second to ensure screen width is set correctly
    setTimeout(update, 1000);
    return () => {
      window.removeEventListener("resize", update);
    };
  }, []);

  return (
    <Link
      href={href || ""}
      className="group/card relative block border-t border-grey-scale-light-gray py-6 last:border-b md:py-4 xl:py-5 2xl:py-6"
      style={{ "--animation-offset": `${animationOffset}px` } as CSSProperties}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-green-accent-one opacity-0 transition-opacity duration-500 ease-in-out group-hover/card:opacity-100 lg:duration-700" />
      <div
        ref={containerFlexibleRef}
        className="container-flexible relative justify-end lg:flex"
      >
        <div ref={gridRef} className={gridStyles}>
          <Typography tag="p" variant="display-h1" className={bigTitleStyles}>
            {title}
          </Typography>
          <Typography
            wysiwygStyling
            tag="div"
            variant="base"
            dangerouslySetInnerHTML={{ __html: rawContent }}
            className="row-start-2 lg:row-start-auto"
          />
          <div className={cn(iconWrapperStyles, !href && "invisible")}>
            <Icon
              name="ArrowTopRight"
              className="size-[11px] transition-colors duration-500 ease-out group-hover/card:text-content-primary-on-dark lg:duration-700"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};
