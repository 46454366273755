import Breadcrumbs from "@atoms/breadcrumbs/Breadcrumbs";
import { Button } from "@atoms/button/Button";
import Typography from "@atoms/typography/Typography";
import { LogoLooper } from "@molecules/logo-looper/LogoLooper";
import { HeaderSubpageProps } from "@sections/header-subpage/HeaderSubpageSection.types";
import { cn } from "@utils/tailwind";
import linesInBackgroundImg from "public/images/lines-in-background.png";
import { FC } from "react";

export const HeaderSubpageSection: FC<HeaderSubpageProps> = ({
  header,
  subheader,
  cta,
  underHeader,
  themeName,
  anchorId,
  showBorder = true,
  showBreadcrumbs = true,
  disableCta,
  className,
}) => (
  <section
    id={anchorId}
    className={cn(
      "flex flex-col justify-end bg-section bg-cover bg-no-repeat pt-24 lg:pt-32",
      className
    )}
    data-theme={themeName}
    style={{ backgroundImage: `url(${linesInBackgroundImg.src})` }}
  >
    <div className="w-full pt-20 md:pt-32 lg:pt-48 xl:pt-56">
      <div className="container-flexible text-primary">
        <div className="mb-12 md:mb-14 lg:mb-16 lg:w-10/12 xl:mb-24 xl:w-8/12 2xl:w-7/12">
          {showBreadcrumbs && (
            <div className="mb-7 md:mb-8 2xl:mb-10">
              <Breadcrumbs />
            </div>
          )}
          <Typography
            tag="h1"
            variant="h1"
            weight="medium"
            className="mb-5 md:mb-6 xl:mb-8"
          >
            {header}
          </Typography>
          {subheader && (
            <Typography
              tag="div"
              wysiwygStyling
              dangerouslySetInnerHTML={{ __html: subheader }}
            />
          )}

          {!disableCta && cta && cta.href && cta.buttonLabel && (
            <div className="mt-12 md:mt-14 lg:mt-16 xl:mt-24">
              <Button
                id="head-cta"
                href={cta.href}
                asLink
                icon="ArrowRight"
                buttonLabel={cta.buttonLabel}
                intent="primaryV2"
                target="_self"
                mobileFullWidth
              />
            </div>
          )}
        </div>

        {(showBorder || underHeader?.title) && (
          <div className="flex w-full items-center">
            {underHeader?.title && (
              <div className="flex-none text-xs font-medium uppercase">
                {underHeader?.title}
              </div>
            )}
            {showBorder && (
              <div className={cn("grow", underHeader?.title && "pl-8")}>
                <div className="h-px -translate-y-px bg-content-primary-on-light darkGreen:bg-white" />
              </div>
            )}
          </div>
        )}
      </div>

      {underHeader?.logos && underHeader?.logos.length > 0 && (
        <div className="py-8 2xl:container-flexible">
          <LogoLooper
            direction={underHeader.direction}
            speed={underHeader.speed}
            logos={underHeader.logos}
          />
        </div>
      )}
    </div>
  </section>
);
