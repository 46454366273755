import { cn, tw } from "@utils/tailwind";
import { ComponentPropsWithoutRef, forwardRef } from "react";

export type TextFieldProps = ComponentPropsWithoutRef<"input"> & {
  id: string;
  label?: string;
  error?: string;
  withPaddings?: boolean;
};

const hiddenLabelStyles = tw`absolute -m-px size-px overflow-hidden`;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, error, withPaddings, className, ...inputProps }, ref) => (
    <div
      className={cn(
        "flex flex-col gap-4",
        withPaddings && "pt-5 md:pt-6 xl:pt-7",
        error && "text-error",
        className
      )}
    >
      <div className="relative font-sans text-base xl:text-lg">
        <input
          type="text"
          autoComplete="off"
          ref={ref}
          placeholder={inputProps.required ? `${label} *` : label}
          className={cn(
            "peer w-full rounded-none border-b  border-grey-mid bg-transparent pb-4 font-medium outline-none",
            error ? "border-error text-error" : "text-grey-scale-off-black"
          )}
          {...inputProps}
        />
        <label htmlFor={inputProps.id} className={hiddenLabelStyles}>
          {label}
        </label>
      </div>
      <div className="text-xs empty:hidden md:text-sm">{error}</div>
    </div>
  )
);

TextField.displayName = "NewInput";
