import { PillCheckbox } from "@atoms/pill-checkbox/PillCheckbox";
import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ContactFormSchema } from "../ContactSection.types";
import { mbStyles } from "./shared";

export const TopicsField: FC<{ className?: string }> = ({ className }) => {
  const {
    register,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext<ContactFormSchema>();

  const { fields } = useFieldArray<ContactFormSchema>({
    name: "topics",
    control,
  });

  const errorMsg = errors.topics?.root?.message;

  return (
    <div className={cn(mbStyles, className)}>
      <Typography tag="h2" variant="h4" className={mbStyles}>
        I'm interested in... *
      </Typography>
      <div className="flex flex-wrap gap-3">
        {fields.map((it, ind) => (
          <PillCheckbox
            id={`contact-topics-${ind}`}
            key={it.id}
            label={it.name}
            labelClassName="w-fit"
            {...register(`topics.${ind}.isIncluded`, {
              onChange: () => clearErrors("topics"),
            })}
          />
        ))}
      </div>
      <Typography
        tag="p"
        variant="base"
        className={cn("hidden pt-6 text-error", errorMsg && "block")}
      >
        {errorMsg}
      </Typography>
    </div>
  );
};
