import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { type ColumnStepsProps } from "@sections/column-steps/ColumnSteps.types";
import { Step } from "@sections/column-steps/components/Step";
import { FC } from "react";

export const ColumnSteps: FC<ColumnStepsProps> = ({
  header,
  summary,
  steps,
  themeName,
  isTransparentBackground,
  anchorId,
  showIcons,
}) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground={isTransparentBackground}
    id={anchorId}
    data-strapi="column-steps"
    data-theme={themeName}
  >
    <div className="container-flexible">
      <div className="flex flex-col gap-8 md:gap-10 lg:gap-5 2xl:gap-8">
        <div className="flex flex-col gap-5 lg:px-0 lg:py-2.5 2xl:gap-6 2xl:px-0 2xl:py-5">
          <Typography tag="h2" variant="h2" className="text-section-title">
            {header}
          </Typography>
          <span className="t-body-b2 text-primary/75">{summary}</span>
        </div>
        {steps && (
          <div className="relative grid py-2.5 lg:grid-cols-2 lg:grid-rows-[auto_auto] lg:px-0 2xl:grid-cols-4 2xl:px-0 2xl:py-5">
            {steps.map((step, index) => (
              <Step
                {...step}
                counter={!showIcons ? index + 1 : undefined}
                key={`${step.title}-${index + 1}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  </ContainerSpacing>
);
