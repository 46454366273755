import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { cn, tw } from "@utils/tailwind";
import parse, { domToReact } from "html-react-parser";
import { FC, useMemo, useRef, useState } from "react";
import { CollapsableListProps } from "./CollapsableList.types";
import type { DOMNode } from "html-dom-parser";

export const CollapsableList: FC<CollapsableListProps> = ({
  items,
  isNumeric = false,
  className,
}) => {
  const [expandedId, setExpandedId] = useState<string>();
  const contentsRef = useRef<Map<string, HTMLDivElement>>(new Map());

  const leftColumnWidthVarStyles = tw`[--left-column-width:25px]
    sm:[--left-column-width:27px]
    md:[--left-column-width:30px]
    xl:[--left-column-width:33px]
    2xl:[--left-column-width:56px]`;

  const circleContainerStyles = tw`relative ml-auto flex
    size-[25px] items-center justify-center
    rounded-[50%] border border-solid border-green-black-equivalent
    transition-colors duration-300 ease-in-out
    group-hover:bg-green-black-equivalent
    group-focus:border-green-accent-one
    group-focus:bg-transparent
    group-active:bg-green-dark 2xl:size-9`;

  const plusIconStyles = tw`transition-all duration-300 ease-in-out
    group-hover:fill-green-accent-one
    group-focus:fill-green-black-equivalent
    group-active:fill-green-accent-one`;

  const collapsableElementStyles = tw`border-t border-solid border-t-green-black-equivalent
    bg-transparent pt-[13px]
    text-green-black-equivalent
    sm:pt-[15px] md:pt-[18px] 2xl:pt-[35px]`;

  const dotStyles = tw`block size-2
    rounded-[100px] transition-[background-color]
    duration-300 ease-in
    sm:h-2.5 sm:min-w-[10px]`;

  const contentContainerStyles = cn(
    `links-container mt-[15px]
    overflow-hidden text-lg text-grey-scale-text
    transition-all duration-300 ease-in-out
    md:mt-[13px] 2xl:mt-[31px]`,
    // paragraph
    `[&_p]:pr-[30px] lg:[&_p]:pl-[--left-column-width]`,
    // ul
    `[&_ul:first-child]:pt-0 [&_ul:last-child]:pb-0
    [&_ul]:m-0 [&_ul]:px-0 [&_ul]:py-[15px]
    md:[&_ul]:px-0 md:[&_ul]:py-5
    2xl:[&_ul]:px-0 2xl:[&_ul]:py-[25px]`
  );

  const itemsWithIcons = useMemo(
    () =>
      items
        ? items.map((item, ind) => ({
            ...item,
            id: `${item.title}-${ind}`,
            description: item.description
              ? parse(item.description, {
                  replace(node) {
                    if (node.type === "tag" && node.name == "li") {
                      return (
                        <li className="mb-2.5 flex list-none items-center last:mb-0 lg:mb-5 lg:ml-[calc(var(--left-column-width)_+_5px)] lg:last:mb-0">
                          <div
                            data-name="Left icon container"
                            className="mr-[15px]"
                          >
                            <Icon
                              name="Thick"
                              className="h-[7.5px] min-w-[10px] fill-green-accent-one sm:h-2.5 sm:min-w-[13px]"
                            />
                          </div>
                          <span>{domToReact(node.children as DOMNode[])}</span>
                        </li>
                      );
                    }
                  },
                })
              : "",
          }))
        : null,
    [items]
  );

  const onItemClicked = (id: string) => {
    setExpandedId((prev) => (prev === id ? undefined : id));
  };

  const getMaxHeight = (id: string): number =>
    expandedId === id && contentsRef.current.has(id)
      ? contentsRef.current.get(id)?.scrollHeight ?? 0
      : 0;

  const getRefHandler = (id: string) => (node: HTMLDivElement | null) => {
    const map = contentsRef.current;
    if (node) map.set(id, node);
    else map.delete(id);
  };

  return (
    <div className={cn(className)}>
      {itemsWithIcons &&
        itemsWithIcons.map((item, index) => {
          const isExpanded = expandedId === item.id;
          return (
            <div
              data-name="Collapsible List"
              key={item.id}
              onClick={() => onItemClicked(item.id)}
              className={cn(
                "group cursor-pointer",
                isNumeric ? "[--left-column-width:0]" : leftColumnWidthVarStyles
              )}
            >
              <div
                data-name="Element"
                className={collapsableElementStyles}
                key={item.title}
              >
                <div data-name="Title container" className="flex items-center">
                  {!isNumeric && (
                    <div
                      data-name="Icon container"
                      className={cn("w-[--left-column-width] pl-[5px]")}
                    >
                      <div
                        data-name="Dot"
                        className={cn(
                          dotStyles,
                          isExpanded
                            ? "bg-green-accent-one"
                            : "bg-green-black-equivalent"
                        )}
                      />
                    </div>
                  )}

                  <Typography
                    tag="div"
                    variant="xl"
                    weight="medium"
                    className="m-0 max-w-[80%]"
                  >
                    {isNumeric && `${index + 1}. `}
                    {item.title}
                  </Typography>
                  <div
                    className={cn(
                      circleContainerStyles,
                      isExpanded
                        ? "bg-green-black-equivalent"
                        : "bg-content-primary-on-dark"
                    )}
                  >
                    <Icon
                      name="Plus"
                      data-name="Plus"
                      className={cn(
                        plusIconStyles,
                        isExpanded
                          ? "rotate-45 fill-content-primary-on-dark 2xl:fill-green-accent-one"
                          : "fill-green-black-equivalent"
                      )}
                    />
                  </div>
                </div>
              </div>
              <div
                data-name="Content container"
                className={contentContainerStyles}
                ref={getRefHandler(item.id)}
                style={{ maxHeight: getMaxHeight(item.id) }}
              >
                <div
                  data-name="Content container inner"
                  className="pb-[15px] md:pb-[18px] 2xl:pb-[35px]"
                >
                  {item.description}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
