import { renderCodeBlockSection } from "@content-sections/CodeBlock/CodeBlock.adapter";
import { renderCuratedBy } from "@content-sections/CuratedBy/CuratedBy.adapter";
import { renderFormatedTextSection } from "@content-sections/FormattedText/FormattedText.adapter";
import { renderImageBlock } from "@content-sections/ImageBlock/ImageBlock.adapter";
import { renderInfoBlock } from "@content-sections/InfoBlock/InfoBlock.adapter";
import { renderQuoteBlock } from "@content-sections/QuoteBlock/QuoteBlock.adapter";
import { renderTableBlock } from "@content-sections/TableBlock/TableBlock.adapter";
import { renderVideoBlock } from "@content-sections/VideoBlock/VideoBlock.adapter";
import { renderWarningBlock } from "@content-sections/WarningBlock/WarningBlock.adapter";
import { renderSchema } from "@content-sections/schema/Schema.adapter";
import { BlogContentWrapper } from "@layout/blog-content-wrapper/BlogContentWrapper";
import { renderCollapsableList } from "@molecules/collapsable-list/CollapsableList.adapter";
import { ReactNode } from "react";
import { RendererArg } from "@/types/renderer";
import { BlockContentSectionsComponent } from "@/types/sections";

const renderFormattedText = (data: RendererArg) => (
  <BlogContentWrapper>{renderFormatedTextSection(data)}</BlogContentWrapper>
);

const renderQuote = (data: RendererArg) => (
  <BlogContentWrapper>{renderQuoteBlock(data)}</BlogContentWrapper>
);

const contentVariants: Record<
  BlockContentSectionsComponent,
  (data: RendererArg) => ReactNode
> = {
  "block-paragraph": renderFormattedText,
  image: renderImageBlock,
  warning: renderWarningBlock,
  quote: renderQuote,
  "c2-a": renderInfoBlock,
  video: renderVideoBlock,
  tables: renderTableBlock,
  "curated-by": renderCuratedBy,
  "blog-accordion": renderCollapsableList,
  code: renderCodeBlockSection,
  "schema-script": renderSchema,
};

export const blogContentRenderer = (data: RendererArg): ReactNode => {
  const variant =
    contentVariants[data.componentName as BlockContentSectionsComponent];

  if (!variant) {
    // eslint-disable-next-line no-console
    console.error(
      `Component ${data.componentName} has no implementation in blogContentRenderer`
    );
    return null;
  }

  return variant(data);
};
