import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { ExpertTile } from "@sections/experts/expert-tile/ExpertTile";
import { ExpertsSectionProps } from "./ExpertsSection.types";

export const ExpertsSection = ({
  experts,
  header,
  themeName,
  anchorId,
  className,
}: ExpertsSectionProps) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground
    id={anchorId}
    data-strapi="experts"
    data-theme={themeName}
    className={className}
  >
    <div className="container-flexible xl:grid xl:grid-cols-12 xl:gap-5 2xl:gap-10">
      <div className="xl:sticky xl:top-24 xl:col-span-4 xl:h-min">
        <Typography
          tag="h2"
          variant="h2"
          weight="medium"
          className="mb-9 text-content-primary-on-light md:mb-10 xl:mb-12"
        >
          {header}
        </Typography>
      </div>
      {experts && (
        <ul className="flex flex-wrap gap-5 max-md:gap-y-7 md:gap-9 xl:col-span-8 xl:gap-5 2xl:col-span-7 2xl:col-start-6 2xl:gap-16">
          {experts.map((expert) => (
            <ExpertTile key={expert.id} expert={expert} />
          ))}
        </ul>
      )}
    </div>
  </ContainerSpacing>
);
