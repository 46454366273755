import Icon, { DynamicIconComponent } from "@atoms/icon/Icon";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import styles from "./WarningBlock.module.css";

import { WarningBlockProps } from "./WarningBlock.types";

export const WarningBlock: FC<WarningBlockProps> = ({
  title,
  description,
  className,
  icon,
}) => (
  <div
    className={cn(
      "relative flex w-full flex-col gap-7 rounded-xl bg-tangerine-light px-7 py-12 text-content-primary-on-light",
      "sm:flex-row sm:p-12",
      styles.warningBlock,
      className
    )}
  >
    {typeof icon === "string" ? (
      <Icon name={icon} />
    ) : (
      <DynamicIconComponent {...icon} />
    )}

    <div className="grid items-center gap-7">
      {title && <h3 className="t-headline-h3 font-sans"> {title}</h3>}
      {description && <p className="t-body-b2 opacity-75">{description}</p>}
    </div>
  </div>
);
