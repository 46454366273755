import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
} from "@molecules/command/Command";
import { Hit } from "@page-components/blogs-list/searchbar/hit/Hit";
import { useFocusOnShortcut } from "@utils/forms/use-focus-on-shortcut";
import { useBlogs } from "@utils/search/queries/blogs";
import { cn } from "@utils/tailwind";
import { FC, useCallback, useRef, useState } from "react";
import { buildTextForNoHits, useAutoHiding, useOnEsc } from "./Search.helpers";

export const Search: FC<{ className?: string }> = ({ className }) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: hits, isFetching, isError } = useBlogs(searchValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const commandEmptyRef = useRef<HTMLDivElement>(null);
  const showHits = !isError && hits?.length !== 0;
  const { isHidden, setIsHidden } = useAutoHiding({
    inputRef,
    commandEmptyRef,
  });
  const reset = useCallback(() => setSearchValue(""), []);
  useFocusOnShortcut(inputRef);
  useOnEsc(reset);

  return (
    <Command
      loop
      className={cn("relative overflow-visible", className)}
      shouldFilter={false}
    >
      <CommandInput
        ref={inputRef}
        placeholder="Search blog..."
        value={searchValue}
        onValueChange={setSearchValue}
        onFocus={() => setIsHidden(false)}
      />
      <CommandList
        className={cn(
          "absolute left-0 top-12 z-10 w-full rounded-md bg-zinc-50 shadow-lg",
          isHidden && "hidden"
        )}
      >
        <CommandEmpty
          ref={commandEmptyRef}
          className={cn(isFetching && "animate-pulse")}
        >
          {buildTextForNoHits(isFetching, isError, searchValue)}
        </CommandEmpty>
        <CommandGroup className={showHits ? "" : "hidden"}>
          {hits?.map((it) => <Hit key={it.id} isStale={isFetching} {...it} />)}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
