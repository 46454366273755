import { cn, tw } from "@utils/tailwind";
import { ComponentPropsWithoutRef, forwardRef, useEffect, useRef } from "react";

export type TextAreaFieldProps = ComponentPropsWithoutRef<"textarea"> & {
  id: string;
  label?: string;
  error?: string;
  withPaddings?: boolean;
};

const hiddenLabelStyles = tw`absolute -m-px size-px overflow-hidden`;

// Tricky styles to make textarea grow using grid
// https://cruip.com/auto-growing-textarea-with-tailwind-css/
const textareaWrapperStyles = tw`relative
  grid
  font-sans
  text-base
  after:invisible
  after:whitespace-pre-wrap
  after:border-b
  after:pb-4
  after:font-medium
  after:text-inherit
  after:content-[attr(data-cloned-val)_"_"]
  after:[grid-area:1/1/2/2]
  xl:text-lg
  [&>textarea]:resize-none
  [&>textarea]:overflow-hidden
  [&>textarea]:text-inherit
  [&>textarea]:[grid-area:1/1/2/2]`;

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(({ label, error, withPaddings, className, ...inputProps }, ref) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const updateWrapperValue = (value?: string) => {
    const wrapperElement = wrapperRef.current;
    if (wrapperElement) {
      wrapperElement.dataset.clonedVal = value;
    }
  };

  // Update wrapper height after a small delay
  useEffect(() => {
    setTimeout(() => {
      const value = wrapperRef.current?.querySelector("textarea")?.value;
      updateWrapperValue(value);
    }, 500);
  }, []);

  return (
    <div
      className={cn(
        "flex flex-col gap-4",
        withPaddings && "pt-5 md:pt-6 xl:pt-7",
        error && "text-error",
        className
      )}
    >
      <div ref={wrapperRef} className={textareaWrapperStyles}>
        <textarea
          rows={1}
          placeholder={inputProps.required ? `${label} *` : label}
          autoComplete="off"
          ref={ref}
          className={cn(
            "peer w-full rounded-none border-b border-grey-mid bg-transparent pb-4 font-medium outline-none",
            error ? "border-error text-error" : "text-grey-scale-off-black"
          )}
          onInput={(evt) => updateWrapperValue(evt.currentTarget.value)}
          {...inputProps}
        />
        <label htmlFor={inputProps.id} className={hiddenLabelStyles}>
          {label}
        </label>
      </div>
      <div className="text-xs empty:hidden md:text-sm">{error}</div>
    </div>
  );
});

TextAreaField.displayName = "NewInput";
