import { TextField } from "@atoms/text-field/TextField";
import { useFormContext } from "react-hook-form";
import { ContactFormSchema } from "../ContactSection.types";

export const EmailField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ContactFormSchema>();
  return (
    <TextField
      required
      id="contact-email"
      type="email"
      label="Email"
      error={errors.email?.message}
      {...register("email")}
    />
  );
};
