import { MutableRefObject, useEffect } from "react";

/** It focuses input when ⌘K is pressed */
export const useFocusOnShortcut = (
  ref: MutableRefObject<HTMLInputElement | null>
) => {
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        ref.current?.focus();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [ref]);
};
