import { TableOfContents } from "@atoms/TableOfContents/TableOfContents";
import { LightBox } from "@atoms/light-box/LightBox";
import { useImageLightbox } from "@utils/use-image-lightbox/useImageLightbox";
import { FC, useEffect, useRef } from "react";
import { BlogAside } from "./BlogAside/BlogAside";
import { BlogBottom } from "./BlogBottom/BlogBottom";
import { BlogLayoutProps } from "./BlogLayout.types";

export const BlogLayout: FC<BlogLayoutProps> = ({
  children,
  headingNodes,
  relatedArticles,
  articleTags,
  interestingArticles,
}) => {
  const articleRef = useRef<HTMLDivElement>(null);
  const { selectedImage, isLightboxOpen, closeLightbox } =
    useImageLightbox(articleRef);

  // Function to set margin-bottom to 0 for the last div in article
  useEffect(() => {
    const article = articleRef.current;
    if (article) {
      const divs = article.querySelectorAll("div");
      const lastDiv = divs[divs.length - 1];
      if (lastDiv) {
        lastDiv.style.marginBottom = "0";
      }
    }
  }, [children]);
  return (
    <main className="relative">
      <LightBox
        url={selectedImage}
        onClick={closeLightbox}
        active={isLightboxOpen}
      />
      <nav className="absolute left-0 top-0 z-10 h-full w-6 md:hidden">
        <TableOfContents
          className="sticky top-20 z-10"
          style={{ height: "calc(100vh - 155px)" }}
          headingNodes={headingNodes}
        />
      </nav>
      <div className="container-flexible relative flex md:gap-5 2xl:max-w-screen-2xl 2xl:justify-between">
        <div
          style={{ contain: "paint" }}
          className="md:sticky md:left-0 md:top-28 md:w-[220px] md:min-w-[220px] md:translate-x-0 md:self-start md:pb-5 xl:w-[24%]"
        >
          <nav className="sticky top-20 hidden md:block">
            <TableOfContents
              style={{ height: "calc(100vh - 155px)" }}
              className="inline-flex min-w-[220px] flex-col md:w-full md:p-0"
              headingNodes={headingNodes}
            />
          </nav>
        </div>
        <article
          className="w-full min-w-0 2xl:w-1/2 2xl:min-w-[50%]"
          ref={articleRef}
        >
          {children}
        </article>
        <BlogAside
          className="sticky top-28 hidden self-start pb-5 text-right text-grey-faded 2xl:block 2xl:w-[24%]"
          relatedArticles={relatedArticles}
          articleTags={articleTags}
        />
      </div>
      <BlogBottom
        articleTags={articleTags}
        interestingArticles={interestingArticles}
      />
    </main>
  );
};
