import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SphereEventsSectionProps } from "@sections/sphere-events/SphereEventsSection.types";
import { FC } from "react";
import { SphereEventsCard } from "./cards/SphereEventsCard";

export const SphereEventsSection: FC<SphereEventsSectionProps> = ({
  title,
  subtitle,
  cards,
  isTransparentBackground,
  anchorId,
  themeName,
}) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground={isTransparentBackground}
    id={anchorId}
    data-strapi="sphere-events"
    data-theme={themeName}
  >
    <div className="container-flexible">
      <Typography
        tag="h2"
        variant="h2"
        data-name="Title"
        className="mb-4 text-section-title xl:mb-8"
      >
        {title}
      </Typography>
      <p
        data-name="Subtitle"
        className="t-body-b2 mb-5 text-section-title opacity-75 md:mb-10 2xl:mb-12"
      >
        {subtitle}
      </p>
      {cards && (
        <div
          data-name="Cards"
          className="grid gap-5 md:grid-cols-2 md:gap-x-10 md:gap-y-8 2xl:grid-cols-4 2xl:gap-20"
        >
          {cards.map((card) => (
            <SphereEventsCard key={card.id} {...card} />
          ))}
        </div>
      )}
    </div>
  </ContainerSpacing>
);
