import { GreenRoundedTile } from "@molecules/green-rounded-tile/GreenRoundedTile";
import { GreenRoundedTileProps } from "@molecules/green-rounded-tile/GreenRoundedTile.types";
import { ContentList } from "@sections/content-list/ContentList";
import { ContentListProps } from "@sections/content-list/ContentList.types";
import { HeaderSubpageSection } from "@sections/header-subpage/HeaderSubpageSection";

import { PartnerFlexiblySectionProps } from "@sections/partner-flexibly/PartnerFlexiblySection.types";
import { TestimonialsSection } from "@sections/testimonials/TestimonialsSection";
import { TestimonialsSectionProps } from "@sections/testimonials/TestimonialsSection.types";
import { FC } from "react";

export type DynamicListProps = {
  globalPartners: PartnerFlexiblySectionProps;
  testimonials: TestimonialsSectionProps;
  header: { title: string; caption: string };
  contentList: ContentListProps;
  list?: GreenRoundedTileProps[];
};

export const DynamicList: FC<DynamicListProps> = ({
  testimonials,
  header,
  contentList,
  list,
}) => (
  <>
    <HeaderSubpageSection
      header={header.title}
      subheader={header.caption}
      disableCta
      showBorder={false}
    />
    <ContentList {...contentList}>
      {list &&
        list.length > 0 &&
        list.map((element, index) => (
          <GreenRoundedTile key={`${element.title}-${index}`} {...element} />
        ))}
    </ContentList>
    <TestimonialsSection {...testimonials} />
  </>
);
