import { Button } from "@atoms/button/Button";
import { IconsNames } from "@icons/allIcons";
import { FC, useEffect, useState } from "react";

type SocialMedia = "facebook" | "x" | "linkedin";

export type ShareButtonProps = {
  socialMedia: "facebook" | "x" | "linkedin";
};

export const ShareButton: FC<ShareButtonProps> = ({ socialMedia }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const link: Record<SocialMedia, string> = {
    facebook: "https://www.facebook.com/share.php?u=",
    x: "https://twitter.com/share?url=",
    linkedin: "https://www.linkedin.com/sharing/share-offsite/?url=",
  };
  const icon: Record<SocialMedia, IconsNames> = {
    facebook: "facebook",
    x: "muskx",
    linkedin: "linkedin",
  } as const;

  return (
    <Button
      intent="primary"
      size="medium"
      background="light"
      icon={icon[socialMedia]}
      href={`${link[socialMedia]}${url}`}
      asLink
      className="px-8 py-3"
    />
  );
};
