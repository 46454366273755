import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SponsorshipSectionProps } from "@sections/sponsorship/SponsorshipSection.types";
import { FC } from "react";

export const SponsorshipSection: FC<SponsorshipSectionProps> = ({
  logos,
  rawHtmlDescription,
  title,
  anchorId,
  isTransparentBackground,
  themeName,
}) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground={isTransparentBackground}
    id={anchorId}
    data-strapi="sponsorship"
    data-theme={themeName}
    className="darkGreen:bg-green-dark"
  >
    <div className="container-flexible grid gap-5 text-primary sm:gap-8 xl:grid-cols-[0.6fr_1.4fr] 2xl:gap-16">
      <div>
        <Typography tag="h2" variant="h2" className="mb-4">
          {title}
        </Typography>

        <span
          dangerouslySetInnerHTML={{ __html: rawHtmlDescription }}
          className="t-body-b2 opacity-75 [&_a]:text-primary"
        />
      </div>
      {logos && (
        <div className="grid grid-cols-2 gap-x-5 gap-y-8 md:grid-cols-3 md:gap-x-5 md:gap-y-8 2xl:grid-cols-4 2xl:gap-x-12 2xl:gap-y-10">
          {logos.map((logo, ind) => (
            <div
              key={ind}
              className="relative aspect-[140/53] h-auto w-full overflow-hidden sm:aspect-[198/74] xl:aspect-[25/8] 2xl:aspect-[257/80]"
            >
              {"attributes" in logo && (
                <Image
                  backgroundImage
                  imageData={logo}
                  className="object-contain"
                />
              )}
              {"name" in logo && (
                <Icon
                  {...logo}
                  className="size-full fill-grey-scale-text darkGreen:fill-blue-light"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  </ContainerSpacing>
);
