import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { TimelineCard } from "@sections/timeline/components/TimelineCard";
import { tw } from "@utils/tailwind";
import { useResolution } from "@utils/use-resolution/useResolution";
import { FC, useEffect, useMemo, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { buildSlides, extractSortedYears } from "./TimelineSection.helpers";
import { TimelineSectionProps } from "./TimelineSection.types";
import { Controls } from "./components/Controls";

export const TimelineSection: FC<TimelineSectionProps> = ({
  title,
  description,
  groups,
  themeName,
  isTransparentBackground,
  anchorId,
}) => {
  const resolution = useResolution();
  const sortedYears = useMemo(() => extractSortedYears(groups), [groups]);
  const [curSlideInd, setCurSlideInd] = useState<number>();

  const slides = useMemo(
    () => buildSlides(groups, sortedYears, resolution.width),
    [groups, sortedYears, resolution.width]
  );

  // Initialize curSlideInd once the resolution is > 0
  useEffect(() => {
    if (curSlideInd === undefined && slides.length && resolution) {
      setCurSlideInd(slides.length - 1);
    }
  }, [resolution, slides, curSlideInd]);

  const columnStyles = tw`grid gap-5
    sm:col-span-2
    sm:only:col-end-4
    md:gap-4
    xl:gap-5
    xl:only:col-end-5
    xl:last:[&:nth-child(2)]:col-end-[-2]
    xl:first:[&:nth-last-child(2)]:col-end-4`;

  return (
    <ContainerSpacing
      withBackground
      isTransparentBackground={isTransparentBackground}
      id={anchorId}
      data-strapi="timeline"
      data-theme={themeName}
    >
      <div className="container-flexible">
        {(title || description) && (
          <SectionHeader
            withMarginBottom
            title={title}
            subtitle={description}
            subtitleClassName="text-primary/75 opacity-75"
          />
        )}
      </div>
      {curSlideInd !== undefined && (
        <Swiper
          initialSlide={curSlideInd}
          onSlideChange={(swiper) => setCurSlideInd(swiper.activeIndex)}
        >
          <span slot="container-start">
            <Controls curSlideInd={curSlideInd} slides={slides} />
          </span>
          {slides &&
            slides.map((slide) => (
              <SwiperSlide key={slide.slideNumber} style={{ height: "auto" }}>
                <div
                  data-name="Cards"
                  className="container-flexible grid items-start gap-5 sm:grid-cols-4 md:gap-4 xl:grid-cols-6 xl:gap-5"
                >
                  {slide.columns.map((column, ind) => (
                    <div data-name="Column" key={ind} className={columnStyles}>
                      {column.map((card) => (
                        <TimelineCard key={card.bodyText} {...card} />
                      ))}
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </ContainerSpacing>
  );
};
