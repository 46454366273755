import { HeaderSubpageSection } from "@sections/header-subpage/HeaderSubpageSection";
import { FC } from "react";
import { ThankYouProps } from "./ThankYou.types";

export const ThankYou: FC<ThankYouProps> = ({ header, description }) => (
  <HeaderSubpageSection
    themeName="darkGreen"
    header={header}
    subheader={description}
    showBreadcrumbs={false}
    showBorder={false}
    className="min-h-fit pb-60"
  />
);
