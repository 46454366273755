export type ColorScheme = "Dark" | "Light";

export const themeNames = [
  "darkGreen",
  "lightGreen",
  "lightBlue",
  "white",
  "beige",
  "lightGrey",
  "v2Redesign",
] as const;

export type ThemeName = (typeof themeNames)[number];
