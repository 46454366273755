import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { FC } from "react";
import { CompanyRecordsSectionProps } from "./CompanyRecordsSection.types";
import { Gdpr } from "./Gdpr";

export const CompanyRecordsSection: FC<CompanyRecordsSectionProps> = ({
  headerLeft,
  headerRight,
  infoColumns,
  gdprEmail,
  anchorId,
}) => (
  <ContainerSpacing
    className="bg-content-primary-on-light !pb-0 text-blue-light"
    withBackground
    id={anchorId}
    data-strapi="company-records"
  >
    <div className="container-flexible flex flex-col gap-9 md:gap-10 lg:gap-12 xl:flex-row xl:gap-24 2xl:justify-between 2xl:gap-0">
      <div className="grid md:grid-cols-3 md:gap-x-10 lg:gap-x-20 xl:gap-x-24 2xl:gap-x-40">
        <Typography
          tag="h2"
          variant="h4"
          weight="medium"
          className="mb-9 md:col-span-3 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16"
        >
          {headerLeft}
        </Typography>
        {infoColumns?.map((it) => (
          <div
            key={it.id}
            className="mb-9 flex flex-col gap-4 last:mb-0 md:mb-0 md:gap-8"
          >
            <Typography tag="h3" variant="h6" weight="medium">
              {it.title}
            </Typography>
            <Typography
              wysiwygStyling
              tag="div"
              variant="sm"
              dangerouslySetInnerHTML={{
                __html: it.rawHtmlContent ?? "",
              }}
            />
          </div>
        ))}
      </div>
      <Gdpr header={headerRight} email={gdprEmail} />
    </div>
  </ContainerSpacing>
);
