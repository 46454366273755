import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsColumnSquaresComponent } from "@api/contract";
import { ColumnSquaresProps } from "@sections/column-squares/ColumnSquares.types";
import { RendererArg } from "@/types/renderer";
import { ColumnSquares } from "./ColumnSquares";

const map = ({
  title,
  description,
  side_image,
  column_squares,
  block_settings,
}: ServiceSectionsColumnSquaresComponent): ColumnSquaresProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title,
  description,
  sideImage: side_image ?? "topography",
  tiles: column_squares?.map(({ title, list_items }) => ({
    title,
    points: list_items?.map((i) => i.description),
  })),
});

export const renderColumnSquares = ({ contentProps }: RendererArg) => (
  <ColumnSquares {...map(contentProps as ColumnSquaresProps)} />
);
