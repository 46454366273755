import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SuccessStoriesCard } from "@sections/success-stories/cards/SuccessStoriesCard";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { SuccessStoriesSectionProps } from "./SuccessStoriesSection.types";

export const SuccessStoriesSection: FC<SuccessStoriesSectionProps> = ({
  title,
  cards,
  anchorId,
  className,
}) => (
  <ContainerSpacing
    withBackground
    id={anchorId}
    data-strapi="success-stories"
    className={cn("bg-grey-scale-light-gray", className)}
  >
    <div className="container-flexible">
      <Typography
        wysiwygStyling
        tag="h2"
        variant="h2"
        className="mb-9 text-content-primary-on-light md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className="grid gap-6 empty:hidden md:gap-5 lg:gap-y-4 xl:grid-cols-[repeat(3,minmax(auto,500px))] xl:justify-between xl:gap-0 xl:gap-x-4">
        {cards.map((it) => (
          <SuccessStoriesCard key={it.id} {...it} />
        ))}
      </div>
    </div>
  </ContainerSpacing>
);
