import { DefaultDefaultFullPageTextComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import {
  DefaultFullPageText,
  DefaultFullPageTextProps,
} from "./DefaultFullPageText";

const map = ({
  content,
}: DefaultDefaultFullPageTextComponent): DefaultFullPageTextProps => ({
  rawHtmlContent: content ?? "",
});

export const renderDefaultFullPageText = ({ contentProps }: RendererArg) => (
  <DefaultFullPageText
    {...map(contentProps as DefaultDefaultFullPageTextComponent)}
  />
);
