import Typography from "@atoms/typography/Typography";
import { tw } from "@utils/tailwind";
import { FC } from "react";

const linkStyles = tw`no-underline
  underline-offset-2
  outline-none
  hover:underline
  hover:decoration-blue-light
  focus:text-green-accent-one
  focus:underline
  focus:decoration-green-accent-one
  active:text-green-accent-one
  active:underline
  active:decoration-green-accent-one`;

export const Gdpr: FC<{ email?: string; header?: string }> = ({
  email,
  header,
}) => (
  // Note: 2xl:w-[18.5rem] is a hardcoded width to align it with the footer nav
  <div className="flex gap-5 md:gap-9 xl:flex-col xl:gap-7 2xl:w-[18.5rem]">
    <Typography tag="h2" variant="h4" weight="medium">
      {header}
    </Typography>
    <a className={linkStyles} href={`mailto:${email}`}>
      <Typography tag="span" variant="sm">
        {email}
      </Typography>
    </a>
  </div>
);
