import { IconFileApi } from "@api/types";
import { DynamicIconComponentProps } from "./Icon";

export const iconAdapter = (
  data: IconFileApi | undefined
): DynamicIconComponentProps | undefined =>
  data
    ? {
        url: data?.url,
        isAnimated: data?.ext === ".webm",
        width: data?.width,
        height: data?.height,
      }
    : undefined;
