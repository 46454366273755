import { blockSettingsAdapter } from "@adapters/block-settings";
import { HomeSectionsBigNumbersComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { BigNumbers } from "./BigNumbers";
import { BigNumbersSectionProps } from "./BigNumbers.types";

const bigNumbersAdapter = ({
  bn_title,
  bn_claim,
  bn_cta,
  bn_cards,
  block_settings,
}: HomeSectionsBigNumbersComponent): BigNumbersSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title: bn_title ?? undefined,
  claim: bn_claim ?? undefined,
  cta:
    bn_cta && bn_cta.button_url
      ? {
          href: bn_cta?.button_url,
          buttonLabel: bn_cta?.button_text,
          target: bn_cta?.button_new_window ? "_blank" : "_self",
          asLink: true,
        }
      : undefined,
  cards:
    bn_cards?.map(({ number, description, href, id }) => ({
      id: id ?? 0,
      number: number ?? undefined,
      href: href ?? undefined,
      description: description ?? undefined,
    })) ?? [],
});

export const renderBigNumbers = ({ contentProps }: RendererArg) => (
  <BigNumbers
    {...bigNumbersAdapter(contentProps as HomeSectionsBigNumbersComponent)}
  />
);
