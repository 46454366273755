import { ArticleTileSize } from "@atoms/articleTile/ArticleTile.types";
import { ArticlesList } from "@molecules/ArticlesList/ArticlesList";
import { Tags } from "@molecules/tags/Tags";
import { FC } from "react";
import { ArticleTag } from "@/types/article";
import { ArticleProps } from "../BlogLayout.types";

type BlogAsideProps = {
  relatedArticles?: ArticleProps[];
  articleTags?: ArticleTag[];
  className?: string;
};

export const BlogAside: FC<BlogAsideProps> = ({
  relatedArticles,
  articleTags,
  className,
}) => (
  <aside className={className}>
    {relatedArticles && relatedArticles.length > 0 && (
      <ArticlesList
        size={ArticleTileSize.SMALL}
        title="RELATED ARTICLES"
        articles={relatedArticles}
        className="mb-8"
      />
    )}
    {articleTags && articleTags.length > 0 && (
      <Tags
        listClassName="justify-end"
        title="ARTICLE TAGS"
        tags={articleTags}
      />
    )}
  </aside>
);
