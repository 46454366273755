import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { FC, PropsWithChildren } from "react";
import { BlockSettings } from "@/types/component";

export type VideoBlockContainerProps = PropsWithChildren<BlockSettings>;

export const VideoBlockContainer: FC<VideoBlockContainerProps> = ({
  children,
  themeName,
  isTransparentBackground,
  anchorId,
}) => (
  <ContainerSpacing
    className="bg-section"
    data-theme={themeName}
    withBackground
    isTransparentBackground={isTransparentBackground}
    id={anchorId}
  >
    <div className="mx-auto max-w-[300px] sm:max-w-[476px] md:max-w-[645px] 2xl:max-w-[1180px]">
      {children}
    </div>
  </ContainerSpacing>
);
