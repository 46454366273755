import { ContentSectionsResultsComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { ColumnText, ColumnTextProps } from "./ColumnText";

const map = ({
  title,
  column_1,
  column_2,
}: ContentSectionsResultsComponent): ColumnTextProps => ({
  title,
  column: [...(column_1 ?? []), ...(column_2 ?? [])],
});

export const renderColumnText = ({ contentProps }: RendererArg) => (
  <ColumnText {...map(contentProps as ContentSectionsResultsComponent)} />
);
