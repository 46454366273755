import { SVGProps } from "react";
import { localSvgs, LocalSvgName, getStaticSvg } from "./localSvgs";

interface LocalSvgProps extends SVGProps<SVGSVGElement> {
  name: LocalSvgName;
}

const LocalSvg = ({ name, ...rest }: LocalSvgProps) => {
  const SvgComponent = localSvgs[getStaticSvg(name)];
  return SvgComponent ? <SvgComponent {...rest} /> : null;
};

export default LocalSvg;
