import { ContentSectionsBlogAccordionComponent } from "@api/contract";
import { CollapsableListProps } from "@molecules/collapsable-list/CollapsableList.types";
import { RendererArg } from "@/types/renderer";
import { CollapsableList } from "./CollapsableList";

const map = ({
  blog_acc_tabs,
}: ContentSectionsBlogAccordionComponent): CollapsableListProps => ({
  items: blog_acc_tabs
    ? blog_acc_tabs.map(({ title, description }) => ({
        title,
        description,
      }))
    : [],
  isNumeric: true,
});

export const renderCollapsableList = ({ contentProps }: RendererArg) => (
  <CollapsableList
    {...map(contentProps as ContentSectionsBlogAccordionComponent)}
  />
);
