import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { cn } from "@utils/tailwind";
import hljs from "highlight.js/lib/core";
import bash from "highlight.js/lib/languages/bash";
import go from "highlight.js/lib/languages/go";
import java from "highlight.js/lib/languages/java";
import javascript from "highlight.js/lib/languages/javascript";
import plaintext from "highlight.js/lib/languages/plaintext";
import python from "highlight.js/lib/languages/python";
import scala from "highlight.js/lib/languages/scala";
import typescript from "highlight.js/lib/languages/typescript";
import yaml from "highlight.js/lib/languages/yaml";
import "highlight.js/styles/default.min.css";
import { FC } from "react";
import { CodeBlockProps } from "./CodeBlock.types";

export const CodeBlock: FC<CodeBlockProps> = ({ language, code }) => {
  hljs.registerLanguage("java", java);
  hljs.registerLanguage("go", go);
  hljs.registerLanguage("bash", bash);
  hljs.registerLanguage("scala", scala);
  hljs.registerLanguage("yaml", yaml);
  hljs.registerLanguage("javascript", javascript);
  hljs.registerLanguage("typescript", typescript);
  hljs.registerLanguage("plaintext", plaintext);
  hljs.registerLanguage("python", python);
  let highlightedCode;

  try {
    highlightedCode = hljs.highlight(code, {
      language: language === "console" ? "bash" : language.toLowerCase(),
    }).value;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`CodeBlock has returned an error: ${error}`);
  }
  const codeBlockWrapperStyles = cn(
    "relative left-[calc(-50vw+50%)] w-screen overflow-x-scroll rounded-r-xl border-l-4 border-green-accent-two bg-content-primary-on-dark p-7",
    "sm:left-0 sm:w-full sm:px-10 sm:py-7",
    "md:px-12",
    marginBottomWrapperStyles
  );
  return (
    <div className={codeBlockWrapperStyles}>
      <h3 className="t-headline-h3 mb-7 font-sans">{language}</h3>
      <pre>
        <code
          className="t-body-b2 text-grey-scale-off-black"
          dangerouslySetInnerHTML={{ __html: highlightedCode || code }}
        />
      </pre>
    </div>
  );
};
