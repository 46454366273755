import { CardProps } from "@sections/trifold-section/expandable-cards/Card/Card.types";
import { cn, tw } from "@utils/tailwind";
import { useIsVisible } from "@utils/use-is-visible/useIsVisible";
import trifoldBgImage from "public/images/trifold-background.png";
import { CSSProperties, FC, useRef } from "react";
import { CardLink, CardVariant } from "../ExpandableCards.types";
import styles from "./Card.module.css";
import { TrifoldLink } from "./TrifoldLink/TrifoldLink";

export const Card: FC<CardProps> = ({
  variant,
  number,
  title,
  type,
  links,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { hasIntersectedOnce } = useIsVisible(cardRef, "64px");

  const renderCardItems = (items: CardLink[], variant: CardVariant) => (
    <div
      className={cn(
        `mb-16 w-full columns-1 opacity-100
        md:columns-2
        xl:absolute xl:left-0 xl:top-0 xl:mb-0 xl:opacity-0`,
        styles.cardItemsContainer
      )}
    >
      <div className="space-y-5">
        {items.map((item) => (
          <TrifoldLink
            className="mb-5 ml-0 max-w-full xl:w-fit xl:last:mb-0"
            onDarkBg={variant !== CardVariant.LIGHT}
            key={item.id}
            href={item.url}
          >
            {item.title}
          </TrifoldLink>
        ))}
      </div>
    </div>
  );

  const rootStyles = cn(
    styles.card,
    `relative h-auto w-full rounded-4xl border border-solid border-transparent p-8 xl:relative 
      xl:h-[685px] xl:w-[initial] xl:flex-1 xl:transition-all xl:duration-300 xl:ease-in-out xl:hover:flex-[2.5]
      2xl:rounded-[48px] 2xl:p-10`,
    variant === CardVariant.IMAGE &&
      "bg-[image:var(--bg-url)] bg-cover bg-[center_bottom] bg-no-repeat text-blue-light",
    variant === CardVariant.GREEN && "bg-green-saturated text-blue-light",
    variant === CardVariant.LIGHT &&
      "bg-green-secondary text-green-black-equivalent"
  );

  const rootCssProps = hasIntersectedOnce
    ? ({
        "--bg-url": `url('${trifoldBgImage.src}')`,
      } as CSSProperties)
    : ({
        backgroundImage: "none",
        "--bg-url": `url('${trifoldBgImage.src}')`,
      } as CSSProperties);

  const headingStyles = tw`t-headline-h3
    mb-5 whitespace-normal
    md:mb-8 lg:mb-5 xl:mb-10 xl:whitespace-pre-line
    2xl:mb-16 [&_span]:whitespace-nowrap`;

  const numberStyles = tw`flex size-6 items-center justify-center
    rounded-[30px] border border-solid border-current text-sm font-light
    sm:size-8
    xl:block xl:size-[initial] xl:rounded-none xl:border-0 xl:text-5xl
    2xl:text-8xl 2xl:leading-18`;

  return (
    <div className={rootStyles} style={rootCssProps} ref={cardRef}>
      <div className="mb-5 flex items-center md:mb-8 lg:mb-5 xl:mb-10 xl:items-start">
        <div className={numberStyles}>{number}</div>

        <div className="t-breadcrumbs ml-2.5 h-fit rounded-[30px] border border-solid border-current px-1.5 py-1 xl:ml-auto xl:h-[initial]">
          {type}
        </div>
      </div>

      <h3
        className={headingStyles}
        dangerouslySetInnerHTML={{ __html: title ?? "" }}
      />

      <div className="relative mb-0 xl:pb-80">
        {links && renderCardItems(links, variant)}
      </div>
    </div>
  );
};
