import { ArticleProps } from "@layout/blog-layout/BlogLayout.types";

export enum ArticleTileSize {
  DEFAULT = "default",
  SMALL = "small",
}

export interface ArticleTileProps extends ArticleProps {
  size?: ArticleTileSize;
}
