import { blockSettingsAdapter } from "@adapters/block-settings";
import {
  HomeSectionsEventsListComponent as ApiEventsListSection,
  ApiEvent,
  SmallComponentsEventSpeakerComponent as ApiSpeaker,
} from "@api/contract";
import { EventsListSection } from "@sections/events-list/EventsListSection";
import {
  EventProps,
  EventsListSectionProps,
  SpeakerProps,
} from "@sections/events-list/EventsListSection.types";
import { isNull } from "lodash";
import { RendererArg } from "@/types/renderer";

const getSpeaker = (data: ApiSpeaker, ind: number): SpeakerProps => ({
  id: data.id ?? ind,
  name: data.speaker_name ?? "",
  description: data.speaker_position,
  image: data.speaker_image?.data,
  link: data.speaker_linkedin
    ? {
        title: "linkedin",
        href: data.speaker_linkedin,
      }
    : undefined,
});
const checkDateFormat = (dateString: string) => {
  const pattern = /^(?:\d{1,2}[.-]){2}\d{4}$/;
  return pattern.test(dateString);
};

const excludePastEvents = (events: ApiEvent[] | undefined) => {
  if (events === undefined || isNull(events)) return events;
  const currentDate = new Date();
  return events.filter((event) => {
    if (
      event.attributes &&
      event.attributes.event_end_date &&
      checkDateFormat(event.attributes.event_end_date)
    ) {
      const [day, notZeroBasedMonth, year] = event.attributes.event_end_date
        .split(/[-.]/)
        .map(Number);
      const month = notZeroBasedMonth - 1;
      // Note: we rather have to build Date instance as below
      // https://github.com/VirtusLab/web-nextjs-virtuslab-2-0/pull/629
      // Even better approach would be to use one timezone but it works for now
      const eventEndDate = new Date(year, month, day);
      return eventEndDate >= currentDate;
    }
    return event;
  });
};

const getEvent = (
  { id, attributes: attrs }: ApiEvent,
  ind: number
): EventProps => ({
  id: id ?? ind,
  period:
    attrs?.event_date && attrs?.event_end_date
      ? {
          from: attrs.event_date,
          to: attrs.event_end_date,
        }
      : undefined,
  speakers: attrs?.event_speakers?.map(getSpeaker) ?? [],
  location: attrs?.event_location,
  description: attrs?.excerpt,
  image: attrs?.event_image?.data,
  href: attrs?.event_external_url,
});

const map = ({
  title,
  description,
  events,
  block_settings,
}: ApiEventsListSection): EventsListSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "lightBlue",
  }),
  title,
  subtitle: description,
  events: excludePastEvents(events?.data)?.map(getEvent) ?? [],
});

export const renderEventsListSection = ({ contentProps }: RendererArg) => (
  <EventsListSection {...map(contentProps as ApiEventsListSection)} />
);
