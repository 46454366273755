export enum CardVariant {
  IMAGE = "image",
  GREEN = "green",
  LIGHT = "light",
}

export type CardLink = {
  id?: number;
  title?: string;
  url?: string;
};

export type TrifoldCard = {
  type: string;
  title: string;
  links?: CardLink[];
};
