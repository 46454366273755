import Headline from "@atoms/headline";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC, useState } from "react";
import { useHighlightedNode } from "./TableOfContents.helpers";
import { TableOfContentsProps } from "./TableOfContents.types";

export const TableOfContents: FC<TableOfContentsProps> = ({
  headingNodes,
  className,
  style,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const highlightedId = useHighlightedNode(headingNodes);
  const toggleNav = () => setIsExpanded((prev) => !prev);
  const expandedStyle = isExpanded ? "translate-x-0" : "-translate-x-[256px]";
  const iconCommonStyle =
    "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-green-black-equivalent";
  return (
    <div
      className={cn(
        expandedStyle,
        "ease relative inline-block w-64 bg-content-primary-on-dark p-7 transition-transform duration-300 md:translate-x-0 lg:p-0",
        className
      )}
      style={style}
    >
      {headingNodes.length > 0 && (
        <>
          <Headline
            className="t-body-caption absolute -right-6 top-[calc(var(--navbar-height)+1rem)] flex h-6 w-24 origin-top-right rotate-90 cursor-pointer items-center justify-center bg-content-primary-on-dark md:relative md:right-auto md:top-auto md:mb-5 md:block md:h-auto md:w-full md:rotate-0 md:cursor-default md:text-grey-faded"
            onClick={toggleNav}
            noDefaultStyle
            tag="h6"
          >
            <span className="relative mx-1 size-2.5 md:hidden">
              <span className={cn(iconCommonStyle, "h-px w-2")} />
              <span
                className={cn(
                  iconCommonStyle,
                  "ease h-2 w-px transition-opacity duration-300",
                  isExpanded && "opacity-0"
                )}
              />
            </span>
            CONTENT
          </Headline>
          <ul
            className={cn(
              "hidden max-h-full list-none flex-col gap-5 overflow-y-auto p-0 md:flex",
              isExpanded && "flex"
            )}
          >
            {headingNodes.map((item) => (
              <li className="p-0" key={item.id}>
                <Link
                  className={cn(
                    "ease t-subpage-body bg-none p-0 text-left text-grey-scale-off-black no-underline transition-colors duration-300 hover:text-green-accent-two",
                    item.id === highlightedId && "text-green-accent-two"
                  )}
                  data-disable-delay
                  href={`#${item.id}`}
                  onClick={toggleNav}
                >
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
