/**
 * Mapping ApiId -> function that gives a path
 * @see import('./entities').ApiId
 */
export const endpoints = {
  blog: (category: string, slug: string) => `/blog/${category}/${slug}/`,
  page: (slug: string) => `/${slug}/`,
  expertise: (slug: string) => `/expertise/${slug}/`,
  "case-studie": (slug: string) => `/success-stories/${slug}/`,
  capability: (slug: string) => `/capabilities/${slug}/`,
  service: (slug: string) => `/services/${slug}/`,
  "business-sector": (slug: string) => `/sectors/${slug}/`,
  "business-sectors-listing": () => "/sectors/",
  "capabilities-listing": () => "/capabilities/",
  "expertise-listing": () => "/expertise/",
  "services-listing": () => "/services/",
  "contact-us": () => "/contact/",
  home: () => "/",
  "blog-listing": (category?: string, page?: number, tag?: string) => {
    if (tag) return `/blog/tag/${tag}/`;

    const prefix = category ? `/blog/${category}` : `/blog`;
    const suffix = page ? `/page/${page}` : "";
    return `${prefix}${suffix}/`;
  },
  "case-studies-listing": (page?: number) =>
    page ? `/success-stories/page/${page}/` : "/success-stories/",
} as const;
