import { Button } from "@atoms/button/Button";
import { FormattedText } from "@content-sections/FormattedText/FormattedText";
import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { InfoBlockProps } from "./InfoBlock.types";

export const InfoBlock: FC<InfoBlockProps> = ({
  rawDescription,
  headline,
  button,
  className,
}) =>
  (!!rawDescription || !!headline || !!button?.text) && (
    <div
      className={cn(
        "relative flex w-full flex-col items-start gap-8 rounded-xl bg-green-dark px-7 py-12 text-grey-scale-light-gray [&_p]:t-body-b2",
        "sm:p-12",
        marginBottomWrapperStyles,
        className
      )}
    >
      {headline && <h3 className="t-headline-h3">{headline}</h3>}
      {rawDescription && (
        <FormattedText
          className="!mb-0 [&>:last-child]:mb-0 [&_li]:!text-grey-scale-light-gray [&_p]:!text-grey-scale-light-gray"
          content={rawDescription}
        />
      )}
      {button && button?.text && (
        <Button
          background="dark"
          size="big"
          intent="tertiary"
          href={button.url}
          target={button.newWindow ? "_blank" : "_self"}
          className="w-full border-grey-scale-light-gray text-grey-scale-light-gray sm:w-auto"
          asLink
        >
          {button.text}
        </Button>
      )}
    </div>
  );
