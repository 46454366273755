import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import {
  ChessCardWithLogosProps,
  Logo as LogoData,
} from "../ChessSection.types";
import { cardStyles } from "./Card.styles";
import { Texts } from "./Texts";

function getKey(logo: LogoData) {
  return "name" in logo ? logo.name : logo.src.attributes?.url;
}

const Logo: FC<LogoData> = (logo) =>
  "name" in logo ? (
    <Icon className="size-full fill-icon-fg" {...logo} />
  ) : (
    <div className="relative size-full overflow-hidden">
      <Image className="object-contain" backgroundImage imageData={logo.src} />
    </div>
  );

export const ChessCardWithLogos: FC<ChessCardWithLogosProps> = (props) => {
  const { logos, direction } = props;

  const logosWrapperStyles = cn(
    `mb-10 flex h-16 w-full items-center justify-between gap-2
    sm:h-24 sm:gap-4 md:gap-6
    lg:col-span-6 lg:row-span-1 lg:mb-0 lg:h-32
    2xl:col-span-5 2xl:h-36`,
    direction === "LR" &&
      "lg:col-start-7 lg:pl-5 lg:pr-0 2xl:col-start-7 2xl:pl-5 2xl:pr-0",
    direction === "RL" &&
      "lg:col-start-1 lg:pl-0 lg:pr-2.5 2xl:col-start-2 2xl:pl-0 2xl:pr-5"
  );

  return (
    <div className={cn(cardStyles, "lg:min-h-96")}>
      <div className={logosWrapperStyles}>
        {logos.map((logo) => (
          <Logo key={getKey(logo)} {...logo} />
        ))}
      </div>
      <Texts {...props} />
    </div>
  );
};
