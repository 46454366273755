import { Image } from "@atoms/image/Image";
import { CardProps } from "@sections/sphere-events/cards/SphereEventsCard.types";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";

export const SphereEventsCard: FC<CardProps> = ({
  title,
  description,
  image,
  href,
}) => {
  const underlineStyles = tw`underline
    decoration-transparent decoration-2
    underline-offset-4
    transition-colors duration-300 ease-out
    group-hover:decoration-current`;

  return (
    <Link
      href={href}
      className="group grid h-fit justify-items-center pb-8 text-card-title no-underline darkGreen:text-primary md:justify-items-start md:pb-5"
    >
      <div className="relative mb-6 aspect-square h-auto w-32 overflow-hidden rounded-3xl bg-card shadow-xl md:w-44 2xl:mb-10">
        <Image maxKnownWidth={240} backgroundImage imageData={image} />
      </div>

      <h4
        className={cn(
          "t-headline-h4 mb-2.5 text-center md:mb-4 md:text-left 2xl:mb-5",
          underlineStyles
        )}
      >
        {title}
      </h4>

      <p className="t-body-b2 text-balance text-center opacity-75 darkGreen:text-primary md:text-left">
        {description}
      </p>
    </Link>
  );
};
