import { contentRenderer } from "@utils/content-renderer/contentRenderer";
import { extractNameAndRender } from "@utils/content-renderer/extract-and-render/extractAndRender";
import React, { FC } from "react";
import { WithComponent } from "@/types/component";

export type HomeProps = {
  page_sections: WithComponent[];
};

export const Home: FC<HomeProps> = ({ page_sections }) => (
  <>
    {page_sections.map((page_section, idx) =>
      extractNameAndRender(page_section, contentRenderer, idx)
    )}
  </>
);
