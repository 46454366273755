import { Image } from "@atoms/image/Image";
import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { ImageBlockProps } from "./ImageBlock.types";

export const ImageBlock: FC<ImageBlockProps> = ({ imageObject, caption }) => (
  <div className={cn(marginBottomWrapperStyles, "relative w-fit")}>
    <Image
      className="max-h-[640px] cursor-pointer rounded-xl"
      allowLightBox
      imageData={imageObject}
    />
    {caption && (
      <p className="t-body-caption mt-4 text-grey-scale-mid">{caption}</p>
    )}
  </div>
);
