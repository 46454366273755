import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { LogoProps } from "@sections/open-source/open-source-components/types";
import { cn } from "@utils/tailwind";

export const Logo = (logo: LogoProps) => (
  <div
    data-name="LogoContainer"
    className={cn(
      "relative flex aspect-square h-full w-auto items-center justify-center overflow-hidden rounded-[50px]",
      logo.backgroundColor === "blue" ? "bg-blue-light" : "bg-[#F9F6F5]"
    )}
  >
    {"name" in logo && (
      <Icon
        name={logo.name}
        className="h-full w-auto fill-grey-scale-off-black"
      />
    )}

    {"attributes" in logo && (
      <Image
        backgroundImage
        imageData={logo}
        maxKnownWidth={50}
        className="object-contain"
      />
    )}
  </div>
);
