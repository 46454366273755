import {
  HomeSectionsAccordionCards3ColComponent,
  SmallComponentsAccordionCardComponent as AccordionCardComponent,
} from "@api/contract";
import { type TrifoldSectionProps } from "@sections/trifold-section/TrifoldSection.types";
import { TrifoldCard } from "@sections/trifold-section/expandable-cards/ExpandableCards.types";
import { RendererArg } from "@/types/renderer";
import { TrifoldSection } from "./TrifoldSection";

const getTrifoldCard = ({
  main_card_topic,
  main_title,
  links,
}: AccordionCardComponent): TrifoldCard => ({
  type: main_card_topic ?? "",
  title: main_title ?? "",
  links: links?.map(({ title, url, id }) => ({
    id,
    title: title ?? "",
    url: url ?? "",
  })),
});

const map = ({
  acc_cols,
  title,
  description,
}: HomeSectionsAccordionCards3ColComponent): TrifoldSectionProps => ({
  title: title ?? "",
  description: description ?? "",
  cards: acc_cols?.map(getTrifoldCard),
});

export const renderTrifoldSection = ({ contentProps }: RendererArg) => (
  <TrifoldSection
    {...map(contentProps as HomeSectionsAccordionCards3ColComponent)}
  />
);
