import { PillCheckbox } from "@atoms/pill-checkbox/PillCheckbox";
import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ContactFormSchema } from "../ContactSection.types";
import { mbStyles } from "./shared";

export const CameFromField = () => {
  const {
    register,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext<ContactFormSchema>();

  const { fields } = useFieldArray<ContactFormSchema>({
    name: "cameFrom",
    control,
  });

  const errorMsg = errors.cameFrom?.root?.message;

  return (
    <div className={mbStyles}>
      <Typography tag="h2" variant="h4" className={mbStyles}>
        How did you hear about VirtusLab? *
      </Typography>
      <div className="flex flex-wrap gap-3">
        {fields.map((it, ind) => (
          <PillCheckbox
            id={`contact-came-from-${ind}`}
            key={it.id}
            label={it.name}
            labelClassName="w-fit"
            {...register(`cameFrom.${ind}.isIncluded`, {
              onChange: () => clearErrors("cameFrom"),
            })}
          />
        ))}
      </div>
      <Typography
        tag="p"
        variant="base"
        className={cn("hidden pt-6 text-error", errorMsg && "block")}
      >
        {errorMsg}
      </Typography>
    </div>
  );
};
