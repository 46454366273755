import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsStoryInNumbersComponent } from "@api/contract";
import { iconAdapter } from "@atoms/icon/Icon.adapter";
import { OurStoryInNumbersSectionProps } from "@sections/story-in-numbers/OurStoryInNumbersSection.types";
import { RendererArg } from "@/types/renderer";
import { OurStoryInNumbersSection } from "./OurStoryInNumbersSection";

const map = ({
  block_settings,
  numbers_items,
  title,
}: ServiceSectionsStoryInNumbersComponent): OurStoryInNumbersSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
    fallbackTheme: "beige",
  }),
  title: title ?? "",
  cards:
    numbers_items?.map(({ icon_file, id, description, title }) => ({
      id: id ?? 0,
      description: description ?? "",
      title: title ?? "",
      icon: iconAdapter(icon_file?.data?.attributes),
    })) ?? [],
});

export const renderOurStoryInNumbersSection = ({
  contentProps,
}: RendererArg) => (
  <OurStoryInNumbersSection
    {...map(contentProps as ServiceSectionsStoryInNumbersComponent)}
  />
);
