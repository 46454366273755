import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { cn } from "@utils/tailwind";
import React, { FC, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { generateImageStyle } from "./Carousel.helpers";
import { CarouselImageVariant, CarouselProps } from "./Carousel.types";
import { breakpointValues } from "@utils/theming/breakpoints";

export const Carousel: FC<CarouselProps> = ({ images }) => {
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [displayImage, setDisplayImage] = useState(false);

  const handleMouseEnter = () => {
    !displayImage && setDisplayImage(true);
  };

  const handleMouseLeave = () => {
    displayImage && setDisplayImage(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    // SPY PER ELEMENT
    const x = e.pageX - e.currentTarget.offsetLeft;
    const y = e.pageY - e.currentTarget.offsetTop;
    setImagePosition({ x, y });
  };

  const mapHeight: Record<CarouselImageVariant, string> = {
    square: "!h-4/5",
    horizontal: "!h-full",
    "horizontal-small": "!h-[90%]",
    vertical: "!h-full",
  };

  const HoverCircleVisibilityStyle = displayImage
    ? "opacity-100 transform scale-100 duration-0"
    : "opacity-0 transform scale-0 duration-200";

  return (
    <div
      className="relative cursor-none"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onPointerMove={handleMouseMove}
    >
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        breakpoints={{
          [breakpointValues.sm]: { spaceBetween: 20 },
          [breakpointValues.md]: { spaceBetween: 30 },
          [breakpointValues.lg]: { spaceBetween: 40 },
          [breakpointValues.xl]: { spaceBetween: 40 },
          [breakpointValues.xxl]: { spaceBetween: 40 },
        }}
        className="h-[24.125rem] w-full md:h-[31.375rem] lg:h-[32.8125rem]"
      >
        {images.map((image, idx) => {
          const SwiperSlideStyles = generateImageStyle(image.variant);
          const SwiperSlideHeightStyles = mapHeight[image.variant];

          return (
            <SwiperSlide
              key={idx}
              className={cn(
                "relative flex !w-auto self-center text-center text-lg",
                SwiperSlideStyles,
                SwiperSlideHeightStyles
              )}
            >
              <Image
                backgroundImage
                alt="Gallery Image"
                imageData={image.image}
                className="block rounded-[11px] object-cover"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div
        className={cn(
          "pointer-events-none absolute z-10 flex size-20 shrink-0 items-center justify-center space-x-1 rounded-full border-2 border-[#1b1a1f] bg-white/60 shadow-lg backdrop-blur-[9px] ",
          HoverCircleVisibilityStyle
        )}
        style={{
          left: imagePosition.x,
          top: imagePosition.y,
        }}
      >
        <div className="flex w-full items-center justify-center text-center text-xs font-bold uppercase text-grey-scale-off-black">
          <div className="mr-1 flex">
            <Icon name="FloatingArrowLeft" />
          </div>
          SWIPE
          <div className="ml-1 flex">
            <Icon name="FloatingArrowLeft" className="rotate-180" />
          </div>
        </div>
      </div>
    </div>
  );
};
