import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import { CommuningCardProps } from "@sections/communing/cards/CommuningCard.types";
import { tw } from "@utils/tailwind";
import { FC } from "react";

export const CommuningCard: FC<CommuningCardProps> = ({
  title,
  description,
  image,
}) => {
  const rootStyles = tw`content-just-start relative grid
    md:row-span-3 md:grid-rows-subgrid md:pt-14
    xl:pt-0
    xl:[&:nth-child(3n+2)]:col-start-2
    xl:[&:nth-child(n+5)]:pt-10`;

  return (
    <div className={rootStyles}>
      <Typography
        tag="h3"
        variant="h3"
        className="mb-5 pt-2.5 text-primary md:pt-0 xl:mb-10"
      >
        {title}
      </Typography>
      <Image
        disableAutomaticAspectRatio
        imageData={image}
        className="mb-5 h-52 w-full rounded-xl object-cover sm:h-52 md:aspect-square md:h-auto 2xl:mb-5"
      />
      <Typography tag="p" variant="base" className="text-primary opacity-75">
        {description}
      </Typography>
    </div>
  );
};
