import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsIconListComponent } from "@api/contract";
import { iconAdapter } from "@atoms/icon/Icon.adapter";
import { type IconListSectionProps } from "@sections/icon-list/IconListSection.types";
import { RendererArg } from "@/types/renderer";
import { IconListSection } from "./IconListSection";

export const map = ({
  block_settings,
  title,
  description,
  icons_listing,
  show_numbers,
}: ServiceSectionsIconListComponent): IconListSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title,
  description,
  showNumbers: show_numbers,
  cards: icons_listing?.map(({ id, description, icon_file }) => ({
    id,
    description,
    icon: iconAdapter(icon_file?.data?.attributes),
  })),
});

export const renderIconListSection = ({ contentProps }: RendererArg) => (
  <IconListSection {...map(contentProps as ServiceSectionsIconListComponent)} />
);
