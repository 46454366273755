import { BlogLayout } from "@layout/blog-layout/BlogLayout";
import { ArticleProps } from "@layout/blog-layout/BlogLayout.types";
import { HeaderBlogSection } from "@sections/header-blog/HeaderBlogSection";
import { HeaderBlogSectionProps } from "@sections/header-blog/HeaderBlogSection.types";
import { PartnerFlexiblySection } from "@sections/partner-flexibly/PartnerFlexiblySection";
import { PartnerFlexiblySectionProps } from "@sections/partner-flexibly/PartnerFlexiblySection.types";
import { TestimonialsSection } from "@sections/testimonials/TestimonialsSection";
import { TestimonialsSectionProps } from "@sections/testimonials/TestimonialsSection.types";
import { extractNameAndRender } from "@utils/content-renderer/extract-and-render/extractAndRender";
import { SimpleNode } from "@utils/dom/parsed-html";
import { FC } from "react";
import { ArticleTag } from "@/types/article";
import { WithComponent } from "@/types/component";
import { blogContentRenderer } from "./Blog.render";

export type SingleBlogProps = {
  headerAttributes: HeaderBlogSectionProps;
  relatedArticles?: ArticleProps[];
  interestingArticles?: ArticleProps[];
  articleTags?: ArticleTag[];
  blocks: WithComponent[];
  headingNodes: SimpleNode[];
  testimonials: TestimonialsSectionProps;
  globalPartners: PartnerFlexiblySectionProps;
};

export const Blog: FC<SingleBlogProps> = ({
  headerAttributes,
  relatedArticles,
  interestingArticles,
  articleTags,
  blocks,
  headingNodes,
  testimonials,
  globalPartners,
}) => (
  <>
    <HeaderBlogSection
      title={headerAttributes.title}
      authors={headerAttributes.authors}
      backgroundUrl={headerAttributes.backgroundUrl}
      minutesToRead={headerAttributes.minutesToRead}
    />
    <BlogLayout
      relatedArticles={relatedArticles}
      articleTags={articleTags}
      interestingArticles={interestingArticles}
      headingNodes={headingNodes}
    >
      {blocks &&
        blocks.map((page_section, idx) =>
          extractNameAndRender(page_section, blogContentRenderer, idx)
        )}
    </BlogLayout>
    <PartnerFlexiblySection {...globalPartners} />
    <TestimonialsSection {...testimonials} />
  </>
);
