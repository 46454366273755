import { Button } from "@atoms/button/Button";
import { cn } from "@utils/tailwind";
import { StaticImageData } from "next/legacy/image";
import { FC } from "react";
import styles from "./DownloadStory.module.css";

export type DownloadStoryProps = {
  header?: string;
  caption?: string;
  image?: StaticImageData;
  alt?: string;
  fileSrc?: string;
};

export const DownloadStory: FC<DownloadStoryProps> = ({
  header,
  caption,
  image,
  alt,
  fileSrc,
}) => (
  <div className="flex rounded-xl bg-yellow-saturated">
    <div className="flex flex-[2_2_0%] flex-col p-5 md:p-8 xl:flex-1">
      <h4 className="t-headline-h4">{header}</h4>

      <span className="t-subpage-body pb-5 pt-1.5 text-grey-scale-off-black md:pb-8 md:pt-2.5">
        {caption}
      </span>

      {fileSrc && (
        <Button
          asLink
          className="mt-auto w-full text-center sm:w-auto"
          size="big"
          intent="tertiary"
          href={fileSrc}
        >
          Free download
        </Button>
      )}
    </div>

    {image && (
      <div
        className={cn(
          "relative hidden flex-1 items-center justify-items-center rounded-xl p-8 md:flex",
          styles.gradient
        )}
      >
        <img
          className="ml-auto rounded-xl object-contain shadow-[0_25px_50px_0_rgba(255,124,63,0.5)] xl:w-10/12 2xl:w-3/5"
          alt={alt ?? ""}
          src={image?.src}
        />
      </div>
    )}
  </div>
);
