import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { ExpertTileProps } from "./ExpertTile.types";

export const ExpertTile = ({ expert }: ExpertTileProps) => {
  const iconWrapperStyles = cn(
    "absolute bottom-0 right-0 rounded-full border-2 border-transparent transition-transform duration-200 ease-in group-hover/tile:-translate-y-1.5 group-focus/tile:border-green-accent-two",
    !expert.href && "hidden"
  );

  return (
    <li className="w-36 md:w-44 xl:w-48">
      <a
        href={expert.href}
        target="_blank"
        rel="noreferrer"
        className="group/tile block p-2.5 outline-none md:p-5"
      >
        <div className="relative aspect-square w-full">
          <Image
            backgroundImage
            imageData={expert.img}
            maxKnownWidth={250}
            alt={expert.name}
            className="rounded-full bg-grey-scale-light-gray object-contain"
          />
          <div className={iconWrapperStyles}>
            <Icon
              className="size-8 md:size-[2.125rem] xl:size-[2.375rem]"
              name="LinkedInGray"
            />
          </div>
        </div>
      </a>
      <div className="pl-2.5 md:pl-5">
        <Typography
          tag="p"
          variant="base"
          weight="medium"
          className="mb-2.5 text-content-primary-on-light"
        >
          {expert.name}
        </Typography>
        <Typography
          wysiwygStyling
          tag="p"
          variant="sm"
          className="text-grey-mid"
          dangerouslySetInnerHTML={{ __html: expert.title }}
        />
      </div>
    </li>
  );
};
