import { blockSettingsAdapter } from "@adapters/block-settings";
import {
  ServiceSectionsOpenSourceProjectsComponent,
  SmallComponentsProjectsGroupComponent,
  OpenSource,
} from "@api/contract";
import { OpenSourceSectionProps } from "@sections/open-source/OpenSourceSection.types";
import { OpenSourceCardProps } from "@sections/open-source/open-source-components/types";
import { RendererArg } from "@/types/renderer";
import { OpenSourceSection } from "./OpenSourceSection";

type OpenSourceApi = {
  id?: number;
  attributes?: OpenSource;
};

const mapCard = ({
  id,
  title,
  button,
  open_sources,
}: SmallComponentsProjectsGroupComponent): OpenSourceCardProps => ({
  id: id ?? 0,
  title: title ?? "",
  linkButton: {
    href: button?.button_url ?? "",
    text: button?.button_text ?? "",
    openInNewWindow: button?.button_new_window ?? false,
    className: button?.button_class,
  },
  projects:
    open_sources?.data?.map(({ id, attributes }: OpenSourceApi) => ({
      id: id ?? 0,
      title: attributes?.title ?? "",
      url: attributes?.url,
      logo: {
        ...attributes?.image?.data,
        backgroundColor: attributes?.background ?? "beige",
      },
    })) ?? [],
});

const map = ({
  block_settings,
  header,
  description,
  projects_create,
  projects_contribute,
}: ServiceSectionsOpenSourceProjectsComponent): OpenSourceSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title: header,
  subtitle: description,
  cards:
    projects_create && projects_contribute
      ? [mapCard(projects_create), mapCard(projects_contribute)]
      : undefined,
});

export const renderOpenSourceSection = ({ contentProps }: RendererArg) => (
  <OpenSourceSection
    {...map(contentProps as ServiceSectionsOpenSourceProjectsComponent)}
  />
);
