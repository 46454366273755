import { blockSettingsAdapter } from "@adapters/block-settings";
import {
  ContentSectionsVideoComponent,
  ServiceSectionsVideoComponent,
} from "@api/contract";
import { BlockSettings } from "@/types/component";
import { RendererArg } from "@/types/renderer";
import { VideoBlock } from "./VideoBlock";
import { VideoBlockProps } from "./VideoBlock.types";
import { VideoBlockContainer } from "./VideoBlockContainer";

const mapContentBlock = ({
  videoURL,
  caption,
}:
  | ContentSectionsVideoComponent
  | ServiceSectionsVideoComponent): VideoBlockProps => ({
  caption,
  videoURL: videoURL ?? "",
});

const mapSection = ({
  videoURL,
  caption,
  block_settings,
}: ServiceSectionsVideoComponent): VideoBlockProps & BlockSettings => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  caption,
  videoURL: videoURL ?? "",
});

export const renderVideoBlock = ({ contentProps }: RendererArg) => (
  <VideoBlock
    {...mapContentBlock(contentProps as ContentSectionsVideoComponent)}
  />
);

export const renderVideoSection = ({ contentProps }: RendererArg) => {
  const props = mapSection(contentProps as ServiceSectionsVideoComponent);
  return (
    <VideoBlockContainer {...props}>
      <VideoBlock videoURL={props.videoURL} caption={props.caption} />
    </VideoBlockContainer>
  );
};
