import React, { ReactNode } from "react";
import { WithComponent } from "@/types/component";
import { RendererArg } from "@/types/renderer";

export const extractNameAndRender = (
  data: WithComponent,
  renderer: (data: RendererArg) => ReactNode,
  key?: number | string
) => {
  const { __component, ...rest } = data;
  const componentName = __component.split(".").pop();

  if (!componentName) {
    throw new Error(`Component '${componentName}' wasn't not found`);
  }
  return (
    <React.Fragment key={key}>
      {renderer({ componentName, contentProps: rest })}
    </React.Fragment>
  );
};
