import Topography from "./topography-svg";

export const localSvgs = {
  topography: Topography,
} as const;

export type LocalSvgName = keyof typeof localSvgs;
export const staticSvgNames = Object.keys(localSvgs) as LocalSvgName[];

export const getStaticSvg = (icon: string | undefined) =>
  staticSvgNames.find((i) => i === icon) ?? "topography";

export default localSvgs;
