import Icon from "@atoms/icon/Icon";
import { cn } from "@utils/tailwind";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import { BurgerSectionAccordeonTypes } from "./BurgerSection.constants";

export const desktopScroll = (
  index: number,
  wrapperRef: RefObject<HTMLDivElement>
) => {
  const sectionTop = wrapperRef?.current?.getBoundingClientRect().top;
  if (sectionTop || sectionTop === 0) {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const positionOfASection = sectionTop + scrollTop;
    window.scrollTo({
      top: index === 0 ? positionOfASection : positionOfASection + index * 300,
      behavior: "smooth",
    });
  }
};

export const prepareStylesForLayers = (
  index: number,
  activeLayer: number | undefined
) => {
  let basicStyles;
  let activeStyles;
  let notActiveStyles;

  if (index === 0)
    basicStyles = "top-[-10px] z-30 md:top-[-54px] xl:top-[-55px]";
  if (index === 2)
    basicStyles =
      "top-[10px] md:-bottom-1/2 md:translate-y-[35px] xl:translate-y-[5px]";
  if (typeof activeLayer !== "undefined" && index !== activeLayer)
    notActiveStyles = "scale-90 opacity-40 xl:scale-50";

  if (activeLayer === 0 && index === 0)
    activeStyles = "-translate-y-8 md:translate-y-0";
  if (activeLayer === 1 && index === 0)
    activeStyles = "-translate-y-8 md:translate-y-0";
  if (activeLayer === 1 && index === 1)
    activeStyles = "md:top-1/2 md:-translate-y-1/2";
  if (activeLayer === 1 && index === 2)
    activeStyles = "translate-y-8 md:translate-y-0";
  if (activeLayer === 2 && index === 2)
    activeStyles = "translate-y-8 md:translate-y-0";

  return cn(basicStyles, activeStyles, notActiveStyles);
};

export const prepareStylesForDesktop = (
  index: number,
  activeLayer: number | undefined
) => {
  if (activeLayer === 1 && index === 1) return "md:translate-y-8";
  if (activeLayer === 1 && index === 2) return "md:translate-y-8";
  if (activeLayer === 2 && index === 2) return "md:translate-y-8";
};
export const prepareStylesForLine = (
  index: number,
  activeLayer: number | undefined
) => {
  if (activeLayer === 1 && index === 1) return "md:-translate-y-8";
  if (activeLayer === 1 && index === 2)
    return "md:h-[calc(100%+32px)] md:bottom-2 xl:bottom-6";
  if (activeLayer !== 2 && index === 2) return "md:bottom-2 xl:bottom-6";
  if (activeLayer === 2 && index === 2)
    return "md:h-[calc(100%+32px)] md:bottom-2 xl:bottom-6";
  if (index === 0) return "md:top-2 xl:top-[22px]";
};

export const BurgerSectionAccordeon: FC<BurgerSectionAccordeonTypes> = ({
  className,
  index,
  activeLayer,
  layer,
  setActiveLayer,
  themeName,
  windowWidth,
  wrapperRef,
}) => {
  const contentRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    contentRefs.current.forEach((ref, index) => {
      if (index !== activeLayer) {
        ref.style.maxHeight = "0px";
      } else {
        ref.style.maxHeight = `${ref.scrollHeight}px`;
      }
    });
  }, [activeLayer]);

  const secondLayerUlStyles = "xl:flex xl:flex-wrap xl:gap-x-3";
  const secondLayerLiStyles = "xl:w-[calc(50%-6px)]";
  const prepareCircleBgColor = () => {
    if (activeLayer === index) {
      if (themeName === "darkGreen") return "bg-content-primary-on-dark";
      return "bg-content-primary-on-light";
    } else {
      if (themeName === "darkGreen") return "bg-content-primary-on-light";
      return "bg-content-primary-on-dark";
    }
  };

  const descriptionSize =
    layer.description.length > 8 ? "md:text-sm" : "md:text-base";

  return (
    <div
      className={cn(
        "relative md:ml-auto md:w-80 md:min-w-80 md:max-w-80 xl:w-[500px] xl:min-w-[500px]",
        className
      )}
    >
      {index !== 2 && (
        <span
          className={cn(
            "absolute -left-6 hidden h-full w-px bg-content-primary-on-light transition-transform duration-500 md:block xl:-left-16",
            prepareStylesForLine(index, activeLayer)
          )}
        />
      )}
      <div key={`${layer.title}-${index}`}>
        <div
          className={cn(
            "relative cursor-pointer text-xl font-medium leading-8 text-content-primary-on-light xl:py-3 xl:text-3xl xl:leading-9"
          )}
        >
          <p
            onClick={(event) => {
              event.stopPropagation();
              setActiveLayer((prevValue) =>
                prevValue === index ? undefined : index
              );
              windowWidth &&
                windowWidth >= 1270 &&
                desktopScroll(index, wrapperRef);
            }}
            className={cn(
              "text-primary	transition-opacity duration-500",
              index === activeLayer ? "opacity-100" : "opacity-30"
            )}
          >
            {layer.title}
          </p>
          {index === 2 && (
            <span
              className={cn(
                "absolute -left-6 hidden h-full w-px bg-content-primary-on-light transition-transform duration-500 md:bottom-0 md:block xl:-left-16",
                prepareStylesForLine(index, activeLayer)
              )}
            />
          )}
          <span
            className={cn(
              "absolute left-[-31.8px] top-[calc(50%-8px)] z-10 hidden size-4 rounded-full border border-content-primary-on-light transition-colors md:block xl:left-[-71.8px]",
              prepareCircleBgColor()
            )}
          />
        </div>
        <div
          ref={(ref) => (contentRefs.current[index] = ref as HTMLDivElement)}
          className="overflow-hidden transition-all duration-500"
        >
          <ul
            className={cn(
              "mb-2 list-none md:mt-3",
              index === 1 && secondLayerUlStyles
            )}
          >
            {layer.description.map((text) => (
              <li
                className={cn(
                  "mb-1 flex items-center",
                  index === 1 && secondLayerLiStyles
                )}
                key={`${text}-${index}`}
              >
                <Icon className="mr-2.5 fill-green-accent-two" name="Success" />
                <p
                  className={cn(
                    "text-sm font-medium leading-6 text-primary md:py-2",
                    descriptionSize
                  )}
                >
                  {text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const useSelectActiveLayer = (
  windowWidth: number | undefined,
  wrapperRef: RefObject<HTMLDivElement>
) => {
  const [activeLayer, setActiveLayer] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const sectionTop = wrapperRef?.current?.getBoundingClientRect().top;
        if (sectionTop) {
          if (sectionTop <= -600) {
            setActiveLayer(2);
          } else if (sectionTop <= -300) {
            setActiveLayer(1);
          } else if (sectionTop <= 0) {
            setActiveLayer(0);
          }
        }
      }
    };
    if (windowWidth && windowWidth >= 1270) {
      window.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [setActiveLayer, windowWidth, wrapperRef]);

  return {
    activeLayer,
    setActiveLayer,
  };
};
