import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { YourNeedsCard } from "@molecules/your-needs/YourNeedsCard";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { YourNeedsSectionProps } from "./YourNeedsSection.types";

export const YourNeedsSection: FC<YourNeedsSectionProps> = ({
  title,
  cards,
  variant,
  themeName,
  anchorId,
  linkPosition = "top",
  color = "light",
}) => {
  const linkBlockPresent = cards.some((card) => card.href);

  return (
    <ContainerSpacing
      withBackground
      isTransparentBackground={color === "light"}
      id={anchorId}
      data-strapi="your-needs"
      data-theme={themeName}
      className={
        color === "dark"
          ? "bg-content-primary-on-light text-content-primary-on-dark"
          : "text-content-primary-on-light"
      }
    >
      <div className="container-flexible ">
        <Typography
          tag="h2"
          variant="h2"
          className="mb-9 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16"
        >
          {title}
        </Typography>

        {cards && (
          <div
            className={cn(
              "grid overflow-hidden rounded-4xl border border-green-accent-two",
              variant === "twoColumns" ? "lg:grid-cols-2" : "lg:grid-cols-3"
            )}
          >
            {cards.map((card, idx) => (
              <YourNeedsCard
                {...card}
                key={idx}
                linkPosition={linkPosition}
                id={idx}
                color={color}
                linkBlockPresent={linkBlockPresent}
              />
            ))}
          </div>
        )}
      </div>
    </ContainerSpacing>
  );
};
