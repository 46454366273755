import { renderCodeBlockSection } from "@content-sections/CodeBlock/CodeBlock.adapter";
import { renderCuratedBy } from "@content-sections/CuratedBy/CuratedBy.adapter";
import { renderFormatedTextSection } from "@content-sections/FormattedText/FormattedText.adapter";
import { renderImageBlock } from "@content-sections/ImageBlock/ImageBlock.adapter";
import { renderInfoBlock } from "@content-sections/InfoBlock/InfoBlock.adapter";
import { renderQuoteBlock } from "@content-sections/QuoteBlock/QuoteBlock.adapter";
import { renderTableBlock } from "@content-sections/TableBlock/TableBlock.adapter";
import { renderVideoBlock } from "@content-sections/VideoBlock/VideoBlock.adapter";
import { renderWarningBlock } from "@content-sections/WarningBlock/WarningBlock.adapter";
import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { renderSchema } from "@content-sections/schema/Schema.adapter";
import { renderCollapsableList } from "@molecules/collapsable-list/CollapsableList.adapter";
import { renderColumnText } from "@sections/column-text/ColumnText.adapter";
import { renderIconsInCategoriesSection } from "@sections/icons-in-categories/IconsInCategories.adapter";
import { renderPartnerFlexibilitySection } from "@sections/partner-flexibly/PartnerFlexiblySection.adapter";
import { renderTestimonialSection } from "@sections/testimonials/TestimonialsSection.adapter";
import { FC, PropsWithChildren, ReactNode } from "react";
import { RendererArg } from "@/types/renderer";
import {
  BlockContentSectionsComponent,
  GlobalContentSectionComponent,
} from "@/types/sections";

const CenteredLayout: FC<PropsWithChildren> = ({ children }) => (
  <div className={marginBottomWrapperStyles}>
    <div className="container-flexible flex justify-center">
      <div className="w-full max-w-3xl">{children}</div>
    </div>
  </div>
);

const imageBlockRenderer = (data: RendererArg) => (
  <div className="container-flexible flex justify-center">
    {renderImageBlock(data)}
  </div>
);

const blockParagraphRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderFormatedTextSection(data)}</CenteredLayout>
);

const curatedByRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderCuratedBy(data)}</CenteredLayout>
);

const blogAccordionRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderCollapsableList(data)}</CenteredLayout>
);

const warningRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderWarningBlock(data)}</CenteredLayout>
);

const quoteRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderQuoteBlock(data)}</CenteredLayout>
);

const infoBlockRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderInfoBlock(data)}</CenteredLayout>
);

const tablesRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderTableBlock(data)}</CenteredLayout>
);

const videoRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderVideoBlock(data)}</CenteredLayout>
);

const codeRenderer = (data: RendererArg) => (
  <CenteredLayout>{renderCodeBlockSection(data)}</CenteredLayout>
);

const columnTextRenderer = (data: RendererArg) => (
  <div className={marginBottomWrapperStyles}>{renderColumnText(data)}</div>
);

type CaseStudyBlocks =
  | "results"
  | "icons-in-categories"
  | BlockContentSectionsComponent
  | Extract<
      GlobalContentSectionComponent,
      "testimonials-placeholder" | "flexible-partners-placeholder"
    >;

const variants: Record<CaseStudyBlocks, (data: RendererArg) => ReactNode> = {
  image: imageBlockRenderer,
  "block-paragraph": blockParagraphRenderer,
  warning: warningRenderer,
  quote: quoteRenderer,
  "c2-a": infoBlockRenderer,
  video: videoRenderer,
  tables: tablesRenderer,
  "curated-by": curatedByRenderer,
  "blog-accordion": blogAccordionRenderer,
  results: columnTextRenderer,
  "testimonials-placeholder": renderTestimonialSection,
  "flexible-partners-placeholder": renderPartnerFlexibilitySection,
  code: codeRenderer,
  "icons-in-categories": renderIconsInCategoriesSection,
  "schema-script": renderSchema,
} as const;

type Variant = keyof typeof variants;

export const caseStudyContentRenderer = (data: RendererArg): ReactNode => {
  const variant = variants[data.componentName as Variant];

  if (!variant) {
    // eslint-disable-next-line no-console
    console.error(
      `Component ${data.componentName} has no implementation in contentRenderer`
    );
    return null;
  }

  return variant(data);
};
