import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { Card } from "@sections/icons-in-categories/card/Card";
import { tw } from "@utils/tailwind";
import { FC } from "react";
import { IconsInCategoriesSectionProps } from "./IconsInCategories.types";

const titleContainerStyles = tw`mb-12
  flex
  h-min
  max-w-[45.625rem]
  flex-col
  gap-4
  text-content-primary-on-light
  md:mb-14
  md:gap-5
  lg:mb-16
  xl:sticky
  xl:top-[calc(var(--navbar-height)+15px)]
  xl:col-span-4
  xl:mb-0
  xl:gap-6
  xl:pr-16
  2xl:gap-7`;

export const IconsInCategoriesSection: FC<IconsInCategoriesSectionProps> = ({
  title,
  description,
  cards,
  anchorId,
  className,
  subtitle,
}) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground
    id={anchorId}
    data-strapi="icons-in-categories"
    className={className}
  >
    <div className="container-flexible grid xl:grid-cols-12 xl:gap-5 2xl:gap-10">
      <div className={titleContainerStyles}>
        <Typography
          wysiwygStyling
          tag="h2"
          variant="h2"
          weight="medium"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Typography
          wysiwygStyling
          tag="p"
          variant="base"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="xl:col-span-8 2xl:col-span-7 2xl:col-start-6">
        <Typography
          tag="h3"
          variant="h3"
          weight="medium"
          className="mb-7 text-content-primary-on-light empty:hidden md:mb-8 lg:mb-10 2xl:mb-16"
        >
          {subtitle}
        </Typography>

        {cards && (
          <div className="grid gap-11 md:gap-12 xl:gap-20">
            {cards.map((card) => (
              <Card key={card.title} {...card} />
            ))}
          </div>
        )}
      </div>
    </div>
  </ContainerSpacing>
);
