import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { TagsProps } from "./Tags.types";

export const Tags: FC<TagsProps> = ({
  title,
  tags,
  className,
  listClassName,
  headlineClassName,
}) => (
  <div className={className}>
    <h6 className={cn("t-headline-h6 mb-5", headlineClassName)}>{title}</h6>
    <ul className={cn("flex flex-wrap gap-4 p-0", listClassName)}>
      {tags.map((tag, index) => (
        <li key={`tag-${index}`} className="t-body-b2 cursor-pointer list-none">
          <Link
            className="link no-underline"
            href={`/blog/tag/${tag.slug}`}
            id={index === 0 ? `tag` : `tag-${index}`}
          >
            {`#${tag.title}`}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
