import { ContactProps } from "@page-components/contact/Contact.types";
import { CompanyRecordsSection } from "@sections/company-records/CompanyRecordsSection";
import { ContactSection } from "@sections/contact-section/ContactSection";
import { LogosLooper } from "@sections/logos-looper/LogosLooper";
import { TestimonialsSection } from "@sections/testimonials/TestimonialsSection";
import { FC } from "react";

export const Contact: FC<ContactProps> = ({
  testimonials,
  companyRecords,
  contactForm,
  logosLooper,
}) => (
  <>
    {contactForm && <ContactSection {...contactForm} />}
    {logosLooper && <LogosLooper {...logosLooper} />}
    {testimonials && <TestimonialsSection {...testimonials} />}
    {companyRecords && <CompanyRecordsSection {...companyRecords} />}
  </>
);
