import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { useCallbackOnEsc } from "./LightBox.helpers";
import { LightBoxProps } from "./LightBox.types";

export const LightBox: FC<LightBoxProps> = ({ url, onClick, active }) => {
  useCallbackOnEsc(onClick, active);
  const activeStyles = cn(
    active ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
  );
  return (
    <div
      className={cn(
        "ease fixed left-0 top-0 z-[100000] flex h-screen w-screen cursor-pointer items-center justify-center bg-black/80 transition-opacity duration-500",
        activeStyles
      )}
      onClick={onClick}
    >
      <Icon
        className="ease absolute right-5 top-5 size-4 fill-content-primary-on-dark opacity-30 transition-opacity duration-500 hover:opacity-60"
        name="Error"
      />
      {url && (
        <Image
          className="h-auto max-h-[85vh] max-w-[85vw] bg-white"
          imageData={{ attributes: { url: url } }}
          src={url}
          alt=""
        />
      )}
    </div>
  );
};
