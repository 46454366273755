import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsColumnStepsComponent } from "@api/contract";
import { iconAdapter } from "@atoms/icon/Icon.adapter";
import { type ColumnStepsProps } from "@sections/column-steps/ColumnSteps.types";
import { RendererArg } from "@/types/renderer";
import { ColumnSteps } from "./ColumnSteps";

const map = ({
  title,
  description,
  columns_steps,
  block_settings,
  show_icons,
}: ServiceSectionsColumnStepsComponent): ColumnStepsProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  header: title,
  summary: description,
  steps: columns_steps?.map(({ title, description, icon_file }) => ({
    title,
    text: description,
    icon: iconAdapter(icon_file?.data?.attributes),
  })),
  showIcons: show_icons,
});

export const renderColumnSteps = ({ contentProps }: RendererArg) => (
  <ColumnSteps {...map(contentProps as ServiceSectionsColumnStepsComponent)} />
);
