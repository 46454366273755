import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsHorizontalAccordionComponent } from "@api/contract";
import { HorizontalAccordionSectionProps } from "@sections/horizontal-accordion/HorizontalAccordionSection.types";
import { RendererArg } from "@/types/renderer";
import { HorizontalAccordionSection } from "./HorizontalAccordionSection";

const map = ({
  hor_acc_title,
  hor_acc_subtitle,
  hor_acc_items,
  block_settings,
}: ServiceSectionsHorizontalAccordionComponent): HorizontalAccordionSectionProps => ({
  ...blockSettingsAdapter({ blockSettings: block_settings }),
  title: hor_acc_title ?? "",
  subtitle: hor_acc_subtitle ?? "",
  cards:
    hor_acc_items?.map((it) => ({
      title: it.title || "",
      rawContent: it.content || "",
      href: it.url,
    })) ?? [],
});

export const renderHorizontalAccordionSection = ({
  contentProps,
}: RendererArg) => (
  <HorizontalAccordionSection
    {...map(contentProps as ServiceSectionsHorizontalAccordionComponent)}
  />
);
