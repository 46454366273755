import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useRedirectToHome = (seconds: number) => {
  const [remaining, setRemaining] = useState(seconds);
  const router = useRouter();

  useEffect(() => {
    const tick = () => setRemaining(remaining - 1);

    const countdown = setInterval(tick, 1000);

    if (remaining === 1) {
      clearInterval(countdown);
      void router.push("/");
    }

    return () => clearInterval(countdown);
  }, [remaining, router]);

  return remaining;
};
