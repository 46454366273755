import { blockSettingsAdapter } from "@adapters/block-settings";
import { ServiceSectionsCommuningComponent } from "@api/contract";
import { ApiImage } from "@page-components/types";
import { CommuningSectionProps } from "@sections/communing/CommuningSection.types";
import { getResponsiveImages } from "@utils/images/responsive-images";
import { RendererArg } from "@/types/renderer";
import { CommuningSection } from "./CommuningSection";

const map = ({
  block_settings,
  title,
  description,
  resp_image,
  columns_comm,
}: ServiceSectionsCommuningComponent): CommuningSectionProps => ({
  ...blockSettingsAdapter({
    blockSettings: block_settings,
  }),
  title,
  description,
  decorativeImages: getResponsiveImages(resp_image),
  cards:
    columns_comm?.map(({ title, description, image_file, id }) => ({
      id: id ?? 0,
      title: title ?? "",
      description: description ?? "",
      image: image_file?.data as ApiImage,
    })) ?? [],
});

export const renderCommuningSection = ({ contentProps }: RendererArg) => (
  <CommuningSection
    {...map(contentProps as ServiceSectionsCommuningComponent)}
  />
);
