import { SmallComponentsButtonComponent } from "@api/contract";
import { ButtonC2A } from "@page-components/types";

export const callToActionButtonAdapter = (
  button?: SmallComponentsButtonComponent
): ButtonC2A => ({
  buttonText: button?.button_text ?? "",
  buttonUrl: button?.button_url ?? "",
  buttonNewWindow: button?.button_new_window ?? false,
  buttonClass: button?.button_class,
});
