import { TextField } from "@atoms/text-field/TextField";
import { useFormContext } from "react-hook-form";
import { ContactFormSchema } from "../ContactSection.types";

export const LastNameField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ContactFormSchema>();
  return (
    <TextField
      required
      id="contact-last-name"
      label="Last name"
      error={errors.lastName?.message}
      {...register("lastName")}
    />
  );
};
