import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import { FC } from "react";
import { CardProps } from "../IconsInCategories.types";

export const Card: FC<CardProps> = ({ title, images }) => (
  <div>
    <Typography
      tag="h4"
      variant="h4"
      weight="medium"
      className="mb-2.5 border-b border-current pb-3 text-content-primary-on-light md:mb-4 md:pb-4 lg:mb-5 lg:pb-5 xl:mb-10"
    >
      {title}
    </Typography>
    <div className="grid grid-cols-2 justify-between justify-items-center gap-5 md:grid-cols-3 xl:gap-x-8 xl:gap-y-10">
      {images.map((image, ind) => (
        <div key={ind} className="relative h-14 w-full sm:h-20">
          <Image
            backgroundImage
            imageData={image}
            maxKnownWidth={250}
            className="object-contain"
          />
        </div>
      ))}
    </div>
  </div>
);
