import { Button } from "@atoms/button/Button";
import { CheckboxField } from "@atoms/checkbox-field/CheckboxField";
import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormContext } from "react-hook-form";
import { env } from "@/env";
import { ContactFormSchema } from "../ContactSection.types";
import { PersonalDataPopover } from "./PersonalDataPopover";

export const FormBottomPart = () => {
  const {
    register,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useFormContext<ContactFormSchema>();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const handleCaptchaChange = (token: string | null) => {
    if (token) setValue("captchaToken", token, { shouldValidate: true });
  };

  return (
    <div className="text-grey-scale-off-black">
      <CheckboxField
        id="contact-personal-data"
        error={errors.shouldProcessPersonalData?.message}
        label="I want VirtusLab to process my personal data. *"
        className="mb-3"
        afterLabel={<PersonalDataPopover />}
        {...register("shouldProcessPersonalData")}
      />
      <CheckboxField
        id="contact-newsletter"
        label="I want to receive the VirtusLab newsletter."
        className="mb-9 md:mb-10 lg:mb-11"
        {...register("shouldReceiveNewsletter")}
      />
      <div className="flex flex-col gap-12 md:flex-row md:items-center xl:gap-16">
        <div className="flex flex-col gap-2">
          <ReCAPTCHA
            onChange={handleCaptchaChange}
            ref={captchaRef}
            sitekey={env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
          />
          <Typography
            tag="p"
            variant="sm"
            weight="medium"
            className={cn("hidden", errors.captchaToken && "block text-error")}
          >
            Required
          </Typography>
        </div>
        <Button
          mobileFullWidth
          iconShowOnMobile
          type="submit"
          intent="primaryV2"
          icon="ArrowRight"
          className={cn("h-fit", isSubmitSuccessful && "!hidden")}
          loading={isSubmitting}
        >
          Request initial meeting
        </Button>
        <Icon
          name="Thick"
          className={cn(
            "size-6 animate-bounce text-green-accent-two",
            isSubmitSuccessful ? "block" : "hidden"
          )}
        />
      </div>
    </div>
  );
};
