import { marginBottomWrapperStyles } from "@content-sections/helpers";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import styles from "./FormattedText.module.css";
import { FormattedTextProps } from "./FormattedText.types";
import listStyles from "./list-styles.module.css";

export const FormattedText: FC<FormattedTextProps> = ({
  content,
  className,
}) => (
  <div
    className={cn(
      listStyles.root,
      marginBottomWrapperStyles,
      "links-container relative",
      styles.root,
      className
    )}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);
