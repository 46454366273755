import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { ChessSectionProps } from "./ChessSection.types";
import { ChessCardWithImage } from "./cards/ChessCardWithImage";
import { ChessCardWithLogos } from "./cards/ChessCardWithLogos";

export const ChessSection: FC<ChessSectionProps> = ({
  title,
  rawHtmlSubtitle,
  cards,
  anchorId,
  isTransparentBackground,
  themeName,
}) => (
  <ContainerSpacing
    withBackground
    id={anchorId}
    data-strapi="chess"
    data-theme={themeName}
    className={cn(isTransparentBackground && "bg-transparent")}
  >
    {title && (
      <SectionHeader
        withMarginBottom
        title={title}
        subtitle={rawHtmlSubtitle}
        className="container-flexible text-section-title"
        subtitleClassName="lg:opacity-70"
      />
    )}
    {cards && (
      <div className="container-flexible grid place-items-center gap-12 sm:gap-20 lg:gap-10">
        {cards.map((card) =>
          card.variant === "with-image" ? (
            <ChessCardWithImage key={card.id} {...card} />
          ) : (
            <ChessCardWithLogos key={card.id} {...card} />
          )
        )}
      </div>
    )}
  </ContainerSpacing>
);
