import { Button } from "@atoms/button/Button";
import { ButtonProps } from "@atoms/button/Button.types";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export type ArrowButtonProps = Omit<ButtonProps, "size" | "icon"> & {
  rotated?: boolean;
};

export const ArrowButton: FC<ArrowButtonProps> = ({
  rotated,
  className,
  disabled,
}) => (
  <Button
    intent="secondaryV2"
    size="medium"
    icon="ArrowRight"
    className={className}
    disabled={disabled}
    iconClassName={cn(rotated && "w-max rotate-180")}
  />
);
