import { forwardRef } from "react";
import { Card } from "./Card/Card";
import { CardVariant, TrifoldCard } from "./ExpandableCards.types";

export type ExpandableCardsProps = {
  cards: TrifoldCard[];
};

export const ExpandableCards = forwardRef<HTMLDivElement, ExpandableCardsProps>(
  ({ cards }, ref) => (
    <div className="w-full overflow-hidden xl:flex" ref={ref}>
      {cards.map((card, index) => (
        <Card
          key={index}
          {...card}
          number={index + 1}
          variant={
            index === 0
              ? CardVariant.IMAGE
              : index === 1
                ? CardVariant.GREEN
                : CardVariant.LIGHT
          }
        />
      ))}
    </div>
  )
);

ExpandableCards.displayName = "ExpandableCards";
