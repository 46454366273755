import { DynamicIconComponent } from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { OurStoryInNumbersCardProps } from "@sections/story-in-numbers/cards/OurStoryInNumbersCard.types";
import { FC } from "react";

export const OurStoryInNumbersCard: FC<OurStoryInNumbersCardProps> = ({
  title,
  description,
  icon,
}) => (
  <div className="relative -mb-px -ml-px flex flex-col gap-1.5 rounded-3xl border border-solid border-card bg-card p-6 md:gap-5 xl:gap-5 xl:p-10 2xl:gap-2.5 2xl:p-10">
    <div className="flex items-center justify-between gap-5">
      <Typography tag="h3" variant="h3" className="text-card-title">
        {title}
      </Typography>
      {icon && (
        <div className="relative aspect-square h-full w-7 flex-none sm:w-9 xl:w-10 2xl:w-14">
          <DynamicIconComponent
            className="!object-contain"
            backgroundImage
            {...icon}
          />
        </div>
      )}
    </div>

    <div className="t-body-b2 text-grey-scale-text max-md:text-base max-md:leading-7">
      {description}
    </div>
  </div>
);
