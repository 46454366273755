import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { OpenSourceSectionProps } from "@sections/open-source/OpenSourceSection.types";
import { OpenSourceCard } from "@sections/open-source/open-source-components/OpenSourceCard";
import { FC } from "react";

export const OpenSourceSection: FC<OpenSourceSectionProps> = ({
  title,
  subtitle,
  cards,
  anchorId,
  isTransparentBackground,
  themeName,
}) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground={isTransparentBackground}
    id={anchorId}
    data-strapi="open-source"
    data-theme={themeName}
  >
    <div className="container-flexible">
      <div className="max-w-6xl">
        <Typography
          tag="h2"
          variant="h2"
          className="mb-4 text-content-primary-on-light md:mb-5 2xl:mb-8"
        >
          {title}
        </Typography>
        <p className="t-body-b2 mb-8 text-grey-scale-off-black/75 opacity-75 md:mb-10 2xl:mb-16">
          {subtitle}
        </p>
      </div>
      {cards && (
        <div className="grid gap-8 md:gap-10 xl:grid-cols-2 2xl:gap-20">
          {cards.map((card) => (
            <OpenSourceCard key={card.id} {...card} />
          ))}
        </div>
      )}
    </div>
  </ContainerSpacing>
);
