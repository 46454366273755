import { useEffect } from "react";

export const useCallbackOnEsc = (cb: () => void, enabled?: boolean) => {
  useEffect(() => {
    // eslint-disable-next-line
    const down = (e: any) => {
      if (e.key === "Escape") {
        e.preventDefault();
        cb();
      }
    };

    let listening = false;

    if (enabled) {
      listening = true;
      document.addEventListener("keydown", down);
    }

    return () => {
      if (listening) {
        document.removeEventListener("keydown", down);
        listening = false;
      }
    };
  }, [enabled, cb]);
};
