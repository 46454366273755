import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { LogoLooper } from "@molecules/logo-looper/LogoLooper";
import { FC } from "react";
import { LogosLooperProps } from "./LogosLooper.types";

export const LogosLooper: FC<LogosLooperProps> = ({
  title,
  logos,
  anchorId,
}) => (
  <ContainerSpacing
    withBackground
    isTransparentBackground
    id={anchorId}
    data-strapi="logos-looper"
    className="text-content-primary-on-light"
  >
    <div className="container-flexible">
      {title && (
        <div className="flex w-full items-center">
          <div className="flex-none text-xs font-medium uppercase">{title}</div>
          <div className="grow pl-8">
            <div className="h-px -translate-y-px bg-content-primary-on-light" />
          </div>
        </div>
      )}
      <LogoLooper logos={logos} className="pt-5 md:pt-6 lg:pt-7 xl:pt-8" />
    </div>
  </ContainerSpacing>
);
