import { ContentSectionsC2AComponent } from "@api/contract";
import { RendererArg } from "@/types/renderer";
import { InfoBlock } from "./InfoBlock";
import { InfoBlockProps } from "./InfoBlock.types";

const map = ({
  formatted_description,
  headline,
  button,
}: ContentSectionsC2AComponent): InfoBlockProps => ({
  rawDescription: formatted_description ?? "",
  headline: headline ?? "",
  button: {
    text: button?.button_text ?? "",
    url: button?.button_url ?? "",
    newWindow: button?.button_new_window ?? true,
  },
});

export const renderInfoBlock = ({ contentProps }: RendererArg) => (
  <InfoBlock {...map(contentProps as ContentSectionsC2AComponent)} />
);
