import Icon from "@atoms/icon/Icon";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { type TrifoldLinkProps } from "./TrifoldLink.types";

export const TrifoldLink: FC<TrifoldLinkProps> = ({
  className,
  iconContainerClassName,
  childrenContainerClassName,
  children,
  href,
  onDarkBg,
  ...rest
}) => {
  const rootStyles = cn(
    `group/tLink flex w-max cursor-pointer items-center
    text-base font-medium not-italic leading-[normal]
    no-underline outline-none 2xl:text-[1.3125rem]`,
    onDarkBg && "text-content-primary-on-dark",
    !onDarkBg && "text-grey-scale-off-black"
  );

  const iconContainerStyles = cn(
    `-mr-2 flex h-full
    max-h-2 min-h-2 min-w-2
    items-center fill-green-accent-one opacity-100
    transition-opacity duration-300 ease-linear
    group-hover/tLink:visible
    group-hover/tLink:opacity-100
    group-active/tLink:visible
    xl:invisible
    xl:opacity-0`,
    onDarkBg && "group-active/tLink:fill-content-primary-on-dark",
    !onDarkBg && "group-active/tLink:fill-grey-scale-off-black"
  );

  const childrenContainerStyles = cn(
    `translate-x-4
    border-b border-b-green-accent-one
    transition-all duration-500
    group-hover/tLink:translate-x-4
    xl:translate-x-0 xl:border-b-0`
  );

  const spanStyles = cn(
    `border-b border-solid border-b-transparent
    group-hover/tLink:border-b-green-accent-one`,
    onDarkBg &&
      `group-focus/tLink:border-b-content-primary-on-dark
      group-active/tLink:border-b-content-primary-on-dark`,
    !onDarkBg &&
      `group-focus/tLink:border-b-grey-scale-off-black
      group-active/tLink:border-b-grey-scale-off-black`
  );

  return (
    <Link
      className={cn(rootStyles, className)}
      href={href || ""}
      prefetch={false}
      tabIndex={0}
      {...rest}
    >
      <div className={cn(iconContainerStyles, iconContainerClassName)}>
        <Icon name="ArrowIcon" className="w-2" />
      </div>

      <div className={cn(childrenContainerStyles, childrenContainerClassName)}>
        <span className={spanStyles}>{children}</span>
      </div>
    </Link>
  );
};
