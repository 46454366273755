import { RefObject, useEffect, useState } from "react";

const OOPS_MSG = "Oops. Something went wrong. Our team is working on it...";

export const buildTextForNoHits = (
  isFetching: boolean,
  isError: boolean,
  searchValue: string
) => {
  if (isError) return OOPS_MSG;
  if (isFetching) return "Loading...";
  return searchValue ? "No results found." : "Search something";
};

export const useAutoHiding = ({
  inputRef,
  commandEmptyRef,
}: {
  inputRef: RefObject<HTMLInputElement>;
  commandEmptyRef: RefObject<HTMLDivElement>;
}) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const ignored = [inputRef, commandEmptyRef];

    const handleClick = (event: MouseEvent) => {
      if (!ignored.some((it) => event.target === it.current)) {
        setIsHidden(true);
      }
    };

    const handleTab = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        setIsHidden(true);
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", handleTab);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleTab);
    };
  }, [inputRef, commandEmptyRef]);

  return { isHidden, setIsHidden };
};

export const useOnEsc = (handler: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    const handle = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handler(event);
      }
    };

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [handler]);
};
