import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import { FingerPulseSectionProps } from "./FingerPulse.types";

export const FingerPulseSection: FC<FingerPulseSectionProps> = ({
  title,
  subtitle,
  connections,
  themeName,
  isTransparentBackground,
  anchorId,
}) => {
  const bodyMicroStyles = tw`text-sm font-medium leading-5`;

  return (
    <ContainerSpacing
      withBackground
      isTransparentBackground={isTransparentBackground}
      id={anchorId}
      data-strapi="finger-pulse"
      data-theme={themeName}
    >
      <div className="container-flexible">
        <div data-name="Title and subtitle" className="max-w-7xl">
          <Typography
            tag="h2"
            variant="h2"
            className="mb-4 text-section-title md:mb-5 2xl:mb-8"
          >
            {title}
          </Typography>
          <p className="t-body-b2 mb-8 text-primary opacity-75 md:mb-10 2xl:mb-16">
            {subtitle}
          </p>
        </div>
        {connections && (
          <div
            data-name="Connections"
            className="grid gap-6 md:grid-cols-2 md:gap-10 xl:grid-cols-3 xl:gap-10 2xl:gap-x-20 2xl:gap-y-16"
          >
            {connections.map((connection) => (
              <div
                key={connection.id}
                className="flex gap-4 xl:gap-5 2xl:gap-8"
              >
                <div
                  data-name="Logo wrapper"
                  className="relative size-20 flex-none overflow-hidden rounded-xl bg-icon shadow-md xl:size-24 xl:h-24 2xl:size-32"
                >
                  <Image
                    backgroundImage
                    maxKnownWidth={160}
                    imageData={connection.logo}
                  />
                </div>
                <div>
                  <h3 className="t-headline-h5 mb-1.5 text-primary 2xl:mb-2.5">
                    {connection.title}
                  </h3>
                  <p className={cn(bodyMicroStyles, "mb-3 text-primary/75")}>
                    {connection.description}
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={connection.link.href}
                    className={cn(
                      bodyMicroStyles,
                      "text-[#0B0A0A] underline underline-offset-2 darkGreen:text-primary"
                    )}
                  >
                    {connection.link.label}
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </ContainerSpacing>
  );
};
